import { ProjectsTrackerPage } from './ProjectTrackerPage'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useAssemblyTree } from '@/state'
import { Button } from '@/components/ui/button'
import { CameraPlusIcons } from '@/components/icons/CameraPlusIcon'
import { useListCadViews } from '@/services/queries/views/queries'
import { useLocation } from 'wouter'

export const ProjectTracker = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const { isLoading: isLoadingViews, data: viewsData } = useListCadViews({
    cadId: data?.version?.cad || '',
  })
  useAssemblyTree((state) => state.tree)
  const getAssemblyNode = useAssemblyTree((state) => state.getNode)
  const [, setLocation] = useLocation()

  if (isLoading || isLoadingViews) {
    return <div>Loading...</div>
  }

  const pages = data?.documentPages || []
  const views = viewsData || []
  const projectId = data?.project.id || ''
  const documentId = data?.documentId || ''

  return (
    <div className="h-full overflow-scroll relative pt-14">
      <div
        className="space-y-4 grid justify-items-center px-4 pb-6 w-full"
        style={{
          transformOrigin: '0 0',
        }}
      >
        {pages.length === 0 && (
          <div className="mt-20 flex flex-col justify-center space-y-2">
            <div className="text-md">
              Capture a smart view to start tracking your project
            </div>
            <Button
              className="w-3/6 flex self-center"
              onClick={() => {
                setLocation(`/p/${projectId}/document/${documentId}/cad`)
              }}
            >
              <div className="flex items-center space-x-2">
                <CameraPlusIcons className="h-6 w-6 relative left-px stroke-white" />
                <span>Capture View</span>
              </div>
            </Button>
          </div>
        )}
        {pages.length > 0 &&
          pages.map((page) => {
            const assemblyNode = getAssemblyNode(page.assembly_group_id)
            const pageViews = views.filter(
              (view) => view.assembly_group_id === page.assembly_group_id,
            )

            return (
              <ProjectsTrackerPage
                key={page.id}
                documentId={data?.documentId ?? ''}
                projectId={projectId}
                pageId={String(page.id)}
                documentPage={page}
                partName={
                  assemblyNode?.display_name ||
                  assemblyNode?.instance ||
                  assemblyNode?.product ||
                  'Unknown'
                }
                assemblyGroupId={page.assembly_group_id}
                views={pageViews}
                isReadOnly={false}
              />
            )
          })}
      </div>
    </div>
  )
}
