export const UploadIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 1.8C0.558172 1.8 0.2 1.44183 0.2 1C0.2 0.558172 0.558172 0.2 1 0.2V1.8ZM9 0.2C9.44183 0.2 9.8 0.558172 9.8 1C9.8 1.44183 9.44183 1.8 9 1.8V0.2ZM5.8 13C5.8 13.4418 5.44183 13.8 5 13.8C4.55817 13.8 4.2 13.4418 4.2 13H5.8ZM5 3.66667L4.43431 3.10098C4.74673 2.78856 5.25327 2.78856 5.56569 3.10098L5 3.66667ZM8.89902 6.43431C9.21144 6.74673 9.21144 7.25327 8.89902 7.56569C8.5866 7.8781 8.08007 7.8781 7.76765 7.56569L8.89902 6.43431ZM2.23235 7.56569C1.91993 7.8781 1.4134 7.8781 1.10098 7.56569C0.788562 7.25327 0.788562 6.74673 1.10098 6.43431L2.23235 7.56569ZM1 0.2H9V1.8H1V0.2ZM4.2 13V3.66667H5.8V13H4.2ZM7.76765 7.56569L4.43431 4.23235L5.56569 3.10098L8.89902 6.43431L7.76765 7.56569ZM5.56569 4.23235L2.23235 7.56569L1.10098 6.43431L4.43431 3.10098L5.56569 4.23235Z"
        fill="#3054EF"
      />
    </svg>
  )
}
