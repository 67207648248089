import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { ErrorBoundary } from '@sentry/react'
import dayjs from 'dayjs'

import { Image } from '@/components/pdf/Image'
import { useDocumentState } from '@/state/document'
import {
  useImagesPositions,
  useListImageTemplates,
  useListNotes,
  useNotesPositions,
  useViewsPositions,
} from '@/services/hooks/template_attributes'
import { useDeleteTemplateAttribute } from '@/services/queries/template_attributes'
import { useDeleteView } from '@/services/queries/views'
import { QUERY_KEYS as DOCUMENTS_QUERY_KEYS } from '@/services/queries/documents'
import { View } from '@/lib/api/client'
import { QUERY_KEYS as VIEWS_QUERY_KEYS } from '@/services/queries/views'
import { cn } from '@/utils'
import { Spinner } from '@/components/ui/spinner'
import queryClient from '@/queryClient'
import { useDocumentPageParams } from '../hooks'

type DraggableImageProps = {
  documentPageId: string
  userFacingDownloadUrl: string
  isAddingAnnotation: boolean
  downloadUrl: string
  draggableId: string
  imageId: string
  type: 'image' | 'view'
  positionData?: {
    x: number
    y: number
  }
  isReadOnly?: boolean
  className?: string
  timestamp?: string
}
export const DraggableImage = ({
  documentPageId,
  userFacingDownloadUrl,
  isAddingAnnotation,
  downloadUrl,
  draggableId,
  imageId,
  type,
  positionData,
  isReadOnly,
  className,
  timestamp,
}: DraggableImageProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === draggableId,
    [selectedElement, documentPageId, draggableId],
  )
  const [{ position, delta }, setPosition] = useState({
    position: positionData,
    delta: INITIAL_DELTA,
  })

  const imageTemplates = useListImageTemplates(documentPageId)
  const imageTemplate = useMemo(
    () =>
      imageTemplates?.find(
        (template) => template.template_values.imageId === imageId,
      ),
    [imageTemplates, imageId],
  )

  const imagesPositions = useImagesPositions(documentPageId)
  const viewsPositions = useViewsPositions(documentPageId)
  const imagePositionTemplate = useMemo(
    () =>
      [...imagesPositions, ...viewsPositions].find(
        (template) => template.template_values.movableElementId === draggableId,
      ),
    [imagesPositions, viewsPositions, draggableId],
  )

  const pageNotes = useListNotes(documentPageId)
  const imageNotesTemplates = useMemo(
    () => pageNotes?.filter((note) => note.template_values?.viewId === imageId),
    [pageNotes, imageId],
  )

  const pageNotesPositions = useNotesPositions(documentPageId)
  const imageNotesPositionTemplates = useMemo(
    () =>
      pageNotesPositions?.filter(
        (position) =>
          position.template_values?.movableElementId ===
          draggableId.replace(type, 'note'),
      ),
    [pageNotesPositions, draggableId, type],
  )

  const { projectId, cv, dv } = useDocumentPageParams()
  const { isPending: isDeletingView, mutateAsync: deleteView } = useDeleteView({
    onSuccess: (viewId) => {
      queryClient.setQueriesData(
        {
          queryKey: [
            DOCUMENTS_QUERY_KEYS.DOCUMENT_PAGE,
            {
              projectId,
              cadVersion: cv,
              documentVersion: dv,
            },
          ],
        },
        (data: any) => ({
          ...data,
          views: data.views.filter((view) => view.id !== viewId),
        }),
      )

      const viewsUpdaterFn = (views: Array<View> | undefined) => {
        if (views) {
          return views.filter((view) => view.id !== viewId)
        }
        return views
      }

      queryClient.setQueriesData(
        {
          queryKey: [VIEWS_QUERY_KEYS.VIEWS],
        },
        viewsUpdaterFn,
      )

      queryClient.setQueriesData(
        {
          queryKey: [VIEWS_QUERY_KEYS.CAD_VIEWS],
        },
        viewsUpdaterFn,
      )

      queryClient.removeQueries({
        queryKey: [VIEWS_QUERY_KEYS.VIEW, { viewId }],
      })
    },
  })
  const {
    isPending: isDeletingTemplate,
    mutateAsync: deleteTemplateAttribute,
  } = useDeleteTemplateAttribute()

  const {
    attributes,
    isDragging,
    listeners,
    transform,
    setNodeRef,
    setActivatorNodeRef,
  } = useDraggable({
    disabled: isAddingAnnotation || isReadOnly,
    id: draggableId,
    data: { type },
  })

  const deleteRelatedTemplateAttributes = useCallback(async () => {
    await Promise.all(
      [
        ...(imageNotesTemplates || []),
        ...(imageNotesPositionTemplates || []),
        imagePositionTemplate,
      ]
        .filter((template) => !!template)
        .map((template) =>
          deleteTemplateAttribute({
            templateAttributeId: template?.id as string,
          }),
        ),
    )
  }, [
    imageNotesTemplates,
    imagePositionTemplate,
    imageNotesPositionTemplates,
    deleteTemplateAttribute,
  ])

  const deleteImageHandler = useCallback(async () => {
    await deleteTemplateAttribute({
      templateAttributeId: imageTemplate?.id as string,
    })
    deleteRelatedTemplateAttributes()
  }, [imageTemplate, deleteTemplateAttribute, deleteRelatedTemplateAttributes])

  const deleteViewHandler = useCallback(async () => {
    await deleteView({ viewId: imageId })
    deleteRelatedTemplateAttributes()
  }, [imageId, deleteView, deleteRelatedTemplateAttributes])

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        if (type === 'image') deleteImageHandler()
        if (type === 'view') deleteViewHandler()
      }
    },
    [deleteImageHandler, deleteViewHandler, type],
  )

  useEffect(() => {
    if (isSelected) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected])

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: transform?.x || 0,
        y: transform?.y || 0,
      },
    }))
  }, [transform])

  useEffect(() => {
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  return (
    <div
      ref={setActivatorNodeRef}
      {...attributes}
      {...listeners}
      className={cn('border flex flex-1 p-px box-content relative', className, {
        'cursor-move': !isReadOnly,
        'cursor-default': isReadOnly,
        'shadow-md': isDragging,
        'shadow-gray-200': isDragging,
        'bg-sky-200/10': isDragging,
        'z-[1]': !isDragging,
        'z-[100]': isDragging,
      })}
      style={{
        maxHeight: '50%',
        translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
        top: position?.y || 0,
        left: position?.x || 0,
        borderColor: isSelected ? '#818cf8' : 'transparent',
      }}
    >
      {(isDeletingTemplate || isDeletingView) && (
        <div className="absolute h-full w-full bg-zinc-400/60">
          <Spinner />
        </div>
      )}
      <div
        ref={setNodeRef}
        className={cn(
          'border rounded-md flex flex-1 flex-col justify-center items-center',
          {
            'pointer-events-none': isAddingAnnotation || isDragging,
            'pointer-events-auto': !isAddingAnnotation && !isDragging,
          },
        )}
      >
        <div className="h-full">
          <ErrorBoundary fallback={<div>Failed to load image</div>}>
            <a
              href=""
              download={userFacingDownloadUrl}
              className={cn(
                'h-full cursor-move flex justify-center flex-col items-center',
                {
                  'cursor-move': !isReadOnly,
                  'cursor-default': isReadOnly,
                  'pointer-events-none': isDragging,
                },
              )}
            >
              <Image
                src={downloadUrl}
                className={cn('max-h-full h-full w-auto', {
                  'cursor-move': !isReadOnly,
                  'cursor-default': isReadOnly,
                })}
              />
            </a>
          </ErrorBoundary>
        </div>
        {timestamp && (
          <div className="text-xs mb-2 font-medium text-gray-500">
            {dayjs(timestamp).format('MMM DD, YYYY hh:mm A')}
          </div>
        )}
      </div>
    </div>
  )
}

export default DraggableImage
