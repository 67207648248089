import tippy from 'tippy.js'
import { ReactRenderer } from '@tiptap/react'
import { Node } from '@tiptap/core'
import { Suggestion } from '@tiptap/suggestion'
import { CommandList } from '../components/SlashCommands/CommandList'

export const SlashCommand = Node.create({
  name: 'slashCommand',

  addStorage() {
    return {
      selectedItem: null,
      isActive: false,
    }
  },

  addOptions() {
    return {
      HTMLAttributes: {},
      SlashComponent: CommandList,
      suggestion: {
        char: '/',
        startOfLine: false,
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        render: () => {
          let reactRenderer
          let popup

          return {
            onBeforeStart(props) {
              props.editor.storage.slashCommand.isActive = true
            },
            onStart: (props) => {
              if (!props.clientRect) {
                return
              }

              reactRenderer = new ReactRenderer(this.options.SlashComponent, {
                props,
                editor: props.editor,
              })

              // @ts-expect-error - tippy types are wrong
              popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: reactRenderer.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
              })
            },

            onUpdate(props) {
              reactRenderer.updateProps(props)

              if (!props.clientRect) {
                return
              }

              popup[0].setProps({
                getReferenceClientRect: props.clientRect,
              })
            },

            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                popup[0].hide()

                return true
              }

              return reactRenderer.ref?.onKeyDown(props)
            },

            onExit({ editor }) {
              editor.storage.slashCommand.isActive = false
              popup[0].destroy()
              reactRenderer.destroy()
            },
          }
        },
        ...this.options.suggestion,
      }),
    ]
  },
})
