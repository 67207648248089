import { cn } from '@/utils'
export const WandFillIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M3.83936 0.506901C3.90379 0.332778 4.15007 0.332778 4.2145 0.506901L5.08266 2.85305C5.10291 2.90779 5.14607 2.95095 5.20082 2.97121L7.54696 3.83936C7.72109 3.90379 7.72109 4.15007 7.54696 4.2145L5.20082 5.08266C5.14607 5.10291 5.10291 5.14607 5.08266 5.20082L4.2145 7.54696C4.15007 7.72109 3.90379 7.72109 3.83936 7.54696L2.97121 5.20082C2.95095 5.14607 2.90779 5.10291 2.85305 5.08266L0.506901 4.2145C0.332778 4.15007 0.332778 3.90379 0.506901 3.83936L2.85305 2.97121C2.90779 2.95095 2.95095 2.90779 2.97121 2.85305L3.83936 0.506901Z"
        fill="#1E1E1E"
      />
      <path
        d="M5.18162 13.9288C5.24605 13.7547 5.49233 13.7547 5.55676 13.9288L6.06237 15.2952C6.08263 15.3499 6.12579 15.3931 6.18053 15.4133L7.54691 15.9189C7.72104 15.9834 7.72104 16.2296 7.54691 16.2941L6.18053 16.7997C6.12579 16.8199 6.08263 16.8631 6.06237 16.9178L5.55676 18.2842C5.49233 18.4583 5.24605 18.4583 5.18162 18.2842L4.67602 16.9178C4.65576 16.8631 4.6126 16.8199 4.55785 16.7997L3.19147 16.2941C3.01735 16.2296 3.01735 15.9834 3.19147 15.9189L4.55785 15.4133C4.6126 15.3931 4.65576 15.3499 4.67602 15.2952L5.18162 13.9288Z"
        fill="#1E1E1E"
      />
      <path
        d="M13.9065 3.1944C13.9709 3.02028 14.2172 3.02028 14.2816 3.1944L14.9685 5.05066C14.9888 5.10541 15.0319 5.14857 15.0867 5.16883L16.9429 5.85571C17.1171 5.92014 17.1171 6.16642 16.9429 6.23085L15.0867 6.91773C15.0319 6.93798 14.9888 6.98115 14.9685 7.03589L14.2816 8.89215C14.2172 9.06628 13.9709 9.06628 13.9065 8.89215L13.2196 7.03589C13.1994 6.98115 13.1562 6.93798 13.1014 6.91773L11.2452 6.23085C11.0711 6.16642 11.0711 5.92014 11.2452 5.85571L13.1014 5.16883C13.1562 5.14857 13.1994 5.10541 13.2196 5.05066L13.9065 3.1944Z"
        fill="#1E1E1E"
      />
      <rect
        x="6"
        y="9.125"
        width="3"
        height="16"
        rx="1.5"
        transform="rotate(-45 6 9.125)"
        fill="#1E1E1E"
      />
    </svg>
  )
}
