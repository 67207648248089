import { useEffect, useRef, useState } from 'react'
import { Mesh, Box3, Vector3, Object3D, Group } from 'three'
import { useThree, ThreeEvent } from '@react-three/fiber'
import { Html, TransformControls } from '@react-three/drei'

import { CADPartLabel } from './CADPartLabel'
import { Progress } from '@/components/ui/progress'
import { ControlPanel } from '@/pages/CADPage/components/ControlPanel'
import { RenderControlsDropdown } from '@/pages/CADPage/components/RenderControlsDropdown'
import { useCADQuery } from '@/services/queries/cads'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { useCADPageStore } from '../state'
import { useShallow } from 'zustand/react/shallow'
import { useAssemblyTree } from '@/state'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useSelectable } from '../hooks'
import { WAND_COLOR } from '@/constants'

const useWireframe = (gltf?: GLTFObject) => {
  const explosions = useCADPageStore((state) => state.explosions)

  useEffect(() => {
    gltf?.explodeWireframes(explosions)
  }, [explosions, gltf])

  useEffect(() => {
    if (gltf) {
      gltf.scene.traverse((object) => {
        if (object instanceof Mesh) {
          object.geometry.computeBoundsTree()
        }
      })

      gltf.buildWireframe()
    }
    return () => {
      if (gltf) {
        gltf.scene.traverse((object) => {
          if (object instanceof Mesh) {
            object.geometry.disposeBoundsTree()
          }
        })
      }
    }
  }, [gltf])
}

const useBounds = (gltf?: GLTFObject) => {
  const { camera } = useThree()

  useEffect(() => {
    if (gltf && gltf.scene) {
      /*
          const bounds = new THREE.Box3().setFromObject(obj3d);
    const center = bounds.getCenter(new THREE.Vector3());
    obj3d.position.sub(center);
      */
      const camInitPos = [1.5, 1, 2]
      const autoZoomFactor = 1
      const bounds = new Box3().setFromObject(gltf?.scene)
      const boundsSizes = bounds.getSize(new Vector3())
      const boundsMaxSize = Math.max(...boundsSizes.toArray())

      const center = bounds.getCenter(new Vector3())
      gltf.scene.position.sub(center)

      if (gltf.wireFrameScene) {
        gltf.wireFrameScene.position.sub(center)
      }

      camera.position.fromArray(camInitPos)
      camera.zoom = autoZoomFactor / boundsMaxSize
      camera.updateProjectionMatrix()
    }
  }, [gltf])
}

const useExplosionLines = (gltf?: GLTFObject) => {
  const explosions = useCADPageStore((state) => state.explosions)
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const hidden = useAssemblyTree(useShallow((state) => state.invisibleParts))

  useEffect(() => {
    if (gltf) {
      gltf.showExplosionLines(explosions, isExplosionLinesEnabled, { hidden })
    }
  }, [gltf, explosions, isExplosionLinesEnabled, hidden])
}

const useAutoGroupNodes = (gltf?: GLTFObject) => {
  const getAssemblyTree = useAssemblyTree(
    useShallow((state) => state.getAssemblyTree),
  )

  useEffect(() => {
    const tree = getAssemblyTree()
    if (!gltf || !tree) return
    gltf.groupParts(tree)
  }, [gltf])
}

const useTransformControls = (gltf?: GLTFObject) => {
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const getTree = useAssemblyTree(useShallow((state) => state.getAssemblyTree))
  const setObjectRef = useCADPageStore((state) => state.setExplodeObjectRef)
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const setExplosion = useCADPageStore((state) => state.setExplosion)
  const focusedPartUUID = useCADPageStore((state) => state.focusedPartUUID)
  const explodeObjectRef = useRef<Object3D | Group | null>(null)
  const invisibleParts = useAssemblyTree((state) => state.invisibleParts)
  const selectable = useSelectable({})
  const selected = selectable.selected
  const tree = getTree()

  /*
   * Handler for when the transform controls are used to move an object
   */
  const handleTransform = (isMouseDown: boolean) => {
    if (!gltf || !explodeObjectRef?.current) return

    const handleObject = (obj: Object3D, isGroup: boolean) => {
      if (isMouseDown) {
        gltf.saveObjectState(obj)
      } else {
        const ogPosition = new Vector3().fromArray(
          obj.userData.originalPosition || [0, 0, 0],
        )

        if (isGroup) gltf.returnToParent(obj)

        setExplosion(obj.name, ogPosition.toArray(), obj.position.toArray())
      }

      obj.matrixAutoUpdate = isMouseDown
    }

    if (explodeObjectRef.current.name === gltf.TEMP_GROUP_NAME) {
      const children = [...explodeObjectRef.current.children]
      children.forEach((child) => handleObject(child, true))
      explodeObjectRef.current.matrixAutoUpdate = isMouseDown
    } else {
      handleObject(explodeObjectRef.current, false)
    }

    if (!isMouseDown) {
      if (selected.length > 1) {
        const firstSelected = selected[0]
        selectable.reset()
        selectable.select(firstSelected)
      }
    }
  }

  // Manually force a re-render since explodeObjectRef does not trigger one
  const [, renderControls] = useState(0)

  useEffect(() => {
    if (!gltf) return

    if (!explosionsToolbar) {
      setObjectRef(null)
      return
    }

    const selectedObjects = selected
      .map((uuid) => getNode(uuid))
      .filter(
        (node) =>
          node &&
          node?.visible !== false &&
          !invisibleParts.includes(node.instance),
      )
      .map((node) =>
        gltf.getObjectByName(gltf.formatPartName(node?.instance) as string),
      )
      .filter((object) => object) as (Object3D | Group)[]

    const group = gltf.getTempGroup(selectedObjects)
    explodeObjectRef.current = group
    setObjectRef(explodeObjectRef)
    renderControls(Math.random())

    if (tree && !group) gltf.groupParts(tree)

    return () => {
      gltf.cleanupTempGroup()
    }
  }, [gltf, selected, explosionsToolbar, invisibleParts])

  useEffect(() => {
    if (!focusedPartUUID) return
    const el = document.getElementById(`part${focusedPartUUID}`)
    el?.scrollIntoView({ behavior: 'smooth' })
  }, [focusedPartUUID])

  return handleTransform
}

const useResetGLTF = (gltf?: GLTFObject) => {
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))
  const explosions = useCADPageStore((state) => state.explosions)
  const parts = useCADPageStore(useShallow((state) => state.transparentParts))

  const gltfRef = useRef<GLTFObject | null>(null)
  const partsRef = useRef<string[]>([])
  const explosionsRef = useRef({})
  const colorMapRef = useRef({})

  useEffect(() => {
    if (gltf) gltfRef.current = gltf
  }, [gltf])

  useEffect(() => {
    partsRef.current = parts
  }, [parts])

  useEffect(() => {
    explosionsRef.current = explosions
  }, [explosions])

  useEffect(() => {
    colorMapRef.current = colorMap
  }, [colorMap])

  useEffect(() => {
    return () => {
      if (gltfRef.current) {
        gltfRef.current.showExplosionLines(explosionsRef.current, false)
        gltfRef.current.resetExplosions(explosionsRef.current)
        gltfRef.current.resetExplosions(explosionsRef.current)
        gltfRef.current.clearDragLines()
        gltfRef.current.resetColors(colorMapRef.current)
        gltfRef.current.resetTransparency(partsRef.current)

        gltfRef.current = null
        partsRef.current = []
        explosionsRef.current = {}
        colorMapRef.current = {}

        setCadPageState({
          renderMode: 'full-color',
          explosionsToolbar: false,
          isDragging: false,
          explodeObjectRef: null,
          loadedExplosion: '',
          explosions: {},
          hiddenParts: [],
          colorMap: {},
          transparentParts: [],
          wandSelected: null,
          isExplosionLinesEnabled: false,
          selectedParts: [],
        })
      }
    }
  }, [])
}

export const Model = () => {
  const { isLoading, data } = useCADQuery()
  const { data: docData } = useDocumentPageQuery()
  const gltf = data?.gltf
  const documentType = docData?.documentType
  const getAssemblyTree = useAssemblyTree(
    useShallow((state) => state.getAssemblyTree),
  )
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const getNodeByInstanceName = useAssemblyTree(
    useShallow((state) => state.getNodeByInstanceName),
  )
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const explodeObjectRef = useCADPageStore((state) => state.explodeObjectRef)
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const updatePartColor = useCADPageStore((state) => state.updatePartColor)
  const updateTransparency = useCADPageStore(
    (state) => state.updateTransparency,
  )
  const isColored = useCADPageStore((state) => state.isColored)
  const isTransparent = useCADPageStore((state) => state.isTransparent)
  const renderMode = useCADPageStore((state) => state.renderMode)
  const loadingProgress = Math.round(
    useCADPageStore((state) => state.loadingProgress),
  )
  const handleTransform = useTransformControls(gltf)
  const selectable = useSelectable({})

  useAutoGroupNodes(gltf)
  useBounds(gltf)
  useExplosionLines(gltf)
  useWireframe(gltf)
  useResetGLTF(gltf)
  useBounds(gltf)

  if (isLoading || !documentType) {
    return (
      <Html center zIndexRange={[50, 0]} className="w-80 flex flex-col gap-2.5">
        <Progress value={loadingProgress} className="w-full" />
        <span className="text-xs text-gray-500 font-semibold text-center">
          {loadingProgress}%
        </span>
      </Html>
    )
  }

  /*
   * Handler for when the pointer enters a part in the CAD model.
   */
  const handlePointerEnter = ({ object }: ThreeEvent<PointerEvent>) => {
    const { isRotating, isDragging } = getCadPageState()
    const tree = getAssemblyTree()
    const explodeObject = explodeObjectRef?.current
    if (!isRotating && !explodeObject && !isDragging && gltf && tree) {
      const selection = gltf.findParentGroupObject(tree, object)

      if (selection) {
        const node = getNodeByInstanceName(selection.name)
        document.body.style.cursor = 'pointer'
        // Highlight part if it's not already selected
        if (
          node &&
          !(
            selectable.selected.length > 1 &&
            selectable.selected.includes(node.uuid)
          )
        ) {
          gltf.highlightPart(tree, selection.name)
          setCadPageState({ highlightedPartUUID: node.uuid })
        }
      }
    }
  }

  /*
   * Handler for when the pointer is pressed down on a part in the CAD model.
   */
  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    const { isRotating, isDragging } = getCadPageState()

    if (isRotating || isDragging) return

    const target = event.target as Element
    target.setPointerCapture(event.pointerId)

    const tree = getAssemblyTree()
    if (tree && gltf) {
      const selection = gltf.findParentGroupObject(tree, event.object)

      if (selection && selection.name) {
        const { wandSelected } = getCadPageState()

        if (wandSelected) {
          if (wandSelected === 'color') {
            if (isTransparent(selection.name))
              gltf.setTransparency(tree, selection.name)
            const result = gltf.setColor(selection.name, WAND_COLOR)
            updatePartColor(selection.name, WAND_COLOR, WAND_COLOR === result)
          } else if (wandSelected === 'transparency') {
            if (isColored(selection.name))
              gltf.setColor(selection.name, WAND_COLOR)
            const result = gltf.setTransparency(tree, selection.name)
            updateTransparency(selection.name, result)
          }
          return
        }

        const partId = getNodeByInstanceName(selection.name)?.uuid
        if (partId) {
          if (documentType !== 'work_instructions' || explosionsToolbar) {
            selectable.handlers(partId).onPointerDown(event)
          }
        }
      }
    }
  }

  /*
   * Handler for when the pointer is released or leves a part in the CAD model.
   */
  const handlePointerUpAndLeave = () => {
    const { isRotating, isDragging } = getCadPageState()
    const explodeObject = explodeObjectRef?.current
    if (!isRotating && !explodeObject && !isDragging && gltf) {
      gltf.unhighlightParts()
      document.body.style.cursor = 'default'
      setCadPageState({ highlightedPartUUID: null })

      if (selectable.selected.length > 1) {
        const tree = getAssemblyTree()
        selectable.selected.forEach((uuid) => {
          const node = getNode(uuid)
          if (tree && node) {
            gltf.highlightPart(tree, node.instance)
          }
        })
      }
    }
  }

  /**
   * General purpose handler for pointer events on the CAD Model.
   *
   * This handler calls setTimeout while the explosions toolbar is open to ensure
   * that any transform controls events are processed first.
   *
   * @param event - The pointer event.
   * @param handler - The respective event handler function.
   */
  const handlePointerEvent = (
    event: ThreeEvent<PointerEvent>,
    handler: (event: ThreeEvent<PointerEvent>) => void,
  ) => {
    event.stopPropagation()
    if (explosionsToolbar) {
      setTimeout(() => handler(event))
    } else {
      handler(event)
    }
  }

  return (
    <>
      <primitive
        object={gltf?.scene ?? {}}
        onPointerEnter={(event: ThreeEvent<PointerEvent>) => {
          handlePointerEvent(event, handlePointerEnter)
        }}
        onPointerLeave={(event: ThreeEvent<PointerEvent>) => {
          handlePointerEvent(event, handlePointerUpAndLeave)
        }}
        onPointerDown={(event: ThreeEvent<PointerEvent>) => {
          handlePointerEvent(event, handlePointerDown)
        }}
        onPointerUp={(event: ThreeEvent<PointerEvent>) => {
          const target = event.target as Element
          target.releasePointerCapture(event.pointerId)
          handlePointerEvent(event, handlePointerUpAndLeave)
        }}
      />
      {gltf?.wireFrameScene && renderMode === 'outline' && (
        <primitive object={gltf?.wireFrameScene ?? {}} />
      )}
      <>{gltf?.scene && <ControlPanel />}</>
      <>{gltf?.scene && <RenderControlsDropdown />}</>
      <CADPartLabel documentType={documentType} />
      {explosionsToolbar && explodeObjectRef && explodeObjectRef.current && (
        <TransformControls
          object={explodeObjectRef.current}
          onMouseDown={() => {
            setCadPageState({ isDragging: true })
            const { loadedExplosion } = getCadPageState()
            if (loadedExplosion) setCadPageState({ loadedExplosion: '' })
            handleTransform(true)
          }}
          onMouseUp={() => {
            handleTransform(false)
            setCadPageState({ isDragging: false })
          }}
        />
      )}
    </>
  )
}
