import { useCallback } from 'react'

import { DocumentPage } from '@/lib/api/client'
import { useListImages } from '@/services/queries/images'
import { useListCadViews } from '../queries/views/queries'
import { useListImageTemplates } from '@/services/hooks/template_attributes'

export const useDocumentPreview = ({
  documentPage,
  cadId,
  documentVersionId,
}: {
  cadId: string
  documentVersionId: string
  documentPage: DocumentPage
}) => {
  const {
    isLoading: isViewLoading,
    data: viewData,
    refetch: refetchViews,
  } = useListCadViews({ cadId })

  const {
    isLoading: isLoadingImages,
    data: images,
    refetch: refetchListImages,
  } = useListImages({
    documentVersionId,
  })
  const documentImages = useListImageTemplates(documentPage?.id || '').map(
    (template) => template.template_values.imageId,
  )

  const isLoading = isViewLoading || isLoadingImages

  const refetchImages = useCallback(() => {
    refetchListImages()
  }, [refetchListImages])

  return {
    isLoading,
    images,
    documentImages,
    viewData,
    refetchImages,
    refetchViews,
  }
}
