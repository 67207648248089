import { useParams } from 'wouter'
import { useShallow } from 'zustand/react/shallow'
import { useQuery, useMutation } from '@tanstack/react-query'

import {
  createDocument,
  createDocumentVersion,
  cadVersionUploaded,
  createCadVersion,
  uploadFile,
  View,
  DocumentTypeChoices,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import {
  useUpdateCadVersion,
  getCadVersionQuery,
} from '@/services/queries/cad_versions'
import {
  QUERY_KEYS as CAD_QUERY_KEYS,
  useCADQuery,
} from '@/services/queries/cads'
import { QUERY_KEYS as VIEW_JOB_QUERY_KEYS } from '@/services/queries/view_jobs'
import {
  getLatestDocumentVersion,
  QUERY_KEYS as DOCUMENT_VERSION_QUERY_KEYS,
} from '@/services/queries/document_versions'
import {
  useCreateDocumentPage,
  useDeleteDocumentPage,
  getDocumentPageQuery,
} from '@/services/queries/document_pages'
import { QUERY_KEYS as DOCUMENT_QUERY_KEYS } from '@/services/queries/documents'
import {
  useReorderDocumentPages,
  listDocumentPagesQuery,
} from '@/services/queries/document_pages'
import { useCreateView, useDeleteView } from '@/services/queries/views'
import {
  getNotesQuery,
  useDeleteNotes,
  useCreateViewTemplate,
} from '@/services/hooks/template_attributes'
import { useToast } from '@/components/ui/use-toast'
import { ASMTreeNode, useAppStore } from '@/state'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useAssemblyTree, RawAssemblyTree } from '@/state'
import { compressFile } from '@/lib/gzip'

export const useAssemblyTreeQuery = ({
  cadVersionId,
  projectId,
  documentId,
}: {
  cadVersionId: string
  projectId: string
  documentId: string
}) => {
  const setAssemblyTree = useAssemblyTree(
    useShallow((state) => state.setAssemblyTree),
  )

  return useQuery({
    staleTime: 0,
    refetchOnMount: true,
    enabled: Boolean(projectId && cadVersionId),
    queryKey: [CAD_QUERY_KEYS.CAD_ASSEMBLY_TREE, { projectId }],
    queryFn: async () => {
      if (!cadVersionId) {
        return
      }

      const cadVersion = await getCadVersionQuery({ cadVersionId })
      const documentVersion = documentId
        ? await getLatestDocumentVersion({
            documentId,
          })
        : undefined
      const documentPages = documentVersion
        ? await listDocumentPagesQuery({
            documentVersionId: documentVersion.id as string,
          })
        : []

      const rawAssemblyTree =
        cadVersion.assembly_tree as unknown as RawAssemblyTree

      if (rawAssemblyTree && documentPages) {
        setAssemblyTree(rawAssemblyTree, documentPages)
      }

      return { assemblyTree: rawAssemblyTree, documentPages }
    },
  })
}

export const useDeleteViewMutation = ({
  onDelete,
}: {
  onDelete?: () => void
}) => {
  const { toast } = useToast()
  const { mutateAsync: deleteView } = useDeleteView()
  const { mutateAsync: deleteNotes } = useDeleteNotes()

  return useMutation({
    mutationFn: async ({
      viewId,
      documentPageId,
    }: {
      viewId: string
      documentPageId?: string
    }) => {
      await deleteView({ viewId })

      if (documentPageId) {
        const notes = await getNotesQuery(documentPageId)
        const viewNotes = notes.filter(
          (note) => note.template_values?.viewId === viewId,
        )

        await Promise.all(
          viewNotes.map((note) => deleteNotes(note.id as string)),
        )
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [VIEW_JOB_QUERY_KEYS.VIEW_JOBS],
      })
      if (onDelete) {
        onDelete()
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDocument = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const getNodeByUUID = useAssemblyTree(useShallow((state) => state.getNode))
  const selectedPartUUID = useCADPageStore((s) =>
    s.selectedParts.length === 1 ? s.selectedParts[0] : null,
  )
  const { isLoading: isCadLoading, data: cad } = useCADQuery()

  const isEnabled = Boolean(selectedPartUUID && !isCadLoading && cad?.gltf)

  return useQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT, { projectId, selectedPartUUID }],
    queryFn: async () => {
      if (!isEnabled) {
        return { document: null, cad: null }
      }
      const node = getNodeByUUID(selectedPartUUID as string)

      if (!node) {
        throw new Error('No assembly node found')
      }

      if (!node.document_page_id) {
        return null
      }

      const documentPage = await getDocumentPageQuery({
        documentPageId: node.document_page_id,
      })

      return { documentPage, cadVersionId: cad.version?.id }
    },
    enabled: isEnabled,
  })
}

export const useViewCreateMutation = ({
  documentType,
  onSuccess,
}: {
  documentType: DocumentTypeChoices
  onSuccess?: () => void
}) => {
  const setDocumentPageId = useAssemblyTree(
    useShallow((state) => state.setDocumentPageId),
  )
  const getAssemblyTree = useAssemblyTree(
    useShallow((state) => state.getAssemblyTree),
  )
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))

  const { mutateAsync: updateCadVersion } = useUpdateCadVersion()
  const { mutateAsync: createDocumentPage } = useCreateDocumentPage()
  const { mutateAsync: reorderDocumentPages } = useReorderDocumentPages()
  const { mutateAsync: createView } = useCreateView()
  const { mutateAsync: createViewTemplate } = useCreateViewTemplate()
  const viewWorker = useAppStore((state) => state.viewWorker)

  return useMutation({
    mutationFn: async ({
      cadId,
      cadVersionId,
      documentPageId,
      docVersionId,
      assemblyPartUUID,
      projectId,
      values,
    }: {
      cadId: string
      cadVersionId: string
      documentPageId?: string | null
      docVersionId?: string | null
      assemblyPartUUID: string
      projectId: string
      values: View
    }) => {
      const didCreateDocument = !docVersionId

      if (!docVersionId) {
        const doc = await createDocument(projectId, {
          name: values.name,
          cad_id: cadId,
          document_type: documentType,
        })
        if (!doc.id) {
          throw new Error('Failed to create document')
        }
        const docVersion = await createDocumentVersion(doc.id)
        if (!docVersion.id) {
          throw new Error('Failed to create document version')
        }

        docVersionId = docVersion.id
      }

      const didCreateDocumentPage = !documentPageId
      let documentPage
      if (!documentPageId) {
        const node = getNode(assemblyPartUUID)
        const partName =
          node?.display_name || node?.instance || node?.product || 'part'
        const documentName =
          documentType === 'work_instructions'
            ? `${partName}-page`
            : documentType === 'project_tracker'
              ? `${partName}`
              : `${partName}-page`

        const { documentPage: newDocPage } = await createDocumentPage({
          documentVersionId: docVersionId,
          values: {
            name: documentName,
            assembly_group_id: assemblyPartUUID,
            template_values: {},
          },
        })
        documentPage = newDocPage
      } else {
        documentPage = await getDocumentPageQuery({ documentPageId })
      }

      if (!documentPage.id) {
        throw new Error('Failed to create document page')
      }

      const view = await createView({ cadVersionId, values })

      if (viewWorker && cadVersionId) {
        viewWorker.kick(cadVersionId)
      }

      if (!view.id) {
        throw new Error('Failed to create view')
      }

      await createViewTemplate({
        documentPageId: documentPage.id,
        viewTemplateData: {
          viewId: view.id,
        },
      })

      setDocumentPageId(assemblyPartUUID, documentPage.id, documentType)
      await updateCadVersion({
        cadVersionId,
        values: {
          assembly_tree: getAssemblyTree() as RawAssemblyTree,
        },
      })

      await reorderDocumentPages({
        documentVersionId: docVersionId,
        assemblyTree: getAssemblyTree() as RawAssemblyTree,
      })

      return {
        didCreateDocument,
        didCreateDocumentPage,
        documentPageId: documentPage.id,
        viewID: view.id,
      }
    },
    onSuccess: ({ didCreateDocument, didCreateDocumentPage }) => {
      if (didCreateDocument || didCreateDocumentPage) {
        queryClient.invalidateQueries({
          queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENTS],
        })
        queryClient.invalidateQueries({
          queryKey: [DOCUMENT_VERSION_QUERY_KEYS.DOCUMENT_VERSIONS],
        })
        queryClient.invalidateQueries({
          queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE],
        })
      }

      if (onSuccess) {
        onSuccess()
      }
    },
  })
}

export const useCreateCadVersionMutation = () => {
  const { projectId, documentId } = useParams<{
    projectId: string
    documentId: string
  }>()

  return useMutation({
    mutationFn: async ({
      cadId,
      comment,
      file,
    }: {
      cadId: string
      comment: string
      file: File
    }) => {
      const compressedFile = await compressFile(file)
      const cadVersion = await createCadVersion(cadId, {
        filename: compressedFile.name,
        comment,
      })

      const uploadUrl = cadVersion.upload_url
      if (!uploadUrl) {
        throw new Error('Failed to get upload URL')
      }
      await uploadFile(uploadUrl, compressedFile)
      await cadVersionUploaded(cadVersion.id as string)
      return { cadVersion, cadId }
    },
    onSuccess: () => {
      window.location.href = `/p/${projectId}/document/${documentId}/cad`
    },
  })
}

export const useUngroupAssemblyNodesMutation = () => {
  const { toast } = useToast()
  const viewWorker = useAppStore((state) => state.viewWorker)
  const ungroupNodes = useAssemblyTree(
    useShallow((state) => state.ungroupNodes),
  )
  const getAssemblyTree = useAssemblyTree(
    useShallow((state) => state.getAssemblyTree),
  )
  const { mutate: updateCadVersion } = useUpdateCadVersion()
  const getNodeByUUID = useAssemblyTree(useShallow((state) => state.getNode))
  const { mutateAsync: reorderDocumentPages } = useReorderDocumentPages()
  const { mutateAsync: deleteDocumentPage } = useDeleteDocumentPage()
  const { data: cadData } = useCADQuery()
  const gltf = cadData.gltf

  return useMutation({
    mutationFn: async ({
      groupUUID,
      cadVersionId,
      documentVersionId,
    }: {
      groupUUID: string
      cadVersionId: string
      documentVersionId?: string | null
    }) => {
      const groupNode = getNodeByUUID(groupUUID)
      if (!groupNode) {
        return {}
      }

      ungroupNodes(groupUUID)
      const assemblyTree = getAssemblyTree()

      if (!assemblyTree) {
        return {}
      }

      await updateCadVersion({
        cadVersionId,
        values: {
          assembly_tree: assemblyTree,
        },
      })

      if (documentVersionId && groupNode.document_page_id) {
        await deleteDocumentPage({ documentPageId: groupNode.document_page_id })
        await reorderDocumentPages({ documentVersionId, assemblyTree })
      }

      if (gltf) {
        gltf.groupParts(assemblyTree)
      }

      return { cadVersionId }
    },
    onSuccess: ({ cadVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (viewWorker && cadVersionId) {
        viewWorker.kick(cadVersionId)
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateCadVersionMutation = () => {
  const { toast } = useToast()
  const viewWorker = useAppStore((state) => state.viewWorker)
  const { mutateAsync: updateCadVersion } = useUpdateCadVersion()
  const { mutateAsync: reorderDocumentPages } = useReorderDocumentPages()

  return useMutation({
    mutationFn: async ({
      cadVersionId,
      documentVersionId,
      assemblyTree,
    }: {
      cadVersionId: string
      documentVersionId: string
      assemblyTree: RawAssemblyTree
    }) => {
      await updateCadVersion({
        cadVersionId,
        values: {
          assembly_tree: assemblyTree,
        },
      })

      if (documentVersionId) {
        await reorderDocumentPages({ documentVersionId, assemblyTree })
      }
      return { cadVersionId }
    },
    onSuccess: ({ cadVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (viewWorker && cadVersionId) {
        viewWorker.kick(cadVersionId)
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useCreateGroupDocumentMutation = () => {
  const getAssemblyTree = useAssemblyTree(
    useShallow((state) => state.getAssemblyTree),
  )
  const setAssemblyTree = useAssemblyTree(
    useShallow((state) => state.setAssemblyTree),
  )
  const { mutateAsync: updateCadVersion } = useUpdateCadVersion()
  const { mutateAsync: deleteDocumentPage } = useDeleteDocumentPage()
  const { toast } = useToast()
  const viewWorker = useAppStore((state) => state.viewWorker)
  const { data: cadData } = useCADQuery()
  const gltf = cadData.gltf

  return useMutation({
    mutationFn: async ({
      cadVersionId,
      documentVersionId,
      assemblyTree,
      group,
    }: {
      cadVersionId: string
      documentVersionId: string
      assemblyTree: RawAssemblyTree
      group: ASMTreeNode
    }) => {
      const documentPages = await listDocumentPagesQuery({ documentVersionId })

      const childDocumentPages = documentPages.filter((page) => {
        return page.id && group.children.includes(page.assembly_group_id)
      })

      if (childDocumentPages.length === 0) {
        await updateCadVersion({
          cadVersionId,
          values: {
            assembly_tree: assemblyTree,
          },
        })
        setAssemblyTree(assemblyTree, documentPages)
        const updatedTree = getAssemblyTree()
        if (updatedTree && gltf) {
          gltf.groupParts(updatedTree)
        }
        return { cadVersionId }
      }

      for (const childDocumentPage of childDocumentPages) {
        if (childDocumentPage.id) {
          await deleteDocumentPage({ documentPageId: childDocumentPage.id })
        }
      }

      const updatedDocumentPages = documentPages.filter(
        (doc) => !childDocumentPages.find((cdoc) => cdoc.id === doc.id),
      )

      await updateCadVersion({
        cadVersionId,
        values: {
          assembly_tree: assemblyTree,
        },
      })
      setAssemblyTree(assemblyTree, updatedDocumentPages)

      const updatedTree = getAssemblyTree()
      if (updatedTree && gltf) {
        gltf.groupParts(updatedTree)
      }

      return { documentPages: updatedDocumentPages, cadVersionId }
    },
    onSuccess: ({ cadVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (viewWorker && cadVersionId) {
        viewWorker.kick(cadVersionId)
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}
