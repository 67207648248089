import Emoji from '@tiptap-pro/extension-emoji'

export const Icons = Emoji.extend({
  addCommands() {
    return {
      addIcon:
        (icon, color) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              name: icon,
              color: color,
            },
          })
        },
    } as any
  },

  addAttributes() {
    return {
      name: {
        default: null,
      },
      color: {
        default: null,
        parseHTML: (element) =>
          (element.getAttribute('data-type') === 'emoji' &&
            element.getAttribute('color')) ||
          element.getAttribute('data-color'),
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {}
          }

          return {
            'data-color': attributes.color,
          }
        },
      },
    }
  },
  // @ts-expect-error - Types are wrong
  onCreate({ editor }) {
    const editorContainer = editor.options.element
    if (!editorContainer) return

    const icons = editorContainer.querySelectorAll('[data-type="emoji"]') || []
    icons.forEach((icon) => {
      const color = icon.getAttribute('data-color')
      if (color) {
        icon.style = `color: ${color}`
      }
    })
  },
  // @ts-expect-error - Types are wrong
  onUpdate({ editor }) {
    const editorContainer = editor.options.element
    if (!editorContainer) return

    const icons = editorContainer.querySelectorAll('[data-type="emoji"]') || []
    icons.forEach((icon) => {
      const color = icon.getAttribute('data-color')
      if (color) {
        icon.style = `color: ${color}`
      }
    })
  },
})
