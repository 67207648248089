import { useRef } from 'react'

import { Button } from '@/components/ui/button'
import { useAnnotationsQuery } from './queries'
import { IconStrokes, MenuIconSizes } from './enums'
import { IconProps } from '@/components/icons/AnnotationsIcons'
import { DEFAULT_ANNOTATION_SIZE, DEFAULT_STROKE_COLOR } from './constants'
import { cn } from '@/utils'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useDocumentState } from '@/state'

export type IconSize = 'small' | 'medium' | 'large' | 'default' | number
export type Icon = ({
  className,
  id,
  strokeWidth,
  strokeColor,
}: IconProps) => JSX.Element
type AnnotationsToolbarMenuItemProps = {
  Icon: Icon
  id: string
  size?: IconSize
  strokeColor?: string
  bubbleValue?: string
}
export const AnnotationsToolbarMenuItem = (
  props: AnnotationsToolbarMenuItemProps,
) => {
  const itemRef = useRef<HTMLDivElement>(null)

  return (
    <div className="relative" ref={itemRef}>
      <AnnotationsToolbarMenuItemContent {...props} />
    </div>
  )
}

const AnnotationsToolbarMenuItemContent = ({
  Icon,
  id,
  size = DEFAULT_ANNOTATION_SIZE,
  strokeColor,
  className,
  bubbleValue,
}: AnnotationsToolbarMenuItemProps & { className?: string }) => {
  const { startAddingAnnotation, isAddingAnnotation } = useAnnotationsQuery()
  const selectedPart = useCADPageStore(
    (state) => (state.selectedParts?.length && state.selectedParts[0]) || '',
  )
  const selectedPage = useDocumentState((state) => state.currentDocumentPageId)

  return (
    <Button
      className={cn(
        className,
        'flex items-center justify-center w-8 h-8 p-0.5',
      )}
      variant="outline"
      disabled={isAddingAnnotation}
      onClick={(e) => {
        startAddingAnnotation({
          newAnnotation: { Icon, id, size, strokeColor, bubbleValue },
          triggerLocation: { x: e.clientX, y: e.clientY },
          assemblyGroupId: selectedPart,
          documentPageId: selectedPage,
        })
      }}
    >
      <Icon
        className={MenuIconSizes[size || ''] ?? MenuIconSizes['medium']}
        strokeWidth={(IconStrokes[size || ''] ?? 0) + 1}
        strokeColor={strokeColor ?? DEFAULT_STROKE_COLOR}
      />
    </Button>
  )
}

export default AnnotationsToolbarMenuItem
