import { create } from 'zustand'
import { toPng } from 'html-to-image'
import queryClient from '@/queryClient'
import { QueriesObserver } from '@tanstack/react-query'

type ImageUrls = string[]

interface PDFifyState {
  pageRefs: {
    [pageId: string]: {
      ref: HTMLDivElement
      order: number
    }
  }
  imageUrls: ImageUrls
  isGenerating: boolean
  error: string | null
  addPageRef: (pageId: string, order: number, ref: HTMLDivElement) => void
  removePageRef: (pageId) => void
  generatePDF: (
    pageCount: number,
    retry?: number,
  ) => Promise<ImageUrls | undefined>
}

const sleep = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

const waitForQueriesToFinish = async () => {
  const queries = queryClient.getQueryCache().findAll()
  const observer = new QueriesObserver(queryClient, queries)
  return new Promise((resolve) => {
    const unsubscribe = observer.subscribe((result) => {
      const pendingQueries = result.filter((query) => query.isPending)
      if (pendingQueries.length === 0) {
        resolve({})
        unsubscribe()
      }
    })
  })
}

export const usePDFifyStore = create<PDFifyState>((set, get) => ({
  isGenerating: false,
  error: null,
  imageUrls: [],
  pageRefs: {},

  addPageRef: (pageId, order, ref) =>
    set((state) => ({
      pageRefs: { ...state.pageRefs, [pageId]: { ref, order } },
    })),

  removePageRef: (pageId) =>
    set((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [pageId]: _, ...rest } = state.pageRefs
      return { pageRefs: rest }
    }),

  generatePDF: async (
    pageCount: number,
    retry?: number,
  ): Promise<ImageUrls | undefined> => {
    const maxRetries = 5
    const retryCount = retry || 0
    set({ isGenerating: true, error: null, imageUrls: [] })
    await waitForQueriesToFinish()
    await sleep(5000)
    try {
      const pageEls = Object.values(get().pageRefs)
        .sort((a, b) => a.order - b.order)
        .map((page) => page.ref)

      const imgUrls: ImageUrls = []
      for (const page of pageEls) {
        const blob = await toPng(page, {
          quality: 1,
          pixelRatio: 2,
          backgroundColor: 'white',
        })
        if (!blob) {
          throw new Error(
            'Failed to generate PDF: failed to convert page to image',
          )
        }
        imgUrls.push(blob)
      }

      set({ imageUrls: imgUrls })
      if (imgUrls.length !== pageCount && retryCount < maxRetries) {
        await sleep(2000)
        return get().generatePDF(pageCount, retryCount + 1)
      }
      return imgUrls
    } catch (error: any) {
      const message =
        error instanceof Error ? error.message : 'Failed to generate PDF'
      console.error(message)
      set({ error: message })
    } finally {
      set({ isGenerating: false })
    }
  },
}))
