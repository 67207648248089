import { SERVICE_WORKER_ACTIONS } from './constants'
export class ServiceWorker {
  private worker: globalThis.ServiceWorker | null
  constructor() {
    this.worker = null
    this.start()
  }

  async start() {
    if ('serviceWorker' in navigator) {
      const serviceWorkerUrl = new URL(
        '/serviceWorkerProcess.js',
        import.meta.url,
      )
      const registrations = await navigator.serviceWorker.getRegistrations()
      const registration = registrations.find(
        (registration) =>
          registration.active?.scriptURL === serviceWorkerUrl.href,
      )

      if (registration) {
        await registration.update()
      }

      const { installing, waiting, active } =
        await navigator.serviceWorker.register(serviceWorkerUrl, {
          type: 'module',
        })
      this.worker = active || installing || waiting

      this.worker?.addEventListener('statechange', (event: any) => {
        if (event.target?.state === 'activated') {
          this.worker = event.target
        }
      })
    }
  }

  public deleteCache(cacheKey: string) {
    if (this.worker) {
      this.worker.postMessage({
        action: SERVICE_WORKER_ACTIONS.DELETE_CACHE,
        payload: {
          cacheKey,
        },
      })
    }
  }

  public addToCache({
    cacheKey,
    response,
  }: {
    cacheKey: string
    response: Response
  }) {
    if (this.worker) {
      this.worker.postMessage({
        action: SERVICE_WORKER_ACTIONS.ADD_TO_CACHE,
        payload: {
          cacheKey,
          response: response.clone(),
        },
      })
    }
  }
}
