import { DocumentTemplate } from './DocumentTemplate'

export const TitleDocument = ({
  documentName,
  versionNumber,
  lastUpdated,
}: {
  documentName: string
  versionNumber: number
  lastUpdated: string
}) => {
  return (
    <DocumentTemplate
      id="cover"
      lastUpdated={lastUpdated}
      versionNumber={versionNumber}
      order={1}
    >
      <div className="mt-20 flex flex-col space-y-2">
        <h1 className="text-3xl font-bold">{documentName}</h1>
        <h2 className="text-lg">Assembly instructions</h2>
      </div>
    </DocumentTemplate>
  )
}
