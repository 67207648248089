import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { ViewInput, View } from './types'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * Create a new view
 * @param cadVersionId
 * @param values
 * @returns
 */
export const createView = async (cadVersionId: string, values: ViewInput) => {
  await getCsrf()
  const resp = await client.POST('/api/v1/projects/{cad_version_id}/views', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        cad_version_id: cadVersionId,
      },
    },
    body: values as any,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create view')
  }

  // @ts-expect-error - The response data is a view
  const view = resp.data as View

  return view
}

/**
 * Update a view
 * @param viewId
 * @param values
 * @returns
 */
export const updateView = async (viewId: string, values: ViewInput) => {
  await getCsrf()
  const resp = await client.PUT('/api/v1/projects/views/{view_id}', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        view_id: viewId,
      },
    },
    body: values as any,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to update view')
  }

  // @ts-expect-error - The response data is a view
  const view = resp.data as View

  return view
}

/**
 * Get a view
 * @param viewId
 * @returns
 */
export const getView = async (viewId: string) => {
  const resp = await client.GET('/api/v1/projects/views/{view_id}', {
    params: {
      path: {
        view_id: viewId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get view')
  }

  // @ts-expect-error - The response data is a view
  const view = resp.data as View

  return view
}

/**
 * List views
 * @param cadVersionId
 * @returns
 */
export const listViews = async (cadVersionId: string) => {
  const resp = await client.GET('/api/v1/projects/{cad_version_id}/views', {
    params: {
      path: {
        cad_version_id: cadVersionId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list views')
  }

  // @ts-expect-error - The response data is a view
  const view = resp.data as View[]

  return view
}

/**
 * List views
 * @param cadId
 * @returns
 */
export const listCadViews = async (cadId: string) => {
  const resp = await client.GET('/api/v1/projects/cads/{cad_id}/views', {
    params: {
      path: {
        cad_id: cadId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list views')
  }

  // @ts-expect-error - The response data is a view
  const view = resp.data as View[]

  return view
}

/**
 * Delete a view
 * @param viewId
 * @returns
 */
export const deleteView = async (viewId: string) => {
  await getCsrf()
  const resp = await client.DELETE('/api/v1/projects/view/{view_id}', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        view_id: viewId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to delete view')
  }

  return resp.data
}
