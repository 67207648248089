import { ReactNode } from 'react'
import { cn } from '@/utils'
import {
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DndContext,
  DndContextProps,
} from '@dnd-kit/core'
import {
  SortableContext as SortableContextPrimative,
  SortableContextProps,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export const SortableContext = ({
  children,
  items,
  strategy,
  id,
  disabled,
  ...props
}: SortableContextProps & DndContextProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} {...props}>
      <SortableContextPrimative
        items={items}
        strategy={strategy || verticalListSortingStrategy}
        id={id}
        disabled={disabled}
      >
        {children}
      </SortableContextPrimative>
    </DndContext>
  )
}

export const SortableItem = ({
  id,
  children,
}: {
  id: string
  children: ReactNode
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn('relative', {
        'z-50': isDragging,
      })}
    >
      {children}
    </div>
  )
}
