import { useQuery, useMutation } from '@tanstack/react-query'
import { useLocation } from 'wouter'

import queryClient from '@/queryClient'
import { verifyLogin, login, logout } from '@/lib/api/client'
import QUERY_KEYS from './queryKeys'
import MUTATION_KEYS from './mutationKeys'

type useVerifyLoginProps = {
  enabled?: boolean
  onSuccess?: () => void
}

const defaultUseVerifyLoginProps: useVerifyLoginProps = {
  enabled: true,
}

const verifyLoginQueryFn = async (onSuccess?: () => void) => {
  const resp = await verifyLogin()

  if (resp.response.ok && onSuccess) {
    onSuccess()
  }

  return {
    is_logged_in: resp.response.ok,
  }
}

export const useVerifyLogin = (props?: useVerifyLoginProps) => {
  const { enabled, onSuccess } = {
    ...defaultUseVerifyLoginProps,
    ...props,
  }
  const { data, isLoading, isError, refetch } = useQuery({
    enabled,
    refetchOnMount: true,
    staleTime: 0,
    queryKey: [QUERY_KEYS.VERIFY_LOGIN],
    queryFn: () => verifyLoginQueryFn(onSuccess),
  })

  return {
    isLoggedIn: !isLoading && data?.is_logged_in,
    isVerifyingLoginStatus: isLoading,
    loginVerificationFailed: isError,
    refetchVerifyLogin: refetch,
  }
}

export const verifyLoginQuery = (props?: useVerifyLoginProps) =>
  queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.VERIFY_LOGIN],
    queryFn: () => verifyLoginQueryFn(props?.onSuccess),
    staleTime: 0,
  })

export const useLogin = () => {
  const [, setLocation] = useLocation()
  const { mutate, data, isPending, isError } = useMutation({
    mutationKey: [MUTATION_KEYS.LOGIN],
    mutationFn: async (values: { email: string; password: string }) => {
      const loginResp = await login(values.email, values.password)
      const { is_logged_in } = await verifyLoginQuery()

      if (loginResp.response.ok && is_logged_in) {
        setLocation('/')
      } else {
        throw new Error('Login failed!')
      }

      return {
        loginSuccessful: !!loginResp.response.ok && is_logged_in,
      }
    },
  })

  return {
    handleLogin: mutate,
    isLogginIn: isPending,
    isLoggedIn: data?.loginSuccessful,
    loginFailed: isError,
  }
}

export const useLogout = () => {
  const [, setLocation] = useLocation()
  const { mutate, data, isPending, isError } = useMutation({
    mutationKey: [MUTATION_KEYS.LOGOUT],
    mutationFn: async () => {
      const logoutResp = await logout()
      const { is_logged_in } = await verifyLoginQuery()

      if (logoutResp.response.ok && !is_logged_in) {
        setLocation('/login')
      } else {
        throw new Error('Login failed!')
      }

      return {
        logOutSuccessful: !!logoutResp.response.ok && !is_logged_in,
      }
    },
    onSuccess: () => {
      queryClient.clear()
    },
  })

  return {
    handleLogout: mutate,
    isLogginOut: isPending,
    isLoggedOut: data?.logOutSuccessful,
    logoutFailed: isError,
  }
}

export { QUERY_KEYS, MUTATION_KEYS }
