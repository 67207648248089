import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { components as apiComponents } from '@/lib/api/types'
import { getCsrf, View } from '@/lib/api/client'

import { VIEW_WORKER_ACTIONS } from './constants'
import queryClient from '@/queryClient'
import { QUERY_KEYS } from '@/services/queries/views'

type ViewJob = apiComponents['schemas']['ViewJobOut']

// https://github.com/F-loat/offscreen-canvas-demo/tree/main

interface ViewWorkerOptions {
  onUploadsCompleted?: (viewIds: string[]) => void
  onUploadsInProgress?: (
    viewIds: {
      viewId: string
      assemblyGroupId: string
      documentOrder?: number | null
    }[],
  ) => void
  onScreenshot?: (image: string) => void
}

export class ViewWorker {
  worker: Worker | null
  canvas: HTMLCanvasElement | null
  offscreenCanvas: OffscreenCanvas | null
  jobs: {
    [id: string]: {
      job: ViewJob
    }
  }
  loader: GLTFLoader
  options: ViewWorkerOptions

  constructor(options: ViewWorkerOptions = {}) {
    this.options = options
    this.worker = null
    this.canvas = null
    this.offscreenCanvas = null
    this.jobs = {}
    this.loader = new GLTFLoader()
    this.start()
  }

  start() {
    this.worker = new Worker(new URL('./ViewWorkerProcess', import.meta.url), {
      type: 'module',
    })

    this.canvas = document.createElement('canvas')
    this.canvas.style.display = 'block'
    this.canvas.width = 1080
    this.canvas.height = 1024
    this.offscreenCanvas = this.canvas.transferControlToOffscreen()

    this.worker.onmessage = (message) => {
      const { action, payload } = message.data
      if (action === VIEW_WORKER_ACTIONS.UPLOAD_VIEW) {
        this.__uploadView(payload.job)
      } else if (action === VIEW_WORKER_ACTIONS.VIEWS_IN_PROGRESS) {
        if (typeof this.options.onUploadsInProgress === 'function') {
          this.options.onUploadsInProgress(payload)
        }
      } else if (action === VIEW_WORKER_ACTIONS.VIEWS_COMPLETED) {
        if (typeof this.options.onUploadsCompleted === 'function') {
          this.options.onUploadsCompleted(payload)
        }
      }
    }

    this.worker.postMessage(
      {
        action: VIEW_WORKER_ACTIONS.INIT,
        payload: {
          offscreenCanvas: this.offscreenCanvas,
        },
      },
      [this.offscreenCanvas],
    )
  }

  async kick(cadVersionId: string) {
    if (!this.worker || !this.canvas) {
      return
    }

    const csrf = await getCsrf()

    this.worker.postMessage(
      {
        action: VIEW_WORKER_ACTIONS.KICK_JOBS,
        payload: {
          canvasWidth: this.canvas.width,
          canvasHeight: this.canvas.height,
          cadVersionId,
          csrf,
        },
      },
      [],
    )
  }

  __uploadView(job: ViewJob) {
    if (!this.canvas || !this.worker) {
      return
    }

    const base64Image = this.canvas.toDataURL('image/png')
    const viewsUpdaterFn = (data: any) => {
      const view = data?.find((view: View) => view.id === job.view.id)
      if (view) {
        return data.map((view: View) => {
          if (view.id === job.view.id) {
            return {
              ...view,
              download_url: base64Image,
            }
          }

          return view
        })
      }
      return [
        ...data,
        {
          ...job.view,
          download_url: base64Image,
        },
      ]
    }
    queryClient.setQueriesData(
      {
        queryKey: [QUERY_KEYS.VIEWS, { cadVersionId: job.view.cad_version }],
      },
      viewsUpdaterFn,
    )

    queryClient.setQueriesData(
      {
        queryKey: [QUERY_KEYS.CAD_VIEWS],
      },
      viewsUpdaterFn,
    )

    this.canvas.toBlob(
      (blob) => {
        if (!blob) {
          return
        }
        const image = URL.createObjectURL(blob)
        if (this.options.onScreenshot) {
          this.options.onScreenshot(image)
        }
        this.worker?.postMessage(
          {
            action: VIEW_WORKER_ACTIONS.UPLOAD_VIEW,
            payload: {
              job,
              blob,
            },
          },
          [],
        )
      },
      'image/png',
      1,
    )
  }
}
