import { Editor, Range } from '@tiptap/react'
import { Spinner } from '@/components/ui/spinner'
import { useListTemplateAttributes } from '@/services/queries/template_attributes'
import { useAssemblyTree } from '@/state'
import type { EditorAttributes } from '@/components/editor/lib/types'

const MAX_BOM_ROWS = 3

interface AddPartFormProps {
  query: string
  editor: Editor
  range: Range
}

export const AddPartForm = ({ query, editor }: AddPartFormProps) => {
  const editorAttributes = editor.options.editorProps
    .attributes as EditorAttributes
  const documentPageId = editorAttributes.documentPageId
  const assemblyGroupId = editorAttributes.assemblyGroupId

  const bom = useAssemblyTree((state) =>
    state.getBillOfMaterialsForPart(assemblyGroupId),
  )

  const templateAttributes = useListTemplateAttributes({
    documentPageId,
  })

  const customParts = templateAttributes.data?.filter(
    (attr) => attr.data_type === 'part_custom_row',
  )
  const totalRows = bom.length + (customParts?.length || 0)

  if (templateAttributes.isLoading) {
    return <Spinner />
  }

  if (totalRows >= MAX_BOM_ROWS) {
    return <div>No result</div>
  }

  return (
    <div>
      <div className="px-2 py-2 break-all">
        Add{' '}
        <span
          style={{
            padding: '2px 6px',
            borderRadius: '5px',
            backgroundColor: '#DAF2EF',
            color: 'rgb(30, 30, 30)',
          }}
        >
          {query}
        </span>{' '}
        to BOM?
      </div>
    </div>
  )
}
