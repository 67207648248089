import { MinimalLayout } from '@/components/layouts/MinimalLayout'
import { EditorNavbar } from '@/components/nav/EditorNavbar'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable'

import { Documents } from './components/Documents'
import { TableOfContents } from './components/TableOfContents'
import { AnnotationsToolbar } from './components/Annotations/AnnotationsToolbar'

export const DocumentPage = () => {
  return (
    <MinimalLayout>
      <EditorNavbar active="document" />
      <div className="w-full bg-white" style={{ height: 'calc(100vh - 65px)' }}>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel defaultSize={21} minSize={15} maxSize={40}>
            <TableOfContents />
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel className="relative">
            <AnnotationsToolbar />
            <Documents />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </MinimalLayout>
  )
}
