import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * Create a new document version
 * @param documentId
 * @param comment
 * @returns
 */
export const createDocumentVersion = async (
  documentId: string,
  comment?: string,
) => {
  await getCsrf()
  const resp = await client.POST(
    '/api/v1/projects/{document_id}/documentversions',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_id: documentId,
        },
      },
      body: {
        name: '',
        comment: comment ?? '',
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create document version')
  }

  return resp.data
}

/**
 * List all document versions in a project
 * @param cadId
 * @returns
 */
export const listDocumentVersions = async (documentId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/{document_id}/documentversions',
    {
      params: {
        path: {
          document_id: documentId,
        },
      },
    },
  )
  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get CAD versions')
  }
  return resp.data
}

/**
 * Get a document version by id
 * @param documentVersionId
 * @returns
 */
export const getDocumentVersion = async (documentVersionId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/documentversions/{document_version_id}',
    {
      params: {
        path: {
          document_version_id: documentVersionId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get document version')
  }
  return resp.data
}
