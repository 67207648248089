import { useQuery } from '@tanstack/react-query'
import type { CommandItem } from '../commands/types'
import { useAssemblyTree } from '@/state'
import commands from '../commands'

export const useCommandItems = ({
  commandName,
  documentPageId,
  assemblyGroupId,
}: {
  commandName: string
  documentPageId: string
  assemblyGroupId: string
}) => {
  const bom = useAssemblyTree((state) =>
    state.getBillOfMaterialsForPart(assemblyGroupId),
  )

  return useQuery({
    staleTime: 0,
    queryKey: ['editorCommands', commandName, documentPageId, assemblyGroupId],
    queryFn: async (): Promise<CommandItem[]> => {
      const command = commands.find((command) => command.name === commandName)
      if (!command) {
        return []
      }
      return command.getItems({
        documentId: documentPageId,
        assemblyGroupId,
        bom,
      })
    },
  })
}
