import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useToast } from '@/components/ui/use-toast'
import {
  useCreateTemplateAttribute,
  useUpdateTemplateAttribute,
  useListTemplateAttributes,
  useDeleteTemplateAttribute,
  listTemplateAttributesQuery,
  createTemplateAttributeMutation,
} from '@/services/queries/template_attributes'
import { MUTATION_KEYS } from '@/services/queries/template_attributes'
import { TemplateAttribute as TemplateAttributeType } from '@/lib/api/client'
import {
  Annotation,
  Line,
} from '@/pages/DocumentPage/components/Annotations/queries'
import queryClient from '@/queryClient'

export type TemplateAttribute<T> = TemplateAttributeType & {
  template_values: T
}
export type Note = {
  editorVersion: number
  content: string
  viewId: string
}
export type ImageTemplate = {
  imageId: string
}

export type CustomColumn = {
  [accessor: string]: string
}

export type CustomColumnData = {
  [accessor: string]: string
}

export type CustomRow = {
  [accessor: string]: string
}

export type ToolsColumn = {
  [accessor: string]: string
}

export type ToolsRow = {
  [accessor: string]: string
}

export type PositionPayload = {
  position?: {
    x: number
    y: number
  }
  removed?: boolean
  size?: {
    width: number
    height: number
  }
  columnsSize?: {
    [accessor: string]: number
  }
  movableElementId: string
  type: string
}

export const useCreateDocumentNotes = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute({
    shouldOptimisticUpdate: false,
  })

  return useMutation({
    mutationFn: async ({
      documentPageId,
      notesData,
    }: {
      documentPageId: string
      notesData: any
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'note',
            template_values: notesData,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateDocumentNotes = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute({
    shouldOptimisticUpdate: false,
  })

  return useMutation({
    mutationFn: async ({
      templateAttributeId,
      notesData,
    }: {
      templateAttributeId: string
      notesData: any
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'note',
            template_values: notesData,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteNotes = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListNotes = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Notes type
  const notes: Array<TemplateAttribute<Note>> = useMemo(() => {
    if (isSuccess && data) {
      return data.filter(
        (templateAttribute) => templateAttribute.data_type === 'note',
      )
    }

    return []
  }, [data, isSuccess])

  return notes
}

export const useCreateViewTemplate = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      viewTemplateData,
    }: {
      documentPageId: string
      viewTemplateData: any
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'view',
            template_values: viewTemplateData,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListViews = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Views type
  const views: Array<TemplateAttribute<View>> = useMemo(() => {
    if (isSuccess && data) {
      return data.filter(
        (templateAttribute) => templateAttribute.data_type === 'view',
      )
    }

    return []
  }, [data, isSuccess])

  return views
}

export const getNotesQuery = async (documentPageId?: string) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  // @ts-expect-error - Defining Notes type
  const notes: Array<TemplateAttribute<Note>> = templateAttributes.filter(
    (templateAttribute) => templateAttribute.data_type === 'note',
  )

  return notes
}

export const useCreateAnnotation = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: ({
      documentPageId,
      annotationData,
    }: {
      documentPageId: string
      annotationData: Annotation | Line
    }) => {
      return createTemplateAttribute({
        documentPageId,
        payload: {
          data_type: 'annotation',
          template_values: annotationData as any,
        },
      })
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateAnnotation = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationFn: async (updatedAnnotation: Annotation | Line) => {
      if (updatedAnnotation.id) {
        await updateTemplateAttribute({
          templateAttributeId: updatedAnnotation.id,
          payload: {
            data_type: 'annotation',
            template_values: updatedAnnotation as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteAnnotation = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListAnnotations = (documentPageId?: string) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Annotation type
  const annotations: Array<TemplateAttribute<Annotation | Line>> =
    useMemo(() => {
      if (isSuccess && data) {
        return data.filter(
          (templateAttribute) => templateAttribute.data_type === 'annotation',
        )
      }

      return []
    }, [data, isSuccess])

  return annotations
}

export const getAnnotationsQuery = async (documentPageId?: string | null) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  // @ts-expect-error - Defining Annotation type
  const annotations: Array<TemplateAttribute<Annotation | Line>> =
    templateAttributes.filter(
      (templateAttribute) => templateAttribute.data_type === 'annotation',
    )

  return annotations
}

export const useCreateImageTemplate = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: ({
      documentPageId,
      imageData,
    }: {
      documentPageId: string
      imageData: ImageTemplate
    }) => {
      return createTemplateAttribute({
        documentPageId,
        payload: {
          data_type: 'image',
          template_values: imageData as any,
        },
      })
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteImageTemplate = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListImageTemplates = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Image template type
  const imageTemplates: Array<TemplateAttribute<ImageTemplate>> =
    useMemo(() => {
      if (isSuccess && data) {
        return data.filter(
          (templateAttribute) => templateAttribute.data_type === 'image',
        )
      }

      return []
    }, [data, isSuccess])

  return imageTemplates
}

export const getImageTemplatesQuery = async (
  documentPageId?: string | null,
) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  // @ts-expect-error - Defining Image template type
  const imageTemplates: Array<TemplateAttribute<ImageTemplate>> =
    templateAttributes.filter(
      (templateAttribute) => templateAttribute.data_type === 'image',
    )

  return imageTemplates
}

export const useCreatePartsCustomColumn = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      customColumn,
    }: {
      documentPageId: string
      customColumn: CustomColumn
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'parts_custom_columns',
            template_values: [customColumn] as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdatePartsCustomColumns = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      templateAttributeId,
      customColumns,
    }: {
      templateAttributeId: string
      customColumns: Array<CustomColumn>
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'parts_custom_columns',
            template_values: customColumns as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeletePartsCustomColumns = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_CUSTOM_COLUMN],
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListPartsCustomColumns = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  const partsCustomColumns: TemplateAttribute<CustomColumn> | undefined =
    useMemo(() => {
      if (isSuccess && data) {
        return data.find(
          (templateAttribute) =>
            templateAttribute.data_type === 'parts_custom_columns',
        )
      }

      return
    }, [data, isSuccess])

  return partsCustomColumns
}

export const getPartsCustomColumnsQuery = async (documentPageId?: string) => {
  if (!documentPageId) return

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return
  return templateAttributes.find(
    (templateAttribute) =>
      templateAttribute.data_type === 'parts_custom_columns',
  ) as TemplateAttribute<CustomColumn> | undefined
}

export const useCreatePartsCustomColumnsData = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      rowData,
    }: {
      documentPageId: string
      rowData: CustomColumnData
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'parts_columns_data',
            template_values: [rowData] as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdatePartsCustomColumnsData = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      templateAttributeId,
      rowsData,
    }: {
      templateAttributeId: string
      rowsData: Array<CustomColumnData>
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'parts_columns_data',
            template_values: rowsData as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeletePartsCustomColumnsData = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_CUSTOM_COLUMNS_DATA],
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListPartsCustomColumnsData = (
  documentPageId?: string | null,
) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  const partsCustomColumnsData:
    | TemplateAttribute<CustomColumnData>
    | undefined = useMemo(() => {
    if (isSuccess && data) {
      return data.find(
        (templateAttribute) =>
          templateAttribute.data_type === 'parts_columns_data',
      )
    }

    return
  }, [data, isSuccess])

  return partsCustomColumnsData
}

export const getPartsCustomColumnsDataQuery = async (
  documentPageId?: string,
) => {
  if (!documentPageId) return

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return
  return templateAttributes.find(
    (templateAttribute) => templateAttribute.data_type === 'parts_columns_data',
  ) as TemplateAttribute<CustomColumnData> | undefined
}

export const useCreatePartsCustomRow = (props?: { onSuccess?: () => void }) => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_CUSTOM_ROW],
    mutationFn: ({
      documentPageId,
      customRow,
    }: {
      documentPageId: string
      customRow: CustomRow
    }) => {
      return createTemplateAttribute({
        documentPageId,
        payload: {
          data_type: 'part_custom_row',
          template_values: customRow as any,
        },
      })
    },
    onSuccess: props?.onSuccess,
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const createPartsCustomRowMutation = () => {
  const createTemplateAttribute = createTemplateAttributeMutation()
  return queryClient.getMutationCache().build(queryClient, {
    mutationKey: [MUTATION_KEYS.CREATE_CUSTOM_ROW],
    mutationFn: ({
      documentPageId,
      customRow,
    }: {
      documentPageId: string
      customRow: CustomRow
    }) => {
      return createTemplateAttribute.execute({
        documentPageId,
        payload: {
          data_type: 'part_custom_row',
          template_values: customRow as any,
        },
      })
    },
  })
}

export const useUpdatePartsCustomRow = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_CUSTOM_ROW],
    mutationFn: async ({
      templateAttributeId,
      customRow,
    }: {
      templateAttributeId: string
      customRow: CustomRow
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'part_custom_row',
            template_values: customRow as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeletePartsCustomRow = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_CUSTOM_ROW],
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListPartsCustomRows = (documentPageId?: string | null) => {
  const { data, isSuccess, isPending } = useListTemplateAttributes({
    documentPageId,
  })
  const customRows: Array<TemplateAttribute<CustomRow>> = useMemo(() => {
    if (isSuccess && data) {
      return data
        .filter(
          (templateAttribute) =>
            templateAttribute.data_type === 'part_custom_row',
        )
        .sort((a, b) => {
          const dateA = new Date(a.created as string).getTime()
          const dateB = new Date(b.created as string).getTime()
          return dateA - dateB
        })
    }

    return []
  }, [data, isSuccess, isPending])

  return customRows
}

export const getPartsCustomRowQuery = async (
  documentPageId?: string | null,
) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  const customRows: Array<TemplateAttribute<CustomRow>> = templateAttributes
    .filter(
      (templateAttribute) => templateAttribute.data_type === 'part_custom_row',
    )
    .sort((a, b) => {
      const dateA = new Date(a.created as string).getTime()
      const dateB = new Date(b.created as string).getTime()
      return dateA - dateB
    })

  return customRows
}

export const useCreateToolsColumn = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      column,
    }: {
      documentPageId: string
      column: ToolsColumn
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'tools_columns',
            template_values: [column] as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateToolsColumns = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      templateAttributeId,
      columns,
    }: {
      templateAttributeId: string
      columns: Array<ToolsColumn>
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'tools_columns',
            template_values: columns as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteToolsColumns = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_TOOLS_COLUMNS],
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListToolsColumns = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  const toolsColumns: TemplateAttribute<ToolsColumn> | undefined =
    useMemo(() => {
      if (isSuccess && data) {
        return data.find(
          (templateAttribute) =>
            templateAttribute.data_type === 'tools_columns',
        )
      }

      return
    }, [data, isSuccess])

  return toolsColumns
}

export const getToolsColumnsQuery = async (documentPageId?: string) => {
  if (!documentPageId) return

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return
  return templateAttributes.find(
    (templateAttribute) => templateAttribute.data_type === 'tools_columns',
  ) as TemplateAttribute<ToolsColumn> | undefined
}

export const useCreateToolsRow = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_TOOLS_ROW],
    mutationFn: ({
      documentPageId,
      toolsRow,
    }: {
      documentPageId: string
      toolsRow: ToolsRow
    }) => {
      return createTemplateAttribute({
        documentPageId,
        payload: {
          data_type: 'tools_columns_data',
          template_values: toolsRow as any,
        },
      })
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateToolsRow = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_TOOLS_ROW],
    mutationFn: async ({
      templateAttributeId,
      toolsRow,
    }: {
      templateAttributeId: string
      toolsRow: ToolsRow
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'tools_columns_data',
            template_values: toolsRow as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteToolsRow = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_TOOLS_ROW],
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListToolsRows = (documentPageId?: string | null) => {
  const { data, isSuccess, isPending } = useListTemplateAttributes({
    documentPageId,
  })
  const toolsRows: Array<TemplateAttribute<ToolsRow>> = useMemo(() => {
    if (isSuccess && data) {
      return data
        .filter(
          (templateAttribute) =>
            templateAttribute.data_type === 'tools_columns_data',
        )
        .sort((a, b) => {
          const dateA = new Date(a.created as string).getTime()
          const dateB = new Date(b.created as string).getTime()
          return dateA - dateB
        })
    }

    return []
  }, [data, isSuccess, isPending])

  return toolsRows
}

export const getToolsRowsQuery = async (documentPageId?: string | null) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  const toolsRows: Array<TemplateAttribute<ToolsRow>> = templateAttributes
    .filter(
      (templateAttribute) =>
        templateAttribute.data_type === 'tools_columns_data',
    )
    .sort((a, b) => {
      const dateA = new Date(a.created as string).getTime()
      const dateB = new Date(b.created as string).getTime()
      return dateA - dateB
    })

  return toolsRows
}

export const useCreateElementPosition = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: ({
      documentPageId,
      payload,
    }: {
      documentPageId: string
      payload: PositionPayload
    }) => {
      return createTemplateAttribute({
        documentPageId,
        payload: {
          data_type: 'position',
          template_values: payload as any,
        },
      })
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateElementPosition = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_TOOLS_ROW],
    mutationFn: async ({
      templateAttributeId,
      payload,
    }: {
      templateAttributeId: string
      payload: PositionPayload
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'position',
            template_values: payload as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListElementPositions = (documentPageId?: string | null) => {
  const { data, isSuccess, isPending } = useListTemplateAttributes({
    documentPageId,
  })
  const positions = useMemo(() => {
    if (isSuccess && data) {
      return data.filter(
        (templateAttribute) => templateAttribute.data_type === 'position',
      ) as Array<TemplateAttribute<PositionPayload>>
    }

    return []
  }, [data, isSuccess, isPending])

  return positions
}

export const getElementPositionsQuery = async (documentPageId?: string) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  const positions = templateAttributes.filter(
    (templateAttribute) => templateAttribute.data_type === 'position',
  ) as Array<TemplateAttribute<PositionPayload>>

  return positions
}

export const usePartsTablePosition = (documentPageId?: string | null) => {
  const elementPositions = useListElementPositions(documentPageId)
  const position: TemplateAttribute<PositionPayload> | undefined =
    useMemo(() => {
      if (elementPositions && elementPositions.length) {
        return elementPositions.find(
          (p) => p.template_values.type === 'parts-table',
        ) as TemplateAttribute<PositionPayload>
      }

      return
    }, [elementPositions])

  return position
}

export const useToolsTablePosition = (documentPageId?: string | null) => {
  const elementPositions = useListElementPositions(documentPageId)
  const position: TemplateAttribute<PositionPayload> | undefined =
    useMemo(() => {
      if (elementPositions && elementPositions.length) {
        return elementPositions.find(
          (p) => p.template_values.type === 'tools-table',
        ) as TemplateAttribute<PositionPayload>
      }

      return
    }, [elementPositions])

  return position
}

export const useViewsPositions = (documentPageId?: string | null) => {
  const elementPositions = useListElementPositions(documentPageId)
  const positions: Array<TemplateAttribute<PositionPayload>> = useMemo(() => {
    if (elementPositions && elementPositions.length) {
      return elementPositions.filter(
        (p) => p.template_values.type === 'view',
      ) as Array<TemplateAttribute<PositionPayload>>
    }

    return []
  }, [elementPositions])

  return positions
}

export const useImagesPositions = (documentPageId?: string | null) => {
  const elementPositions = useListElementPositions(documentPageId)
  const positions: Array<TemplateAttribute<PositionPayload>> = useMemo(() => {
    if (elementPositions && elementPositions.length) {
      return elementPositions.filter(
        (p) => p.template_values.type === 'image',
      ) as Array<TemplateAttribute<PositionPayload>>
    }

    return []
  }, [elementPositions])

  return positions
}

export const useNotesPositions = (documentPageId?: string | null) => {
  const elementPositions = useListElementPositions(documentPageId)
  const positions: Array<TemplateAttribute<PositionPayload>> = useMemo(() => {
    if (elementPositions && elementPositions.length) {
      return elementPositions.filter(
        (p) => p.template_values.type === 'note',
      ) as Array<TemplateAttribute<PositionPayload>>
    }

    return []
  }, [elementPositions])

  return positions
}

export const useImagePlaceholderPositions = (
  documentPageId?: string | null,
) => {
  const elementPositions = useListElementPositions(documentPageId)
  const positions: Array<TemplateAttribute<PositionPayload>> = useMemo(() => {
    if (elementPositions && elementPositions.length) {
      return elementPositions.filter(
        (p) => p.template_values.type === 'image-placeholder',
      ) as Array<TemplateAttribute<PositionPayload>>
    }

    return []
  }, [elementPositions])

  return positions
}
