import type { CommandOption } from './types'

const command: CommandOption = {
  name: '',
  path: '/',
  getItems: async () => {
    return [{ key: 'parts', value: 'parts' }]
  },
  onSelect: ({ items, editor, range }) => {
    if (items.length > 0) {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertContent(`/${items[0].value}:`)
        .run()
    }
  },
}

export default command
