import { useSearch } from 'wouter'
import queryString from 'query-string'
import { z } from 'zod'

import { useCreateDocumentVersion } from '@/services/queries/document_versions'
import { CreateDocumentVersionFormFields } from './CreateDocumentVersionFormFields'

const formSchema = z.object({
  comment: z.string(),
})

interface CreateDocumentVersionFormProps {
  projectId: string
  documentId: string
}

export const CreateDocumentVersionForm = ({
  projectId,
  documentId,
}: CreateDocumentVersionFormProps) => {
  const search = useSearch()
  const queryParams = queryString.parse(search) as {
    cv?: string // cad version
    dv?: string // document version
  }

  const createDocVersionService = useCreateDocumentVersion({
    onSuccess(documentVersion) {
      const newQueryParams = queryString.stringify({
        ...queryParams,
        dv: documentVersion?.id || '',
      })

      window.location.href = `/p/${projectId}/document/${documentVersion?.document}/edit?${newQueryParams}`
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    createDocVersionService.mutate({ documentId, comment: values.comment })
  }

  return (
    <CreateDocumentVersionFormFields
      isSubbmitting={createDocVersionService.isPending}
      onSubmit={onSubmit}
    />
  )
}
