import { useLocation } from 'wouter'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'

import { useDocument } from '@/services/queries/documents'
import { useCADQuery } from '@/services/queries/cads'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'

import { Assembly } from './Assembly'
import { StaticAssembly } from './StaticAssembly'
import { UpdateCadVersionButton } from './UpdateCadVersionButton'
import { ProjectDocumentTitle } from '@/components/core/ProjectDocumentTitle'

export const SidePanel = () => {
  const { projectId, documentId, cv: cadVersionParam } = useCadPageParams()
  const { isLoading: isDocumentLoading, data: documentData } = useDocument({
    documentId,
  })

  const [, setLocation] = useLocation()

  const { isLoading, data } = useCADQuery()

  const cadVersionId = data?.version?.id as string
  const { isLoading: isLoadingAssembly } = useAssemblyTreeQuery({
    projectId,
    cadVersionId,
    documentId,
  })

  if (data.hasNoDownloadUrl) {
    return null
  }

  if (isDocumentLoading || isLoading || isLoadingAssembly) {
    return (
      <div className="p-4">
        <Skeleton className="w-full h-6 bg-gray-200" />
        <div className="mt-8 flex flex-col space-y-2">
          {Array.from({ length: 6 }).map((_, i) => (
            <Skeleton key={i} className="w-full h-6 bg-gray-200" />
          ))}
        </div>
      </div>
    )
  }

  const documentType = documentData?.document_type
  const cadVersions = data?.cadVersions || []
  const cadVersion = data?.version

  const defaultVersion =
    cadVersionParam || (cadVersion?.id ? `${cadVersion.id}` : undefined)

  return (
    <div className="flex flex-col justify-evenly h-full">
      <div className="my-2 px-8">
        <ProjectDocumentTitle />
      </div>
      {documentType === 'project_tracker' && <StaticAssembly />}
      {documentType === 'work_instructions' && <Assembly />}
      <div
        className="flex flex-col items-center space-y-2 py-7"
        style={{ backgroundColor: '#DEE6FA' }}
      >
        <Select
          defaultValue={defaultVersion}
          onValueChange={(selectedVersion) => {
            setLocation(
              `/p/${projectId}/document/${data.documentVersion?.document}/cad/?cv=${selectedVersion}`,
            )
          }}
        >
          <SelectTrigger
            className="w-full h-10 rounded-full"
            style={{ maxWidth: '220px' }}
          >
            <SelectValue placeholder="Version" />
          </SelectTrigger>
          <SelectContent>
            {cadVersions.map((version) => (
              <SelectItem key={version.id} value={`${version.id}`}>
                V{version.version_number}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <UpdateCadVersionButton />
      </div>
    </div>
  )
}
