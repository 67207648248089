import { Page, Image, Document, PDFViewer, View } from '@react-pdf/renderer'
import { usePDFifyStore } from './state'

export const DocumentPDFPreview = () => {
  return (
    <>
      <Loading />
      <Preview />
    </>
  )
}

export const Loading = () => {
  const isGenerating = usePDFifyStore((state) => state.isGenerating)

  if (isGenerating) {
    return <div>Loading...</div>
  }

  return null
}

export const PDFDocument = ({ imageUrls }: { imageUrls: string[] }) => {
  return (
    <Document>
      {imageUrls.map((url, i) => (
        <Page
          key={`page-${i}`}
          size="A4"
          orientation="landscape"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <View style={{ width: '100%', height: '100%', padding: 5 }}>
            <Image
              src={url}
              style={{
                objectFit: 'fill',
              }}
            />
          </View>
        </Page>
      ))}
    </Document>
  )
}

export const Preview = () => {
  const imageUrls = usePDFifyStore((state) => state.imageUrls)

  return (
    <PDFViewer className="h-full w-full">
      <PDFDocument imageUrls={imageUrls} />
    </PDFViewer>
  )
}
