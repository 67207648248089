import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { Editor as TipTapEditor } from '@tiptap/react'
import { ResizableBox } from 'react-resizable'
import debounce from 'lodash.debounce'

import { cn } from '@/utils'
import { Editor } from '@/components/editor/Editor'
import { Spinner } from '@/components/ui/spinner'
import { useUpdateEditorNotes } from './hooks/useUpdateEditorNotes'
import { useDocumentState } from '@/state/document'
import {
  useCreateElementPosition,
  useDeleteNotes,
  useListNotes,
  useNotesPositions,
  useUpdateElementPosition,
} from '@/services/hooks/template_attributes'

import 'react-resizable/css/styles.css'

interface OperationNotesEditorProps {
  documentId: string
  documentPageId: string
  assemblyGroupId: string
  projectId: string
  viewOrImageId: string
  isAddingAnnotation: boolean
  isReadOnly: boolean
  type: string
  draggableId: string
  imageId: string
  positionData?: { x: number; y: number }
  size?: { width: number; height: number }
}

export const OperationNotesEditor = ({
  documentId,
  documentPageId,
  assemblyGroupId,
  projectId,
  viewOrImageId,
  isAddingAnnotation,
  isReadOnly,
  type,
  draggableId,
  imageId,
  positionData,
  size,
}: OperationNotesEditorProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const containerRef = useRef<HTMLDivElement>(null)
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const { mutateAsync: updateNotesPosition, isPending: isUpdatingPosition } =
    useUpdateElementPosition()
  const { mutateAsync: createNotesPosition, isPending: isCreatingPosition } =
    useCreateElementPosition()
  const { mutateAsync: removeNotes, isPending: isRemovingNotes } =
    useDeleteNotes()
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === draggableId,
    [selectedElement, documentPageId, draggableId],
  )

  const notesTemplates = useListNotes(documentPageId)
  const notesTemplate = useMemo(
    () =>
      notesTemplates?.find(
        (template) => template.template_values.viewId === imageId,
      ),
    [notesTemplates, imageId],
  )

  const pageNotesPositions = useNotesPositions(documentPageId)
  const notesPositionTemplate = useMemo(
    () =>
      pageNotesPositions?.find(
        (position) =>
          position.template_values?.movableElementId === draggableId,
      ),
    [pageNotesPositions, draggableId],
  )

  const updateTemplateHandler = useCallback(
    async (payload: any) => {
      if (notesPositionTemplate) {
        await updateNotesPosition({
          templateAttributeId: notesPositionTemplate.id as string,
          payload,
        })
      } else {
        await createNotesPosition({
          documentPageId,
          payload,
        })
      }
    },
    [
      notesPositionTemplate,
      documentPageId,
      updateNotesPosition,
      createNotesPosition,
    ],
  )

  const deleteNotesHandler = useCallback(async () => {
    const payload = {
      ...(notesPositionTemplate?.template_values || {}),
      movableElementId: draggableId,
      removed: true,
      type,
    }

    if (payload.position) {
      delete payload.position
    }

    if (payload.size) {
      delete payload.size
    }

    await updateTemplateHandler(payload)

    if (notesTemplate) {
      removeNotes(notesTemplate.id as string)
    }
  }, [
    draggableId,
    type,
    notesPositionTemplate,
    updateTemplateHandler,
    removeNotes,
    notesTemplate,
  ])

  const updateNotesSizeHandler = useCallback(
    async (size: { width: number; height: number }) => {
      const payload = {
        ...(notesPositionTemplate?.template_values || {}),
        movableElementId: draggableId,
        size,
        type,
      }

      await updateTemplateHandler(payload)
    },
    [draggableId, type, notesPositionTemplate, updateTemplateHandler],
  )

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteNotesHandler()
      }
    },
    [deleteNotesHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    let resizeObserver: ResizeObserver
    if (containerRef.current && !size) {
      const rect = containerRef.current.getBoundingClientRect()
      setContainerDimensions({
        width: rect.width,
        height: rect.height,
      })

      resizeObserver = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect
        setContainerDimensions({
          width,
          height,
        })
      })

      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [containerRef, size])

  useEffect(() => {
    if (size) {
      setContainerDimensions(size)
    }
  }, [size])

  const [{ position, delta }, setPosition] = useState({
    position: positionData,
    delta: INITIAL_DELTA,
  })
  const { mutate: updateNotes, isPending: isUpdatingNotes } =
    useUpdateEditorNotes()
  const containerId = useMemo(
    () => `${documentPageId}-${draggableId}`,
    [documentPageId, draggableId],
  )

  const { attributes, isDragging, listeners, transform, setNodeRef } =
    useDraggable({
      disabled: isAddingAnnotation || isReadOnly,
      id: draggableId,
      data: { type },
    })

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: transform?.x || 0,
        y: transform?.y || 0,
      },
    }))
  }, [transform])

  useEffect(() => {
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  const saveNotes = useCallback(
    debounce(({ editor }: { editor: TipTapEditor }) => {
      const html = editor.getHTML()

      const hasChanged = notesTemplate?.template_values?.content !== html
      const isSlashCommandsActive =
        editor.extensionStorage?.slashCommand?.isActive
      const shouldUpdate =
        hasChanged && !isSlashCommandsActive && !isUpdatingNotes

      if (shouldUpdate) {
        updateNotes({
          documentPageId,
          templateAttributeId: notesTemplate?.id,
          note: {
            content: editor.getHTML(),
            viewId: viewOrImageId,
          },
        })
      }
    }, 500),
    [
      notesTemplate?.template_values?.content,
      documentPageId,
      updateNotes,
      viewOrImageId,
      isUpdatingNotes,
      notesTemplate?.id,
    ],
  )

  const mutatedListeners = useMemo(() => {
    return {
      ...listeners,
      onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => {
        const targetId = (e.target as Element)?.id
        if (targetId === containerId) {
          listeners?.onMouseDown(e)
        }
      },
      onTouchStart: (e: React.TouchEvent<Element>) => {
        const targetId = (e.target as Element)?.id
        if (targetId === containerId) {
          listeners?.onMouseDown(e)
        }
      },
    }
  }, [listeners, containerId])

  return (
    <div className="relative flex flex-1 mt-2 p-px" ref={containerRef}>
      <div
        id={containerId}
        ref={setNodeRef}
        className={cn('border flex rounded-md box-content absolute', {
          'cursor-move': !isReadOnly,
          'cursor-default': isReadOnly,
          'rounded-md': isSelected,
          'shadow-md': isDragging,
          'shadow-gray-200': isDragging,
          'bg-sky-200/10': isDragging,
          'pointer-events-none': isAddingAnnotation,
          'pointer-events-auto': !isAddingAnnotation,
          'z-[1]': !isDragging,
          'z-[100]': isDragging,
        })}
        {...attributes}
        {...mutatedListeners}
        style={{
          translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
          top: position?.y || 0,
          left: position?.x || 0,
          borderColor: isSelected ? '#818cf8' : 'transparent',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <ResizableBox
          width={containerDimensions.width}
          height={containerDimensions.height}
          minConstraints={[150, 45]}
          maxConstraints={[740, 420]}
          onResizeStop={(_event, data) => updateNotesSizeHandler(data.size)}
          resizeHandles={['se']}
        >
          <>
            {(isUpdatingPosition || isCreatingPosition || isRemovingNotes) && (
              <div className="absolute h-full w-full bg-zinc-400/60 z-10">
                <Spinner />
              </div>
            )}
            <Editor
              projectId={projectId}
              documentId={documentId}
              documentPageId={documentPageId}
              assemblyGroupId={assemblyGroupId}
              className={cn('flex-1', {
                'pointer-events-none': isAddingAnnotation || isDragging,
              })}
              isAddingAnnotation={isAddingAnnotation}
              onBlur={saveNotes}
              onClick={() => {
                setSelectedElement({
                  id: draggableId,
                  documentPageId,
                  isRemovable: false,
                })
              }}
              onUpdate={saveNotes}
              content={notesTemplate?.template_values?.content ?? ''}
              disabled={isReadOnly}
              placeholder="Add notes"
              isDragging={isDragging}
            />
          </>
        </ResizableBox>
      </div>
    </div>
  )
}
