import { cn } from '@/utils'

export const EyeOpenIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M1.69944 7.25602C3.03205 8.5828 5.35608 10.4219 8.00016 10.4219C10.6442 10.4219 12.9678 8.5828 14.3004 7.25602C14.6519 6.90611 14.8282 6.73055 14.9401 6.387C15.02 6.14187 15.02 5.70196 14.9401 5.45683C14.8282 5.11326 14.6519 4.93768 14.3004 4.58773C12.9678 3.26095 10.6442 1.42188 8.00016 1.42188C5.35608 1.42188 3.03205 3.26095 1.69944 4.58773C1.34771 4.93792 1.17184 5.11314 1.05988 5.45683C0.980039 5.70196 0.980039 6.14187 1.05988 6.387C1.17184 6.73069 1.34771 6.90583 1.69944 7.25602Z"
        stroke="#3054EF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50224 5.92191C6.50224 6.75034 7.17282 7.42192 8.00002 7.42192C8.82723 7.42192 9.49781 6.75034 9.49781 5.92191C9.49781 5.09347 8.82723 4.4219 8.00002 4.4219C7.17282 4.4219 6.50224 5.09347 6.50224 5.92191Z"
        stroke="#3054EF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
