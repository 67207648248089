import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '@/components/ui/context-menu'
import { useToast } from '@/components/ui/use-toast'

export type AssemblyMenuButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  selectedCount: number
  isGroup?: boolean
  hasDocument: boolean
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined
  onMouseDown?: React.MouseEventHandler<HTMLSpanElement> | undefined
  onMouseUp?: React.MouseEventHandler<HTMLSpanElement> | undefined
  onOpenChange?: (open: boolean) => void
  onContextMenu?: React.MouseEventHandler<HTMLSpanElement> | undefined
  onGroup?: () => void
  onUngroup?: () => void
  onRename?: () => void
}
export const AssemblyMenuButton = ({
  children,
  disabled,
  selectedCount,
  isGroup,
  hasDocument,
  onClick,
  onMouseDown,
  onMouseUp,
  onOpenChange,
  onContextMenu,
  onGroup,
  onUngroup,
  onRename,
}: AssemblyMenuButtonProps) => {
  const { toast } = useToast()

  return (
    <>
      <ContextMenu onOpenChange={onOpenChange}>
        <ContextMenuTrigger
          disabled={disabled}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onContextMenu={onContextMenu}
        >
          {children}
        </ContextMenuTrigger>
        <ContextMenuContent>
          <ContextMenuItem
            visiable={selectedCount > 1}
            onClick={() => {
              if (hasDocument) {
                toast({
                  variant: 'default',
                  title: 'Operation document deleted',
                  description:
                    'Operation document and linked images have been deleted',
                })
              }
              onGroup && onGroup()
            }}
          >
            Group
          </ContextMenuItem>
          <ContextMenuItem
            visiable={selectedCount > 1 || (selectedCount === 1 && isGroup)}
            onClick={() => {
              if (hasDocument) {
                toast({
                  variant: 'default',
                  title: 'Operation document deleted',
                  description:
                    'Operation document and linked images have been deleted',
                })
              }
              onUngroup && onUngroup()
            }}
          >
            Ungroup
          </ContextMenuItem>
          <ContextMenuItem
            visiable={selectedCount === 1}
            onClick={() => {
              if (typeof onRename === 'function') {
                onRename()
              }
            }}
          >
            Rename
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
    </>
  )
}
