import { Spinner } from '@/components/ui/spinner'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

import { WorkInstructions } from './WorkInstructions'
import { ProjectTracker } from './ProjectTracker'

export const Documents = () => {
  const { isLoading, data } = useDocumentPageQuery()

  if (isLoading) {
    return <Spinner />
  }

  return data?.documentType === 'project_tracker' ? (
    <ProjectTracker />
  ) : (
    <WorkInstructions />
  )
}
