import { useEffect, useMemo, useState } from 'react'
import { useMutationState } from '@tanstack/react-query'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { BillOfMaterials, ToolsBillOfMaterials, useAssemblyTree } from '@/state'
import { DocumentTemplate } from './DocumentTemplate'
import { toChunks } from '@/utils'
import { MUTATION_KEYS as TEMPLATE_ATTRIBUTES_MUTATION_KEYS } from '@/services/queries/template_attributes'
import { MUTATION_KEYS as CAD_VERSIONS_MUTATION_KEYS } from '@/services/queries/cad_versions'

export const MAX_PARTS_PER_PAGE = 12

const CUSTOM_PARTS_MUTATION_KEYS = [
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.CREATE_CUSTOM_ROW,
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.UPDATE_CUSTOM_ROW,
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.DELETE_CUSTOM_ROW,
  CAD_VERSIONS_MUTATION_KEYS.UPDATE_CAD_VERSION,
]

const TOOLS_MUTATION_KEYS = [
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.CREATE_TOOLS_ROW,
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.UPDATE_TOOLS_ROW,
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.DELETE_TOOLS_ROW,
]
export const BillOfMaterialsDocument = ({
  versionNumber,
  lastUpdated,
}: {
  versionNumber: number
  lastUpdated: string
}) => {
  const partsBomPromise = useAssemblyTree((state) => state.getBillOfMaterials())
  const toolsBomPromise = useAssemblyTree((state) =>
    state.getToolsBillOfMaterials(),
  )
  const [partsBom, setPartsBom] = useState<BillOfMaterials>([])
  const [toolsBom, setToolsBom] = useState<ToolsBillOfMaterials>([])
  const partsMutationData = useMutationState({
    filters: {
      status: 'success',
      predicate: (mutation) => {
        const [mutationKey] = mutation.options.mutationKey || []

        if (!mutationKey) return false

        return CUSTOM_PARTS_MUTATION_KEYS.includes(mutationKey as any)
      },
    },
  })

  const toolsMutationData = useMutationState({
    filters: {
      status: 'success',
      predicate: (mutation) => {
        const [mutationKey] = mutation.options.mutationKey || []

        if (!mutationKey) return false

        return TOOLS_MUTATION_KEYS.includes(mutationKey as any)
      },
    },
  })

  useEffect(() => {
    partsBomPromise.then(setPartsBom)
  }, [partsMutationData])

  useEffect(() => {
    toolsBomPromise.then(setToolsBom)
  }, [toolsMutationData])

  const partsBomChunks: Array<BillOfMaterials> = useMemo(
    () => toChunks(partsBom, MAX_PARTS_PER_PAGE),
    [partsBom],
  )

  const toolsBomChunks: Array<ToolsBillOfMaterials> = useMemo(
    () => toChunks(toolsBom, MAX_PARTS_PER_PAGE),
    [toolsBom],
  )

  return (
    <>
      {partsBomChunks.map((chunk, i) => (
        <DocumentTemplate
          id={`parts-bom--${i}`}
          title="Bill of Materials"
          lastUpdated={lastUpdated}
          versionNumber={versionNumber}
          key={i}
          order={3 + i}
        >
          <div id="bom">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Part name</TableHead>
                  <TableHead>Quantity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {chunk.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell className="py-1 px-2.5">
                      {item.partName}
                    </TableCell>
                    <TableCell className="py-1 px-2.5">
                      {item.quantity ? item.quantity : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DocumentTemplate>
      ))}
      {toolsBomChunks.map((chunk, i) => (
        <DocumentTemplate
          id={`tools-bom--${i}`}
          title="Tools Bill of Materials"
          lastUpdated={lastUpdated}
          versionNumber={versionNumber}
          key={i}
          order={4 + partsBomChunks.length + i}
        >
          <div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Tool name</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {chunk.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell className="py-1 px-2.5">
                      {item.toolName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DocumentTemplate>
      ))}
    </>
  )
}
