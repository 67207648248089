import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import {
  INFRASTRUCTURE_ENVIRONMENT_TYPE,
  SENTRY_DSN,
  FRONTEND_REVISION,
} from './constants'
import { App } from './App'
import './index.css'

if (SENTRY_DSN && INFRASTRUCTURE_ENVIRONMENT_TYPE === 'prod') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [],

    environment: INFRASTRUCTURE_ENVIRONMENT_TYPE,
    release: FRONTEND_REVISION,

    maxBreadcrumbs: 20,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/buildquarter20\.com\/api/,
    ],

    // Capture Replay for 0% of all sessions,
    // plus for 0% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
