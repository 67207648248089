import { useState, useEffect, useRef } from 'react'
import { useRoute } from 'wouter'
import { usePDF } from '@react-pdf/renderer'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { VerticalDotsIcon } from '@/components/icons/VerticalDotsIcon'
import { Spinner } from '@/components/ui/spinner'
import { usePDFifyStore } from '@/components/pdf/state'
import { PDFDocument } from '@/components/pdf/document'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const ShareActionsDropdown = () => {
  const [matchDocumentPage] = useRoute(
    '/p/:projectId/document/:documentId/edit',
  )
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const shouldRenderActionsDropdown = matchDocumentPage

  if (!shouldRenderActionsDropdown) {
    return null
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger className="h-9 px-4 py-2 rounded-md hover:bg-primary-30">
          <VerticalDotsIcon className="w-4 h-4 stroke-white" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-2">
          <DropdownMenuItem>
            <DialogTrigger asChild>
              <div className="flex items space-x-2">
                <ArrowDownTrayIcon className="w-4 h-4" />
                <span>Download</span>
              </div>
            </DialogTrigger>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Dialogs */}
      {isDialogOpen && (
        <DownloadPDFDialogContent onDownload={() => setIsDialogOpen(false)} />
      )}
    </Dialog>
  )
}

const DownloadPDFDialogContent = ({
  onDownload,
}: {
  onDownload: () => void
}) => {
  const { isLoading: isDocumentLoading, data: documentData } =
    useDocumentPageQuery()
  const linkRef = useRef<HTMLAnchorElement>(null)
  const [downloaded, setDownloaded] = useState(false)
  const [instance, updateInstance] = usePDF()
  const imageUrls = usePDFifyStore((state) => state.imageUrls)
  const generatePDF = usePDFifyStore((state) => state.generatePDF)

  useEffect(() => {
    const pages = documentData?.documentPages
    if (!isDocumentLoading && pages) {
      const pageCount = pages.length
      generatePDF(pageCount)
    }
  }, [documentData?.documentPages, generatePDF, isDocumentLoading])

  useEffect(() => {
    if (!isDocumentLoading && !downloaded && imageUrls.length > 0) {
      updateInstance(<PDFDocument imageUrls={imageUrls} />)
      setDownloaded(true)
      setTimeout(() => {
        linkRef.current?.click()
        onDownload()
      }, 1000)
    }
  }, [imageUrls, downloaded, updateInstance, onDownload, isDocumentLoading])

  const projectName = documentData?.project?.name || 'project'
  const versionNumber = documentData?.version?.version_number || 1

  return (
    <DialogContent className="sm:max-w-[425px] min-h-16">
      <DialogHeader>
        <DialogTitle>PDF Download</DialogTitle>
        <DialogDescription>
          Your pdf will be downloaded shortly
        </DialogDescription>
      </DialogHeader>
      <div>
        <Spinner />
        <a
          ref={linkRef}
          href={instance.url || ''}
          download={`${projectName}_v${versionNumber}.pdf`}
          onClick={() => {
            // @ts-expect-error - IE download
            if (window.navigator.msSaveBlob) {
              // @ts-expect-error - IE download
              window.navigator.msSaveBlob(instance.blob, fileName)
            }
          }}
        ></a>
      </div>
    </DialogContent>
  )
}
