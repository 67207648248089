/**
 * Application URLs
 */
export const BASE_URL = __API_URL__ ?? 'http://localhost:8000'
export const BASE_API_URL = `${BASE_URL}/api`
export const MAX_VIEWS_IN_DOCUMENT = 1

export const SENTRY_DSN = __SENTRY_DSN__ ?? ''
export const FRONTEND_REVISION = __FRONTEND_REVISION__ ?? ''
export const INFRASTRUCTURE_ENVIRONMENT_TYPE =
  __INFRASTRUCTURE_ENVIRONMENT_TYPE__ ?? undefined
export const CSRF_TOKEN_NAME = __CSRF_TOKEN_NAME__ ?? 'csrftoken'

export const WAND_COLOR = 0xf5a50a
