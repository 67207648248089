import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query'

import {
  createProject,
  updateProject,
  deleteProject,
  listProjects,
  getProject,
  Project,
  ProjectListItem,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'

type mutationProjectProps = {
  onSuccess?: (project?: Project) => void
}

export const useCreateProject = (props?: mutationProjectProps) =>
  useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_PROJECT],
    mutationFn: async (payload: { name: string; files: Array<File> }) => {
      const project = await createProject(payload)
      return project
    },
    onSuccess: (project) => {
      queryClient.setQueryData(
        [QUERY_KEYS.PROJECTS],
        (projects: Array<Project> | undefined) => {
          projects ? [...projects, project] : [project]
        },
      )
      if (props?.onSuccess) props.onSuccess(project)
    },
  })

export const useUpdateProject = (props?: mutationProjectProps) =>
  useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_PROJECT],
    mutationFn: async ({
      projectId,
      values,
    }: {
      projectId: string
      values: { name: string }
    }) => {
      const project = await updateProject(projectId, values)
      return project
    },
    onSuccess: (project) => {
      queryClient.setQueryData(
        [QUERY_KEYS.PROJECTS],
        (projects: Array<Project> | undefined) => {
          if (projects)
            return projects.map((currentProject) =>
              currentProject.id === project?.id ? project : currentProject,
            )
        },
      )
      if (props?.onSuccess) props.onSuccess(project)
    },
  })

export const useDeleteProject = () =>
  useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_PROJECT],
    mutationFn: async (projectId: string) => {
      await deleteProject(projectId)
      return projectId
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECTS],
      })
    },
  })

export const useListProjects = ({
  limit = 5,
  offset = 0,
  disabled = false,
}: {
  limit?: number
  offset?: number
  disabled?: boolean
}) => {
  return useInfiniteQuery({
    enabled: !disabled,
    refetchOnMount: true,
    queryKey: [QUERY_KEYS.PROJECTS, limit, offset],
    queryFn: async ({ pageParam }) => {
      const resp = await listProjects({
        limit: pageParam.limit,
        offset: pageParam.offset,
      })
      return resp as {
        projects: Array<ProjectListItem>
        totalProjects: number
        limit: number
        offset: number
      }
    },
    getNextPageParam: (lastPage) => {
      return {
        limit: lastPage.limit,
        offset: (lastPage.offset || 0) + (lastPage.limit || 25),
      }
    },
    initialPageParam: { limit, offset },
  })
}

const getProjectQueryFn = async ({ projectId }: { projectId: string }) => {
  const project = await getProject(projectId)

  if (project) {
    queryClient.setQueryData(
      [QUERY_KEYS.PROJECTS],
      (projects: Array<Project> | undefined) => {
        if (projects) {
          const projectExists = !!projects.find(
            (currentProject) => currentProject.id === project?.id,
          )

          return projectExists
            ? projects.map((currentProject) =>
                currentProject.id === project?.id ? project : currentProject,
              )
            : [...projects, project]
        }
      },
    )
  }

  return project
}

export const getProjectQuery = ({ projectId }: { projectId: string }) =>
  queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.PROJECT, { projectId }],
    queryFn: () => getProjectQueryFn({ projectId }),
  })

export const useGetProject = ({
  projectId,
  disabled = false,
}: {
  projectId?: string | null
  disabled?: boolean
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PROJECT, { projectId }],
    queryFn: () => {
      if (!projectId) return
      return getProjectQueryFn({ projectId })
    },
    enabled: Boolean(projectId) && !disabled,
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
