import { AddPartForm } from '@/components/editor/components/AddPartForm'
import { createMark } from '@/components/editor/lib/createMark'
import { listTemplateAttributesQuery } from '@/services/queries/template_attributes'
import { createPartsCustomRowMutation } from '@/services/hooks/template_attributes'
import type { EditorAttributes } from '@/components/editor/lib/types'
import type { CommandOption } from './types'

const MAX_BOM_ROWS = 3

const command: CommandOption = {
  name: 'parts',
  path: '/parts:',
  mutltiSelect: false,
  getItems: async ({ documentId, assemblyGroupId, bom }) => {
    const items: { key: string; value: string }[] = []

    const templateAttributes = await listTemplateAttributesQuery({
      documentPageId: documentId,
    })

    bom.forEach((part) => {
      items.push({
        key: `${documentId}-${assemblyGroupId}-${part.partName}`,
        value: part.partName,
      })
    })

    templateAttributes
      .filter((attr) => attr.data_type === 'part_custom_row')
      .forEach((attr) => {
        const values = attr.template_values
        items.push({
          key: `${documentId}-${assemblyGroupId}-${values.partName}`,
          value: values.partName,
        })
      })

    return items
  },
  onSelect: async ({
    editor,
    range,
    items,
    query,
    bom,
    templateAttributes,
    toast,
  }) => {
    if (items.length === 0) {
      return
    }

    const editorAttributes = editor.options.editorProps
      .attributes as EditorAttributes
    const projectId = editorAttributes.projectId
    const documentPageId = editorAttributes.documentPageId
    const assemblyGroupId = editorAttributes.assemblyGroupId
    const documentId = editorAttributes.documentId

    const parts: string[] = []

    bom.forEach((part) => {
      parts.push(part.partName)
    })

    const customParts = templateAttributes.filter(
      (attr) => attr.data_type === 'part_custom_row',
    )

    customParts.forEach((attr) => {
      parts.push(attr.template_values.partName)
    })

    const partQuery = query.replace('parts:', '')

    const hasPart = parts.includes(partQuery)

    const filteredItems = items.filter((item) =>
      item.value.toLowerCase().includes(partQuery.toLowerCase()),
    )

    const createCustomRow = createPartsCustomRowMutation()

    const shouldAddNewPart =
      parts.length < MAX_BOM_ROWS &&
      !hasPart &&
      filteredItems.length === 0 &&
      partQuery.trim() !== ''

    if (shouldAddNewPart) {
      try {
        await createCustomRow.execute({
          documentPageId,
          customRow: {
            partName: partQuery,
            quantity: '',
          },
        })
        toast({
          variant: 'default',
          description: (
            <div>
              <div className="px-2 py-2 break-all">
                Added{' '}
                <span
                  style={{
                    padding: '2px 6px',
                    borderRadius: '5px',
                    backgroundColor: '#DAF2EF',
                    color: 'rgb(30, 30, 30)',
                  }}
                >
                  {partQuery}
                </span>{' '}
                to BOM
              </div>
            </div>
          ),
        })
      } catch (error) {
        console.error(error)
        toast({
          variant: 'destructive',
          title: `Failed to add part ${query} to BOM`,
        })
      }
    }

    const selectedParts = new Set<string>()

    if (shouldAddNewPart) {
      selectedParts.add(partQuery)
    } else {
      filteredItems.forEach((item) => {
        selectedParts.add(item.value)
      })
    }

    const content = Array.from(selectedParts)
      .map((item) =>
        createMark({
          projectId,
          documentId,
          text: item,
          hash: `part-${assemblyGroupId}-${item.replace(/\s/g, '-')}`,
        }),
      )
      .join(' ')

    editor.chain().focus().deleteRange(range).insertContent(content).run()
  },
  renderEmptyResults: ({ query, editor, range }) => (
    <AddPartForm query={query} editor={editor} range={range} />
  ),
}

export default command
