import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { SaveIcon } from '@/components/icons/SaveIcon'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { cn } from '@/utils'
import { CreateDocumentVersionForm } from './CreateDocumentVersionForm'

interface CreateDocumentVersionButtonProps {
  className?: string
  disabled?: boolean
}

export const CreateDocumentVersionButton = ({
  className,
  disabled,
}: CreateDocumentVersionButtonProps) => {
  const [open, setOpen] = useState(false)
  const documentPage = useDocumentPageQuery()
  const documentId = documentPage.data?.documentId
  const projectId = documentPage.data?.project.id || ''

  if (!documentId || documentPage.isLoading) {
    return null
  }

  return (
    <Dialog open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
      <DialogTrigger
        disabled={disabled}
        className={cn(className, {
          'cursor-default': disabled,
        })}
      >
        <SaveIcon
          className={cn('h-4 w-4', {
            'stroke-gray-400': disabled,
          })}
        />
      </DialogTrigger>
      <DialogContent className="min-h-56 max-w-xl gap-0 pb-0">
        <DialogHeader>
          <DialogTitle>Create New Document Version</DialogTitle>
        </DialogHeader>
        <CreateDocumentVersionForm
          documentId={documentId}
          projectId={projectId}
        />
      </DialogContent>
    </Dialog>
  )
}
