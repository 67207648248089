import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { Link } from 'wouter'

import type { DocumentPage } from '@/lib/api/client'
import { CameraPlusIcons } from '@/components/icons/CameraPlusIcon'
import { useDocumentState } from '@/state/document'
import { UploadPhotoButton } from './UploadPhotoButton'
import { cn } from '@/utils'
import {
  useCreateElementPosition,
  useImagePlaceholderPositions,
  useUpdateElementPosition,
} from '@/services/hooks/template_attributes'
import { Spinner } from '@/components/ui/spinner'

type ImagePlaceholderProps = {
  projectId: string
  documentId: string
  draggableId: string
  isAddingAnnotation: boolean
  documentPage: DocumentPage
  positionData?: {
    x: number
    y: number
  }
}

export const ImagePlaceholder = ({
  projectId,
  documentId,
  draggableId,
  isAddingAnnotation,
  documentPage,
  positionData,
}: ImagePlaceholderProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const documentPageId = useMemo(() => documentPage.id, [documentPage])
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === draggableId,
    [selectedElement, documentPageId, draggableId],
  )
  const [{ position, delta }, setPosition] = useState({
    position: positionData,
    delta: INITIAL_DELTA,
  })

  const imagePlaceholderPositions = useImagePlaceholderPositions(
    documentPage.id,
  )
  const placeholderPosition = imagePlaceholderPositions.find(
    (position) => position.template_values.movableElementId === draggableId,
  )

  const {
    mutateAsync: updatePlaceholderPosition,
    isPending: isUpdatingPosition,
  } = useUpdateElementPosition()
  const {
    mutateAsync: createPlaceholderPosition,
    isPending: isCreatingPosition,
  } = useCreateElementPosition()

  const isDeleting = useMemo(
    () => isUpdatingPosition || isCreatingPosition,
    [isUpdatingPosition, isCreatingPosition],
  )

  const { attributes, isDragging, listeners, transform, setNodeRef } =
    useDraggable({
      disabled: isAddingAnnotation,
      id: draggableId,
      data: { type: 'image-placeholder' },
    })

  const deletePlaceholderHandler = useCallback(async () => {
    if (placeholderPosition) {
      const cleanPayload = {
        ...placeholderPosition.template_values,
      }

      delete cleanPayload.position
      cleanPayload.removed = true
      await updatePlaceholderPosition({
        templateAttributeId: placeholderPosition.id as string,
        payload: cleanPayload,
      })
    } else {
      await createPlaceholderPosition({
        documentPageId: documentPage.id as string,
        payload: {
          movableElementId: draggableId,
          removed: true,
          type: 'image-placeholder',
        },
      })
    }
  }, [
    draggableId,
    documentPage.id,
    placeholderPosition,
    updatePlaceholderPosition,
    createPlaceholderPosition,
  ])

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deletePlaceholderHandler()
      }
    },
    [deletePlaceholderHandler],
  )

  useEffect(() => {
    if (isSelected) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected])

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: transform?.x || 0,
        y: transform?.y || 0,
      },
    }))
  }, [transform])

  useEffect(() => {
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={cn(
        'border box-content flex flex-1 flex-col justify-between space-y-2 cursor-move relative',
        {
          'shadow-md': isDragging,
          'shadow-gray-200': isDragging,
          'bg-sky-200/10': isDragging,
          'z-[1]': !isDragging,
          'z-[100]': isDragging,
        },
      )}
      style={{
        maxWidth: '50%',
        translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
        top: position?.y || 0,
        left: position?.x || 0,
        borderColor: isSelected ? '#818cf8' : 'transparent',
      }}
    >
      {isDeleting && (
        <div className="absolute h-full w-full bg-zinc-400/60 z-10">
          <Spinner />
        </div>
      )}
      <div
        className="border rounded bg-gray-100 flex flex-1 flex-col space-y-3 justify-center px-2"
        style={{
          minHeight: '50%',
        }}
      >
        <div
          className="flex flex-col items-center gap-2"
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Link
            href={`/p/${projectId}/document/${documentId}/cad${
              documentPage.order_number ? '#op' + documentPage.order_number : ''
            }`}
            className="bg-blue-100 rounded-full px-4 py-2 text-blue-700 flex items-center justify-center"
          >
            <CameraPlusIcons className="h-4 h-4 stroke-blue-700 mb-0.5" />
            <span className="text-sm">Capture from CAD View</span>
          </Link>
          <UploadPhotoButton documentPage={documentPage} />
        </div>
      </div>
    </div>
  )
}

export default ImagePlaceholder
