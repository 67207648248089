import { Editor, Range } from '@tiptap/react'
import { useState, useEffect, useImperativeHandle, ForwardedRef } from 'react'
import { cn } from '@/utils'
import { Button } from '@/components/ui/button'
import type { CommandOption } from '../SlashCommands/commands/types'

type CommandItem = {
  key: string
  value: string
}

interface SlashCommandListItemsProps {
  parentRef: ForwardedRef<any>
  items: CommandItem[]
  canMultiSelect: boolean
  query: string
  editor: Editor
  range: Range
  onSelect: (items: CommandItem[]) => void
  renderEmptyResults?: CommandOption['renderEmptyResults']
}

export const CommandSelector = ({
  parentRef,
  items,
  canMultiSelect,
  query,
  editor,
  range,
  onSelect,
  renderEmptyResults = () => <div>No result</div>,
}: SlashCommandListItemsProps) => {
  const [selectedItems, setSelectedItems] = useState<
    {
      key: string
      value: string
    }[]
  >([])

  useEffect(() => {
    const hasSelectedItem = selectedItems.some((selectedItem) => {
      return items.find((item) => item.key === selectedItem.key)
    })
    if (items.length > 0 && (selectedItems.length === 0 || !hasSelectedItem)) {
      setSelectedItems([items[0]])
    }
  }, [items])

  useImperativeHandle(parentRef, () => ({
    onBlur: () => {
      if (typeof onSelect === 'function') {
        onSelect(selectedItems)
      }
    },
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp' && selectedItems.length > 0) {
        const index = items.findIndex(
          (item) => item.key === selectedItems[0].key,
        )
        const nextIndex = (index + items.length - 1) % items.length
        setSelectedItems([items[nextIndex]])
        return true
      }

      if (event.key === 'ArrowDown' && selectedItems.length > 0) {
        const index = items.findIndex(
          (item) => item.key === selectedItems[0].key,
        )
        const nextIndex = (index + 1) % items.length
        setSelectedItems([items[nextIndex]])
        return true
      }

      if (
        event.key === 'Enter' ||
        event.key === 'Tab' ||
        event.key === 'Escape'
      ) {
        if (typeof onSelect === 'function') {
          onSelect(selectedItems)
        }
        return true
      }
      return false
    },
  }))

  const filteredItems = items.filter((item) =>
    item.value.toLowerCase().includes(query.toLowerCase()),
  )

  return (
    <div
      className="flex flex-col rounded-md min-w-[150px] px-2 py-1 bg-white text-sm"
      style={{ border: '1px solid #169081' }}
    >
      {filteredItems.length
        ? filteredItems.map((item, index) =>
            canMultiSelect ? (
              <div
                key={`${item}:${index}-select`}
                className="flex align-items space-x-2 select-none"
              >
                <input
                  type="checkbox"
                  id={item.value}
                  onChange={() => {
                    const isSelected =
                      selectedItems.filter((sitem) => sitem === item).length > 0
                    const nextSelectedItems = isSelected
                      ? selectedItems.filter((sitem) => sitem !== item)
                      : [...selectedItems, item]
                    setSelectedItems(nextSelectedItems)
                  }}
                  checked={selectedItems.includes(item)}
                />
                <label htmlFor={item.value}>{item.value}</label>
              </div>
            ) : (
              <button
                key={`${item}:${index}`}
                className={cn('text-left', {
                  'text-teal-600':
                    selectedItems.findIndex((s) => s.key === item.key) > -1,
                })}
                onClick={() => {
                  setSelectedItems([item])
                  if (typeof onSelect === 'function') {
                    onSelect([item])
                  }
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                {item.value}
              </button>
            ),
          )
        : renderEmptyResults({ query, editor, range })}
      {filteredItems.length > 0 && canMultiSelect && (
        <Button
          size="sm"
          onClick={() => {
            if (typeof onSelect === 'function') {
              onSelect(selectedItems)
            }
          }}
        >
          Done
        </Button>
      )}
    </div>
  )
}
