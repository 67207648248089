import queryClient from '@/queryClient'
import { components } from '@/lib/api/types'
import { useMutation } from '@tanstack/react-query'
import {
  useCreateTemplateAttribute,
  useUpdateTemplateAttribute,
  QUERY_KEYS as TEMPLATE_ATTRIBUTES_QUERY_KEYS,
} from '@/services/queries/template_attributes'

type NoteTemplateAttribute = components['schemas']['TemplateAttributeOut'] & {
  template_values?: {
    content: string
    editorVersion: number
    viewId: string
  }
}

type NotesTemplateValue = {
  content: string
  viewId: string
}

export const useUpdateEditorNotes = () => {
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()
  const mutation = useMutation({
    mutationFn: async ({
      documentPageId,
      templateAttributeId,
      note,
    }: {
      documentPageId: string
      templateAttributeId?: string | null
      note: NotesTemplateValue
    }): Promise<Partial<NoteTemplateAttribute>> => {
      if (documentPageId) {
        if (templateAttributeId) {
          const resp = await updateTemplateAttribute({
            templateAttributeId,
            payload: {
              data_type: 'note',
              template_values: { ...note, editorVersion: 2 } as any,
            },
          })
          return resp as NoteTemplateAttribute
        } else {
          const resp = await createTemplateAttribute({
            documentPageId,
            payload: {
              data_type: 'note',
              template_values: { ...note, editorVersion: 2 } as any,
            },
          })
          return resp as NoteTemplateAttribute
        }
      }
      return {}
    },
    onSuccess: (data) => {
      const documentPageId = data.document_page
      queryClient.invalidateQueries({
        queryKey: [
          TEMPLATE_ATTRIBUTES_QUERY_KEYS.TEMPLATE_ATTRIBUTES,
          { documentPageId },
        ],
        refetchType: 'all',
      })
    },
  })
  return mutation
}
