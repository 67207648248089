import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  createView,
  updateView,
  deleteView,
  listViews,
  listDocumentPages,
  listTemplateAttributes,
  getView,
  View,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import { ViewInput } from '@/lib/api/client/types'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'

type mutationViewProps = {
  onSuccess?: (view?: View) => void
}

/*
 ** Views in cache need to be updated after 1 minute
 ** of inactivity to update the download URL
 */
export const VIEWS_CACHE_TIME = 1 * 1000 * 60

export const useCreateView = (props?: mutationViewProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_VIEW],
    mutationFn: ({
      cadVersionId,
      values,
    }: {
      cadVersionId: string
      values: ViewInput
    }) => createView(cadVersionId, values),
    onSuccess: (view: View) => {
      const updatedView = {
        ...view,
      } as any
      delete updatedView.download_url
      const viewsUpdaterFn = (data: any) =>
        data ? [...data, updatedView] : [updatedView]
      queryClient.setQueriesData(
        {
          queryKey: [
            QUERY_KEYS.VIEWS,
            {
              cadVersionId: updatedView?.cad_version,
            },
          ],
        },
        viewsUpdaterFn,
      )
      queryClient.setQueryData([QUERY_KEYS.CAD_VIEWS], viewsUpdaterFn)
      queryClient.setQueriesData(
        {
          queryKey: [
            QUERY_KEYS.VIEW,
            {
              viewId: updatedView?.cad_version,
            },
          ],
        },
        (data: any) => ({
          ...data,
          ...updatedView,
        }),
      )
      if (props?.onSuccess) props.onSuccess(view)
    },
  })
}

export const useUpdateView = (props?: mutationViewProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_VIEW],
    mutationFn: ({ viewId, values }: { viewId: string; values: ViewInput }) =>
      updateView(viewId, values),
    onSuccess: (view: View) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.VIEWS],
      })
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CAD_VIEWS],
      })
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.VIEW],
      })
      if (props?.onSuccess) props.onSuccess(view)
    },
  })
}

export const useDeleteView = (props?: {
  onSuccess?: (viewId: string) => void
}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_VIEW],
    mutationFn: async ({ viewId }: { viewId: string }) => {
      await deleteView(viewId)
      return { viewId }
    },
    onSuccess: ({ viewId }) => {
      const queryKey = [QUERY_KEYS.VIEW, { viewId }]
      const viewsUpdaterFn = (data: any) =>
        data?.filter((view: any) => view.id !== viewId)
      queryClient.setQueriesData(
        {
          queryKey: [QUERY_KEYS.VIEWS],
        },
        viewsUpdaterFn,
      )
      queryClient.setQueriesData(
        { queryKey: [QUERY_KEYS.CAD_VIEWS] },
        viewsUpdaterFn,
      )

      queryClient.removeQueries({ queryKey })

      if (props?.onSuccess) props.onSuccess(viewId)
    },
  })
}

const listDocumentPageViews = async ({
  cadVersionId,
  documentVersionId,
}: {
  cadVersionId: string
  documentVersionId: string
}) => {
  const views = await listViews(cadVersionId)
  const pages = await listDocumentPages(documentVersionId)
  const pageViews: View[] = []
  for (const page of pages) {
    const attrs = await listTemplateAttributes(page.id as string)
    for (const attr of attrs) {
      if (attr.data_type === 'view') {
        const view = views.find(
          (view) => view.id === attr.template_values.viewId,
        )
        if (view) {
          pageViews.push(view)
        }
      }
    }
  }
  return pageViews
}

export const useListViews = ({
  cadVersionId,
  documentVersionId,
}: {
  cadVersionId?: string | null
  documentVersionId?: string | null
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
    queryFn: () => {
      return cadVersionId && documentVersionId
        ? listDocumentPageViews({ cadVersionId, documentVersionId })
        : []
    },
    enabled: Boolean(cadVersionId && documentVersionId),
    staleTime: VIEWS_CACHE_TIME,
  })
}

export const listViewsQuery = ({
  cadVersionId,
  documentVersionId,
}: {
  cadVersionId: string
  documentVersionId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
    queryFn: () => {
      return listDocumentPageViews({ cadVersionId, documentVersionId })
    },
    staleTime: VIEWS_CACHE_TIME,
  })
}

export const getViewQuery = ({ viewId }: { viewId: string }) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.VIEW, { viewId }],
    queryFn: () => getView(viewId),
  })
}

export const useGetView = ({ viewId }: { viewId?: string | null }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.VIEW, { viewId }],
    queryFn: () => getView(viewId as string),
    enabled: Boolean(viewId),
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
