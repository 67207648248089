import { cn } from '@/utils'

export const FileIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M7.28563 1.00058C7.2106 1 7.12635 1 7.03005 1H3.5144C2.63433 1 2.19397 1 1.85783 1.14532C1.56215 1.27316 1.32193 1.47698 1.17127 1.72786C1 2.01308 1 2.38673 1 3.13346V10.8668C1 11.6135 1 11.9867 1.17127 12.2719C1.32193 12.5228 1.56215 12.727 1.85783 12.8548C2.19365 13 2.63348 13 3.51185 13L9.48802 13C10.3664 13 10.8056 13 11.1414 12.8548C11.4371 12.727 11.6781 12.5228 11.8287 12.2719C11.9998 11.987 11.9998 11.6143 11.9998 10.8691V5.21712C11.9998 5.13531 11.9998 5.06374 11.9991 5M7.28563 1.00058C7.51005 1.00231 7.65203 1.00923 7.78757 1.03684C7.94791 1.0695 8.10107 1.12351 8.24167 1.19661C8.4002 1.27904 8.53632 1.39455 8.80793 1.625L11.2636 3.70866C11.5354 3.93926 11.6705 4.05424 11.7677 4.1888C11.8539 4.30809 11.9176 4.43817 11.9561 4.57422C11.9886 4.68917 11.997 4.80967 11.9991 5M7.28563 1.00058V2.86667C7.28563 3.6134 7.28563 3.98651 7.4569 4.27173C7.60756 4.52261 7.84778 4.72699 8.14346 4.85482C8.47927 5 8.9191 5 9.79745 5H11.9991"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
