import { Html } from '@react-three/drei'
import { useShallow } from 'zustand/react/shallow'
import { useCADPageStore, RenderMode } from '@/pages/CADPage/state'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

export const RenderControlsDropdown = () => {
  const containerWidth = 200

  const renderMode = useCADPageStore((s) => s.renderMode)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  return (
    <Html
      wrapperClass="transform-none w-full h-0 !z-20"
      className="right-0 top-0"
      style={{
        width: `${containerWidth}px`,
        top: 10,
        left: `calc(100% - ${containerWidth}x) !important`,
        transform: 'none !important',
      }}
    >
      <Select
        value={renderMode}
        onValueChange={(selectedVersion: RenderMode) => {
          setCadPageState({ renderMode: selectedVersion })
        }}
      >
        <SelectTrigger className="w-[150px] bg-gray-50 rounded-full px-5 focus:ring-0 text-sm font-medium shadow-none border-gray-200">
          <SelectValue placeholder="Select a rendering mode" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="full-color">Full color</SelectItem>
          <SelectItem value="outline">Outline</SelectItem>
        </SelectContent>
      </Select>
    </Html>
  )
}
