import { useEffect, useRef } from 'react'

import { useAssemblyTree, useDocumentState } from '@/state'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { usePDFifyStore } from '@/components/pdf/state'

import { OperationDocument } from '../../OperationDocument'
import { TitleDocument } from '../../TitleDocument'
import { BillOfMaterialsDocument } from '../../BillOfMaterialsDocument'
import { VersionHistoryDocument } from '../../VersionHistory'
import { Slider } from '@/components/ui/slider'
import { useListViews } from '@/services/queries/views'

export const WorkInstructions = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const { isLoading: isLoadingViews, data: viewsData } = useListViews({
    cadVersionId: data?.version?.id,
    documentVersionId: data?.documentVersion?.id as string,
  })
  const assemblyTree = useAssemblyTree((state) => state.tree)
  const isGeneratingPdf = usePDFifyStore((state) => state.isGenerating)
  const documentRef = useRef<HTMLDivElement>(null)
  const zoom = useDocumentState((state) => state.zoom)
  const setZoom = useDocumentState((state) => state.setZoom)

  useEffect(() => {
    if (documentRef.current) {
      // This is a hack to make sure the DOM repaints the container with the new zoom value
      const n = document.createTextNode('.')
      documentRef.current.appendChild(n)
      documentRef.current.style.visibility = 'hidden'
      setTimeout(() => {
        if (documentRef.current) {
          documentRef.current.removeChild(n)
          documentRef.current.style.visibility = 'visible'
        }
      })

      documentRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      })
    }
  }, [zoom, documentRef])

  if (isLoading || isLoadingViews) {
    return <div>Loading...</div>
  }

  if (
    !assemblyTree ||
    !data ||
    !data.project ||
    !data.version ||
    !data.documentPages
  ) {
    return (
      <div className="h-full overflow-scroll relative pt-14">
        <div className="flex flex-col items-center mt-20">
          <h1 className="text-lg font-semibold">No document found.</h1>
          <h2 className="text-gray-500">
            Capture a smart screenshot to get started.
          </h2>
        </div>
      </div>
    )
  }

  const versionNumber = data.documentVersion?.version_number || 1
  const lastUpdated =
    data.documentVersion?.modified ||
    data.documentVersion?.created ||
    new Date().toISOString()
  const isLatestDocumentVersion = data.isLatestDocumentVersion
  const documentViews = viewsData || []
  const documentName = data.doc?.name || 'Work Instructions'

  return (
    <div className="h-full overflow-scroll relative pt-14">
      <div
        ref={documentRef}
        className="space-y-4 grid justify-items-center px-4 pb-6 w-full"
        style={{
          transform: `scale(${zoom / 100})`,
          transformOrigin: '0 0',
        }}
      >
        <TitleDocument
          documentName={documentName}
          versionNumber={versionNumber}
          lastUpdated={lastUpdated}
        />
        <VersionHistoryDocument
          versionNumber={versionNumber}
          lastUpdated={lastUpdated}
        />
        <BillOfMaterialsDocument
          versionNumber={versionNumber}
          lastUpdated={lastUpdated}
        />
        {data.documentPages.map((page, i) => {
          const views = documentViews
            .filter((view) => view.assembly_group_id === page.assembly_group_id)
            .sort((viewA, viewB) => {
              return (
                (viewA.document_page_order || 0) -
                (viewB.document_page_order || 0)
              )
            })
          return (
            <OperationDocument
              key={page.id}
              documentPage={page}
              views={views}
              versionNumber={versionNumber}
              lastUpdated={lastUpdated}
              orderNumber={i + 1}
              isReadOnly={isGeneratingPdf || !isLatestDocumentVersion}
            />
          )
        })}
      </div>
      <div className="fixed w-40 p-2 bottom-4 right-4 flex items-center flex-col bg-slate-50/50 rounded-md">
        <label id="zoom-label" className="text-sm mb-1">
          {zoom}%
        </label>
        <Slider
          aria-describedby="zoom-label"
          min={100}
          max={200}
          step={5}
          value={[zoom]}
          defaultValue={[100]}
          onValueChange={([val]) => setZoom(val)}
        />
      </div>
    </div>
  )
}
