import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'

import { cn } from '@/utils'
import { Point, useAnnotationsQuery, useUpdateAnnotation } from './queries'
import { ALL_ANNOTATIONS_ICONS, DEFAULT_MENUS } from './menus'
import {
  DEFAULT_STROKE_COLOR,
  DEFAULT_ANNOTATION_SIZE,
  STROKE_COLOR_VARIATIONS,
} from './constants'
import { AnnotationSizes, IconStrokes, MenuIconSizes } from './enums'
import { Line } from './Line'
import { calculateLineValues, isLine, isArrow } from './utils'
import { IconSize } from './AnnotationsToolbarMenuItem'
import { Button } from '@/components/ui/button'

type DraggableAnnotationProps = {
  annotation: {
    startPoint?: Point
    endPoint?: Point
    position: Point
    id: string
    type: string
    strokeColor?: string
    size?: IconSize
    bubbleValue?: string
    text?: string
  }
}
export const DraggableAnnotation = ({
  annotation: {
    position: updatedPosition,
    id,
    size = DEFAULT_ANNOTATION_SIZE,
    strokeColor,
    type,
    startPoint,
    endPoint,
    bubbleValue,
    text: textContent,
  },
}: DraggableAnnotationProps) => {
  const { selectedAnnotation, isAddingAnnotation } = useAnnotationsQuery()
  const { mutate: updateAnnotation } = useUpdateAnnotation()
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const [{ position, delta }, setPosition] = useState({
    position: updatedPosition,
    delta: INITIAL_DELTA,
  })
  const iconData = useMemo(
    () => ALL_ANNOTATIONS_ICONS.find((icon) => type === icon.id),
    [type],
  )
  const Icon = useMemo(() => iconData?.Icon, [iconData?.Icon]) as any
  const colorOptionsEnabled = useMemo(
    () =>
      DEFAULT_MENUS.find((menu) =>
        menu.menuItems.some((item) => item.id === type),
      )?.colorOptionsEnabled,
    [type],
  )
  const isSelected = useMemo(
    () => selectedAnnotation?.annotationId === id,
    [selectedAnnotation?.annotationId, id],
  )
  const {
    attributes,
    isDragging,
    listeners,
    transform,
    setActivatorNodeRef,
    setNodeRef,
  } = useDraggable({
    id,
    disabled: isAddingAnnotation,
    data: {
      type: 'annotation',
    },
  })

  useEffect(() => {
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: transform?.x || 0,
        y: transform?.y || 0,
      },
    }))
  }, [transform])

  useEffect(() => {
    setPosition({
      position: updatedPosition,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, updatedPosition])

  const wrapperStyles = useMemo(() => {
    const styles = {
      translate: `${delta.x}px ${delta.y}px`,
      top: position.y,
      left: position.x,
      borderColor: isSelected ? '#D2E8FF' : 'transparent',
    } as React.CSSProperties

    if (isLine(type)) {
      const { inclination } = calculateLineValues({
        startPoint: startPoint as Point,
        endPoint: endPoint as Point,
      })
      styles.transformOrigin = '2px center'
      styles.rotate = `${inclination}deg`
    }

    return styles
  }, [delta, position, isSelected, endPoint, startPoint, type])

  const strokeColorOptions = useMemo(
    () =>
      [...STROKE_COLOR_VARIATIONS, DEFAULT_STROKE_COLOR].filter(
        (color) => color !== (strokeColor ?? DEFAULT_STROKE_COLOR),
      ),
    [strokeColor],
  )

  const withText = useMemo(() => isArrow(type) && type.includes('text'), [type])
  const textPosition = useMemo(
    () => (withText && type.split('--')[1]) || '',
    [type, withText],
  )

  const [text, setText] = useState(textContent || '')

  useEffect(() => {
    if (textContent) {
      setText(textContent)
    }
  }, [textContent])

  const onTextBlur = useCallback(() => {
    updateAnnotation({
      annotationId: id,
      updatedAnnotationValues: {
        text,
      },
    })
  }, [updateAnnotation, id, text])

  return (
    <div
      className={cn(
        'absolute p-0.5 box-content border-2 rounded-md outline-transparent transition-transform',
        !isLine(type) &&
          size &&
          (AnnotationSizes[size] ?? `w-${size} h-${size}`),
        {
          'border-dashed': isSelected,
          'shadow-md': isDragging,
          'shadow-gray-200': isDragging,
          'cursor-grab': !isDragging && !isAddingAnnotation,
          'cursor-grabbing': isDragging,
          'bg-sky-200/10': isDragging,
          'scale-100': !isDragging,
          'scale-105': isDragging && !isLine(type),
          'pointer-events-none': isAddingAnnotation,
          'pointer-events-auto': !isAddingAnnotation,
          'z-[2]': !isSelected,
          'z-[100]': isSelected,
        },
      )}
      style={wrapperStyles}
      ref={setNodeRef}
      {...attributes}
    >
      <div
        className="w-full h-full relative"
        ref={setActivatorNodeRef}
        {...listeners}
      >
        {!isLine(type) ? (
          Icon ? (
            <Icon
              strokeWidth={IconStrokes[size || 'small']}
              className="h-full w-full"
              strokeColor={strokeColor || DEFAULT_STROKE_COLOR}
            />
          ) : null
        ) : (
          <Line
            startPoint={startPoint as Point}
            endPoint={endPoint as Point}
            isArrow={isArrow(type)}
            isBubble={type.includes('bubble')}
            withText={withText}
            textPosition={textPosition}
            text={text}
            updateText={(e) => setText(e.target.value)}
            onTextBlur={onTextBlur}
            bubbleValue={bubbleValue}
            strokeColor={strokeColor || DEFAULT_STROKE_COLOR}
            strokeWidth={IconStrokes[size || 'small']}
          />
        )}
      </div>
      {colorOptionsEnabled && isSelected && !isDragging && (
        <div className="relative">
          <div className="absolute flex p-1 justify-center left-1/2 -translate-x-1/2 bg-slate-100/80 shadow-md w-[155px] flex flex-wrap top-2">
            {strokeColorOptions.map((color) => (
              <Button
                className="mx-0.5 flex items-center justify-center w-8 h-8 p-0.5"
                key={`annotation-stroke-color--${color}`}
                variant="outline"
                onClick={(e) => {
                  updateAnnotation({
                    annotationId: id,
                    updatedAnnotationValues: {
                      strokeColor:
                        color === DEFAULT_STROKE_COLOR ? null : color,
                    },
                  })
                  e.stopPropagation()
                }}
              >
                <Icon
                  className={
                    MenuIconSizes[size || ''] ?? MenuIconSizes['medium']
                  }
                  strokeWidth={(IconStrokes[size || ''] ?? 0) + 1}
                  strokeColor={color}
                />
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
