import { cn } from '@/utils'
export const ResetExplosionsIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M1 4.6702C2.86712 2.61487 8.00168 -0.13793 13.1362 4.6702"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0024 1.49998L13.3185 4.79139C13.3286 4.89677 13.2549 4.99177 13.1503 5.00812L10.0024 5.5"
        stroke="black"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5349 19V11.0648M7.5349 19L1.19395 16.2824C1.12381 16.2524 1.08874 16.2374 1.06299 16.2131C1.04024 16.1918 1.02281 16.1653 1.01211 16.136C1 16.1028 1 16.0646 1 15.9883V8.31508C1 8.14364 1 8.05792 1.03611 8.00629C1.06764 7.96121 1.11635 7.93111 1.17077 7.92306C1.23309 7.91386 1.30976 7.95219 1.46311 8.02886L7.5349 11.0648M7.5349 19L13.4171 16.2851C13.4845 16.254 13.5182 16.2385 13.5428 16.2144C13.5646 16.1931 13.5813 16.1671 13.5915 16.1383C13.603 16.1058 13.603 16.0688 13.603 15.9946V8.33306C13.603 8.15645 13.603 8.06815 13.5659 8.01604C13.5334 7.97056 13.4835 7.94072 13.4281 7.93373C13.3646 7.92572 13.2868 7.96758 13.1313 8.05131L7.5349 11.0648"
        stroke="#1E1E1E"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.7973V15.6007C1 15.6807 1.04768 15.753 1.12122 15.7845L7.5349 18.5332M7.5349 18.5332V10.1312M7.5349 18.5332L13.4868 15.7862C13.5577 15.7535 13.603 15.6826 13.603 15.6046V7.7973"
        stroke="#1E1E1E"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
