import {
  Object3DEventMap,
  Object3D,
  Group,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  Vector3Tuple,
  Matrix4,
  Box3,
  LineBasicMaterial,
  Line,
  BufferGeometry,
  EdgesGeometry,
  LineSegments,
} from 'three'
// @ts-expect-error - no types available
import { LineMaterial } from 'three/addons/lines/LineMaterial'
// @ts-expect-error - no types available
import { LineGeometry } from 'three/addons/lines/LineGeometry'
// @ts-expect-error - no types available
import { Line2 } from 'three/addons/lines/Line2'
import { LRUCache } from 'lru-cache'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
// @ts-expect-error - no types available
import { TransformControls } from 'three/addons/controls/TransformControls.js'
import { ASMTreeNode, RawAssemblyTree } from '@/state'
import type { Explosions } from '@/pages/CADPage/state'

type ColorCache = {
  [key: string]: {
    color: number
    mesh: Mesh
  }
}

interface Object3DUserData extends Record<string, any> {
  originalParent?: Object3D
  originalParentWorldPosition?: Vector3Tuple
  originalPosition?: Vector3Tuple
  originalWorldPosition?: Vector3Tuple
}

export class GLTFObject {
  static objectMapCache = new Map<string, Map<string, Object3D>>()

  rawGltf: GLTF
  scene: Group<Object3DEventMap>
  wireFrameScene: Group<Object3DEventMap> | null
  highlightColorCache: LRUCache<string, ColorCache>
  colorCache: LRUCache<string, ColorCache>
  transformControls: TransformControls | null
  temporaryGroup: Object3D | null
  TEMP_GROUP_NAME: string
  lines: Line[]
  lineMaterial: LineBasicMaterial

  constructor(rawGltf: GLTF) {
    this.rawGltf = rawGltf
    this.scene = this.rawGltf.scene
    this.highlightColorCache = new LRUCache<string, ColorCache>({
      max: 1000000,
    })
    this.colorCache = new LRUCache<string, ColorCache>({ max: 1000000 })
    this.temporaryGroup = null
    this.TEMP_GROUP_NAME = 'temporary-group'
    this.lines = []
    this.wireFrameScene = null
    this.lineMaterial = new LineBasicMaterial({ color: 0x000000 })
    this.transformControls = null
  }

  formatPartName(partName?: string) {
    if (partName) {
      return partName.startsWith('Group:')
        ? partName
        : partName.replace(' ', '_').replace(':', '')
    }
  }

  /**
   * viewPartAssembly - Toggles the visibility of parts in the assembly tree.
   * @param selectedPartName
   * @param prevouslySelectedPart
   * @param hiddenParts
   */
  viewPartAssembly(
    assemblyTree: RawAssemblyTree,
    selectedPartName: string,
    prevouslySelectedPart?: string,
    hiddenParts?: string[],
    colorMap?: { [key: string]: number },
    transparentParts?: string[],
  ) {
    // First, we'll get a list of parts from the assembly tree.
    // We'll traverse the tree using depth-first search, to ensure
    // the parts are in topological order.
    const parts: ASMTreeNode[] = []
    const root = assemblyTree.nodes.find(
      (node) => node.uuid === assemblyTree.root,
    )
    const q = [root]
    while (q.length > 0) {
      const n = q.pop() as ASMTreeNode
      if (!n) continue
      parts.push(n)
      n.children.forEach((childUUID) => {
        const node = assemblyTree.nodes.find((node) => node.uuid === childUUID)
        if (node) q.push(node)
      })
    }

    // Parts that occur after the selected part will be
    // toggled off. In other words, part that occur later
    // in the parts list will be hidden.
    let inSelectedRange = prevouslySelectedPart === selectedPartName
    parts.forEach((part) => {
      const partName = part.instance
      if (partName === selectedPartName) {
        inSelectedRange = true
      }

      const hidden = hiddenParts?.includes(partName)
      const color = colorMap && partName in colorMap ? colorMap[partName] : null
      const transparent = transparentParts?.includes(partName)
      const isHidden = !inSelectedRange || (inSelectedRange && hidden)

      const wireFrameNode = this.wireFrameScene?.getObjectByName(partName)

      if (wireFrameNode) {
        const isWireFrameVisible =
          isHidden || Boolean(transparent) ? false : true
        const queue = [wireFrameNode]
        while (queue.length > 0) {
          const obj = queue.pop() as Object3D
          const hasLineSegments = obj.children.some(
            (c) => c instanceof LineSegments,
          )
          obj.visible = !isWireFrameVisible && hasLineSegments ? false : true
          if (!hasLineSegments) {
            queue.push(...obj.children)
          }
        }
      }
      this.scene.getObjectByName(partName)?.traverse((child) => {
        if (isHidden) {
          child.layers.disableAll()
        } else {
          if (child.type === 'Mesh') {
            const mesh = child as Mesh
            const material = mesh.material as MeshBasicMaterial
            const matClone = material.clone()
            if (transparent) {
              matClone.opacity = 0.3
              matClone.transparent = true
            }
            if (color) {
              matClone.color.setHex(color)
            }
            mesh.material = matClone
          }
          child.layers.enableAll()
        }
      })
    })
  }

  viewPart(assemblyTree: RawAssemblyTree, target: string) {
    const root = assemblyTree.nodes.find(
      (node) => node.uuid === assemblyTree.root,
    )
    const part = assemblyTree.nodes.find((node) => node.instance === target)
    const object = this.scene.getObjectByName(part?.instance as string)

    if (!object || !part || !root) return

    this.scene.getObjectByName(root.instance)?.traverse((child) => {
      child.layers.disableAll()
    })

    object.traverse((child) => {
      child.layers.enableAll()
    })
  }

  getObjectByName(name: string): Object3D | undefined {
    return this.scene.getObjectByName(name)
  }

  /**
   * highlightPart - Highlights a part in the assembly tree.
   * @param partName
   */
  highlightPart(
    tree: RawAssemblyTree,
    partName: string,
    highlightGroup = true,
  ) {
    const highlightColor = 0x5884e7
    const colors: ColorCache = {}
    const part = this.getObjectByName(this.formatPartName(partName) as string)

    const parent = highlightGroup
      ? this.findParentGroupObject(tree, part)
      : part

    parent?.traverse((child) => {
      if (child.type === 'Mesh') {
        const mesh = child as Mesh
        const material = mesh.material as MeshBasicMaterial
        const matClone = material.clone()

        const oldColor = matClone.color.getHex()
        if (oldColor === highlightColor) return
        colors[mesh.name] = {
          color: oldColor,
          mesh,
        }

        matClone.color.setHex(highlightColor)
        mesh.material = matClone
      }
    })
    this.highlightColorCache.set(partName, colors)
  }

  /**
   * unhighlightParts - Unhighlights a part in the assembly tree.
   */
  unhighlightParts() {
    const values = this.highlightColorCache.values()
    if (!values) return
    for (const colors of values) {
      Object.entries(colors).forEach(([, colorData]) => {
        const mesh = colorData.mesh
        if (mesh) {
          const material = mesh.material as MeshBasicMaterial
          material.color.setHex(colorData.color)
        }
      })
    }
  }

  /**
   * setColor - Sets or resets the color of a part in the CAD model
   * @param partName
   * @returns the new color of the part, or the previous color if the part was already that color
   */
  setColor(partName: string, color: number): number {
    const formattedPartName = this.formatPartName(partName) as string
    const object = this.getObjectByName(formattedPartName)

    const highlights: ColorCache =
      this.highlightColorCache.get(formattedPartName) || {}
    const colors: ColorCache = this.colorCache.get(formattedPartName) || {}
    let result

    object?.traverse((child) => {
      if (child.type === 'Mesh') {
        const mesh = child as Mesh
        const material = mesh.material as MeshBasicMaterial
        const matClone = material.clone()
        const currentHightlight = highlights[mesh.name]

        if (color === currentHightlight?.color) {
          const previousHighlight = colors[mesh.name]
          if (!previousHighlight) return
          matClone.color.setHex(previousHighlight.color)
          highlights[mesh.name] = previousHighlight
          result = previousHighlight.color
        } else {
          matClone.color.setHex(color)
          highlights[mesh.name] = {
            color,
            mesh,
          }
          colors[mesh.name] = currentHightlight
          result = color
        }
      }
    })

    this.highlightColorCache.set(formattedPartName, highlights)
    this.colorCache.set(formattedPartName, colors)

    return result
  }

  resetColors(colorMap: { [key: string]: number }) {
    Object.entries(colorMap).forEach(([instanceName]) => {
      const formattedPartName = this.formatPartName(instanceName) as string
      const node = this.getObjectByName(formattedPartName as string)
      if (!node) return

      const highlights: ColorCache =
        this.highlightColorCache.get(formattedPartName) || {}
      const colors: ColorCache = this.colorCache.get(formattedPartName) || {}

      node.traverse((child) => {
        if (child.type === 'Mesh') {
          const mesh = child as Mesh
          const material = mesh.material as MeshBasicMaterial
          const matClone = material.clone()

          const previousColor = colors[mesh.name]
          if (!previousColor) return
          matClone.color.setHex(previousColor.color)
          highlights[mesh.name] = previousColor
          mesh.material = matClone
        }
      })
      this.highlightColorCache.set(formattedPartName, highlights)
    })
  }

  /**
   * setTransparency - Sets the transparency of a part in the CAD model
   * @param partName
   */

  setTransparency(
    tree: RawAssemblyTree,
    partName: string,
    transparency: number = 0.3,
  ) {
    const formattedPartName = this.formatPartName(partName) as string
    const parent = this.findParentGroupObject(
      tree,
      this.getObjectByName(formattedPartName),
    )
    let isTransparent = false

    parent?.traverse((child) => {
      if (child.type === 'Mesh') {
        const mesh = child as Mesh
        const material = mesh.material as MeshBasicMaterial
        const matClone = material.clone()
        if (matClone.transparent) {
          matClone.opacity = 1
          matClone.transparent = false
        } else {
          matClone.transparent = true
          matClone.opacity = transparency
          isTransparent = true
        }
        mesh.material = matClone
      }
    })

    const wireFrameNode =
      this.wireFrameScene?.getObjectByName(formattedPartName)
    if (wireFrameNode) {
      wireFrameNode.visible = !isTransparent
    }

    return isTransparent
  }

  resetTransparency(transparentParts: string[]) {
    transparentParts.forEach((instanceName) => {
      const formattedPartName = this.formatPartName(instanceName) as string
      const node = this.getObjectByName(formattedPartName as string)
      if (!node) return

      node.traverse((child) => {
        if (child.type === 'Mesh') {
          const mesh = child as Mesh
          const material = mesh.material as MeshBasicMaterial
          const matClone = material.clone()
          if (matClone.transparent) {
            matClone.opacity = 1
            matClone.transparent = false
            mesh.material = matClone
          }
        }
      })
    })
  }

  /**
   * findParentGroupObject - Finds the parent group object of a threejs object.
   * @param obj
   * @returns threejs group object
   */
  findParentGroupObject(
    tree: RawAssemblyTree,
    obj: Object3D<Object3DEventMap> | Group | undefined,
  ): Group | null {
    if (obj && obj.type !== 'Mesh') {
      const root = tree.nodes.find((node) => node.uuid === tree.root)
      if (root) {
        const isRootChild = root.children.some((childUUID) => {
          const child = tree.nodes.find((node) => node.uuid === childUUID)
          return this.formatPartName(child?.instance) === obj.name
        })
        if (isRootChild) {
          return obj as Group
        }
      }
    }
    if (obj && obj.parent) {
      return this.findParentGroupObject(tree, obj.parent)
    }
    return null
  }

  resetExplosions(explosions: Explosions) {
    this.cleanupTempGroup()
    this.explodeParts(explosions, { reset: true })
    this.explodeWireframes(explosions, { reset: true })
  }

  explodeParts(explosions: Explosions, options?: { reset?: boolean }) {
    Object.entries(explosions).forEach(([instanceName, explosion]) => {
      const node = this.getObjectByName(
        this.formatPartName(instanceName) as string,
      )

      if (!node) return

      if (options?.reset) {
        this.resetObjectState(node)
      } else {
        this.saveObjectState(node)
      }

      const position = options?.reset
        ? explosion.originalPosition
        : explosion.position

      node.matrixAutoUpdate = false
      const newPosition = new Vector3().fromArray(position)
      const tempMatrix = new Matrix4().copy(node.matrix)
      tempMatrix.setPosition(newPosition)
      node.position.copy(newPosition)
      node.matrix.copy(tempMatrix)
    })
  }

  /**
   * Groups parts based on assembly tree state and adds them to the scene.
   *
   * @param tree - The raw assembly tree.
   */
  groupParts(tree: RawAssemblyTree) {
    const nodes = tree?.nodes ?? []

    const getNewGroups = (scene: Object3D) =>
      nodes
        .map((node) => {
          if (!node.group) {
            return null
          }
          const newGroup = new Group()
          newGroup.name = node.instance

          node.children.forEach((childUUID) => {
            const childNode = nodes.find((n) => n.uuid === childUUID)
            if (childNode) {
              const child = scene.getObjectByName(childNode.instance)
              if (child) {
                newGroup.add(child)
              }
            }
          })

          return newGroup
        })
        .filter(Boolean) as Group[]

    const newGroups = getNewGroups(this.scene)

    newGroups.forEach((groupObj) => {
      const oldGroup = this.getObjectByName(groupObj.name)
      if (oldGroup) {
        this.scene?.remove(oldGroup)
      }
      this?.scene?.add(groupObj)
    })

    const newWireframeGroups = this.wireFrameScene
      ? getNewGroups(this.wireFrameScene)
      : []

    newWireframeGroups.forEach((groupObj) => {
      const oldGroup = this.wireFrameScene?.getObjectByName(groupObj.name)
      if (oldGroup) {
        this.wireFrameScene?.remove(oldGroup)
      }
      this.wireFrameScene?.add(groupObj)
    })
  }

  /**
   * Returns a temporary group for the GLTFObject, if necessary
   *
   * @param objects - An array of objects.
   */
  getTempGroup(objects: (Object3D | Group)[]): Object3D | null {
    if (objects.length === 0) {
      this.cleanupTempGroup()
      return null
    }

    if (objects.length === 1) {
      this.cleanupTempGroup()
      return objects[0]
    }

    if (!this.temporaryGroup) {
      const oldGroup = this.scene.getObjectByName(this.TEMP_GROUP_NAME)
      if (oldGroup) this.scene.remove(oldGroup)
      this.temporaryGroup = new Group()
      this.temporaryGroup.name = this.TEMP_GROUP_NAME
      this.scene.attach(this.temporaryGroup)
    }

    const tempGroup = this.temporaryGroup

    // Remove objects no longer in the selection
    tempGroup.children
      .filter((child) => !objects.includes(child))
      .map((child) => {
        if (child !== undefined) {
          this.returnToParent(child)
        }
      })

    // Calculate group center before adding new objects
    const boundingBox = new Box3()
    objects.forEach((obj) => boundingBox.expandByObject(obj))
    const center = new Vector3()
    boundingBox.getCenter(center)
    tempGroup.position.set(center.x, center.y, center.z)

    // Attach new objects to the temporary group
    objects.forEach((obj) => {
      if (!tempGroup.children.includes(obj)) {
        this.saveObjectState(obj)
        tempGroup.attach(obj)
      }
    })

    return tempGroup
  }

  cleanupTempGroup() {
    if (this.temporaryGroup) {
      this.temporaryGroup.children
        .filter((child) => child !== undefined)
        .map((child) => this.returnToParent(child))
      this.temporaryGroup = null
    }
    return null
  }

  returnToParent(obj: Object3D) {
    const userData = obj.userData as Object3DUserData
    const parent = userData.originalParent

    if (parent) parent.attach(obj)
    else console.error('No parent found for object', obj)
  }

  saveObjectState(obj: Object3D) {
    const parent = obj.parent
    const userData = obj.userData as Object3DUserData

    if (parent && !userData.originalParent) {
      userData.originalParent = parent
      userData.originalParentWorldPosition = parent
        .getWorldPosition(new Vector3())
        .toArray() as Vector3Tuple
    }

    if (!userData.originalPosition) {
      userData.originalPosition = obj.position.toArray() as Vector3Tuple
    }

    if (!userData.originalWorldPosition) {
      userData.originalWorldPosition = obj
        .getWorldPosition(new Vector3())
        .toArray() as Vector3Tuple
    }
  }

  resetObjectState(obj: Object3D) {
    const userData = obj.userData as Object3DUserData
    const parent = userData.originalParent

    if (parent) {
      parent.attach(obj)
      delete userData.originalParent
      delete userData.originalPosition
      delete userData.originalWorldPosition
      delete userData.originalParentWorldPosition
    }
  }

  drawDragLine(instanceName: string, dir: 'x' | 'y' | 'z') {
    const targetMesh = this.getObjectByName(instanceName)
    if (!targetMesh) return

    const material = new LineBasicMaterial({
      color: 0xf3f4f6, // Blue color for the line
    })

    const boundingBox = new Box3().setFromObject(targetMesh)
    const center = new Vector3()
    boundingBox.getCenter(center)

    const size = new Vector3()
    boundingBox.getSize(size)

    // Extend the start and end points further along the X-axis

    // Adjust these points based on the targetMesh's bounding box and your desired direction
    const points: Vector3[] = []

    if (dir === 'x') {
      const lineLength = size.x * 10 // Making the line longer based on the largest dimension
      points.push(new Vector3(center.x - lineLength / 2, center.y, center.z)) // Start point extended
      points.push(new Vector3(center.x + lineLength / 2, center.y, center.z)) // End point extended
    } else if (dir === 'y') {
      const lineLength = size.y * 10 // Making the line longer based on the largest dimension
      points.push(new Vector3(center.x, center.y - lineLength / 2, center.z)) // Start point of the line along the Y-axis
      points.push(new Vector3(center.x, center.y + lineLength / 2, center.z)) // End point of the line along the Y-axis
    } else if (dir === 'z') {
      const lineLength = size.z * 10 // Making the line longer based on the largest dimension
      points.push(new Vector3(center.x, center.y, center.z - lineLength / 2)) // Start point of the line along the Z-axis
      points.push(new Vector3(center.x, center.y, center.z + lineLength / 2)) // End point of the line along the Z-axis
    }

    const geometry = new BufferGeometry().setFromPoints(points)

    const line = new Line(geometry, material)

    // Optionally, adjust the line's position to match the target mesh's position
    line.position.copy(center)

    this.clearDragLines()
    this.scene.add(line)
    this.lines.push(line)
  }

  clearDragLines() {
    this.lines.forEach((line) => {
      this.scene.remove(line)
    })
    this.lines = []
  }

  showExplosionLines(
    explosions: Explosions,
    isEnabled: boolean,
    options?: { hidden?: string[] },
  ) {
    const groupName = 'explosion-lines'
    const oldGroup = this.scene.getObjectByName(groupName)
    if (oldGroup) {
      this.scene.remove(oldGroup)
    }

    if (!isEnabled) {
      return
    }

    const group = new Group()
    group.name = groupName
    this.scene.add(group)

    Object.entries(explosions).forEach(([name, explosion]) => {
      if (options?.hidden?.includes(name)) return
      const object = this.getObjectByName(this.formatPartName(name) as string)
      const parent = this.getObjectByName(object?.parent?.name as string)
      if (!parent) return

      const startPoint = parent.localToWorld(
        new Vector3(
          explosion.originalPosition[0],
          explosion.originalPosition[1],
          explosion.originalPosition[2],
        ),
      )
      const endPoint = parent.localToWorld(
        new Vector3(
          explosion.position[0],
          explosion.position[1],
          explosion.position[2],
        ),
      )

      if (startPoint.equals(endPoint)) {
        return
      }

      const points = [startPoint.toArray(), endPoint.toArray()]

      const geometry = new LineGeometry()
      geometry.setPositions(points.flatMap((p) => p))

      const matLine = new LineMaterial({
        color: 0xff0000,
        linewidth: 2 / 1000,
        dashed: true,
        dashSize: 2 / 1000,
        gapSize: 1 / 1000,
      })

      const line = new Line2(geometry, matLine)
      line.name = `explodeLine_${name}`
      line.computeLineDistances()
      group.attach(line)
    })
  }

  explodeWireframes(explosions: Explosions, options?: { reset?: boolean }) {
    if (!this.wireFrameScene) return
    Object.entries(explosions).forEach(([instanceName, explosion]) => {
      const node = this.getObjectByName(
        this.formatPartName(instanceName) as string,
      )

      if (!node) return

      const position = options?.reset
        ? explosion.originalPosition
        : explosion.position

      node.matrixAutoUpdate = false
      const newPosition = new Vector3().fromArray(position)
      const tempMatrix = new Matrix4().copy(node.matrix)
      tempMatrix.setPosition(newPosition)
      node.matrix.copy(tempMatrix)

      // Update the original node's position
      node.position.fromArray(position)
      node.updateMatrix()
      node.updateMatrixWorld(true)

      const queue = [node]
      while (queue.length > 0) {
        const obj = queue.pop()
        if (!obj) continue
        const wireFrameNode = this.wireFrameScene?.getObjectByName(obj.name)
        if (!wireFrameNode) continue

        wireFrameNode.position.copy(obj.position)
        wireFrameNode.rotation.copy(obj.rotation)
        wireFrameNode.scale.copy(obj.scale)
        wireFrameNode.updateMatrix()
        wireFrameNode.updateMatrixWorld(true)

        const hasMeshChildren = obj.children.some((c) => c.type === 'Mesh')

        if (hasMeshChildren) {
          obj.traverse((mesh) => {
            if (mesh instanceof Mesh) {
              const lineSegment = wireFrameNode.getObjectByName(mesh.name)
              if (lineSegment instanceof LineSegments) {
                lineSegment.position.copy(mesh.position)
                lineSegment.rotation.copy(mesh.rotation)
                lineSegment.scale.copy(mesh.scale)
                lineSegment.updateMatrix()
                lineSegment.updateMatrixWorld(true)
              }
            }
          })
        } else {
          queue.push(...obj.children)
        }
      }
    })
  }

  buildWireframe() {
    if (this.wireFrameScene) return
    this.wireFrameScene = this.scene.clone()
    this.wireFrameScene.clear()

    const queue = [[this.scene, this.wireFrameScene]]

    while (queue.length > 0) {
      const [obj, parent] = queue.pop() as [
        Object3D<Object3DEventMap>,
        Group<Object3DEventMap>,
      ]

      if (!obj || !parent) continue

      const hasMeshChildren = obj.children.some((c) => c.type === 'Mesh')

      const lineSegmentGroup = new Group()
      lineSegmentGroup.name = obj.name
      lineSegmentGroup.frustumCulled = true
      lineSegmentGroup.applyMatrix4(this.scene.matrixWorld)
      lineSegmentGroup.updateMatrixWorld()
      parent.add(lineSegmentGroup)

      if (hasMeshChildren) {
        obj.traverse((child) => {
          if (child.type === 'Mesh') {
            const mesh = child as Mesh
            const edges = new EdgesGeometry(mesh.geometry, 180)

            const line = new LineSegments(edges, this.lineMaterial)
            line.name = mesh.name
            line.frustumCulled = true
            line.applyMatrix4(mesh.matrixWorld)
            line.updateMatrixWorld()
            lineSegmentGroup.add(line)
          }
        })
      } else {
        queue.push(...obj.children.map((c) => [c, lineSegmentGroup] as any))
      }
    }
  }
}
