import { useShallow } from 'zustand/react/shallow'
import { Skeleton } from '@/components/ui/skeleton'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { ProjectDocumentTitle } from '@/components/core/ProjectDocumentTitle'
import type { DocumentPage } from '@/lib/api/client'
import { useAssemblyTree, useDocumentState } from '@/state'
import { cn } from '@/utils'

const navigateToPage = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  selector: string,
) => {
  e.preventDefault()
  document.querySelector(selector)?.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  })
}

export const ProjectTrackerTOC = () => {
  const { isLoading, data } = useDocumentPageQuery()

  if (isLoading) {
    return <Skeleton className="w-full h-6 bg-gray-200" />
  }

  const project = data?.project

  if (!project) {
    return null
  }

  return (
    <div className="h-full overflow-auto pb-5 text-sm">
      <div className="my-4 px-10">
        <ProjectDocumentTitle />
      </div>
      <div className="flex flex-col">
        {data.documentPages.map((docPage) => {
          return <TrackerItem key={docPage.id} docPage={docPage} />
        })}
      </div>
    </div>
  )
}

const TrackerItem = ({ docPage }: { docPage: DocumentPage }) => {
  const tree = useAssemblyTree((state) => state.tree)
  const currentPageInView = useDocumentState(
    useShallow((state) => state.currentPageInView),
  )
  const assemblyNode = tree?.nodes.find(
    (node) => node.uuid === docPage.assembly_group_id,
  )
  const assemblyName =
    assemblyNode?.display_name ||
    assemblyNode?.product ||
    assemblyNode?.instance

  return (
    <a
      href={`#page-${docPage.id}`}
      className={cn(
        'flex items-center space-x-2 p-2 pl-10 font-medium hover:bg-indigo-50 hover:text-primary-50',
        {
          'bg-indigo-100': currentPageInView === `page-${docPage.id}`,
        },
      )}
      onClick={(e) => {
        navigateToPage(e, `#page-${docPage.id}` || 'page')
      }}
    >
      <span>{assemblyName}</span>
    </a>
  )
}
