import { useState, useEffect } from 'react'
import { MinimalLayout } from '@/components/layouts/MinimalLayout'
import { EditorNavbar } from '@/components/nav/EditorNavbar'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable'
import { WebWorkerDebugger } from '@/components/core/WebWorkerDebugger'

import { CADEditor } from './components/CADEditor'
import { DocumentPreview } from './components/DocumentPreview'
import { SidePanel } from './components/SidePanel'

export const CADPage = () => {
  const [showWebWorkerDebugger, setShowWebWorkerDebugger] =
    useState<boolean>(false)

  useEffect(() => {
    // @ts-expect-error - adding react-query devtools toogle
    window.toggleWebWorkerDebugger = () =>
      setShowWebWorkerDebugger((old) => !old)
  }, [])

  return (
    <MinimalLayout>
      <EditorNavbar active="cad" />
      <div
        className="w-full flex bg-white"
        style={{ height: 'calc(100vh - 65px)' }}
      >
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={20}
            minSize={10}
            maxSize={30}
            style={{
              minWidth: 320,
            }}
          >
            <SidePanel />
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel>
            <CADEditor />
          </ResizablePanel>
        </ResizablePanelGroup>
        <div
          className="border border-l"
          style={{
            maxWidth: 320,
            minWidth: 320,
            width: 320,
          }}
        >
          <DocumentPreview />
        </div>
        {showWebWorkerDebugger && <WebWorkerDebugger />}
      </div>
    </MinimalLayout>
  )
}
