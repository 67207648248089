import { EmojiItem } from '@tiptap-pro/extension-emoji'

import { STROKE_COLOR_OPTIONS } from '@/pages/DocumentPage/components/Annotations/constants'
import { ntc } from '@/utils/nameThatColor'

export const HIGHLIGHT_COLORS = [
  '#FF0000', // Red
  '#FADE66', // Yellow
  '#00E200', // Green
]

const SHAPES_ICONS: Array<EmojiItem> = [
  {
    emoji: '△',
    name: 'triangle',
    shortcodes: ['triangle'],
    tags: ['shapes', 'triangle'],
    group: 'shapes',
    emoticons: [],
    version: 1,
  },
  {
    emoji: '□',
    name: 'square',
    shortcodes: ['square'],
    tags: ['shapes', 'square'],
    group: 'shapes',
    emoticons: [],
    version: 1,
  },
  {
    emoji: '○',
    name: 'circle',
    shortcodes: ['circle'],
    tags: ['shapes', 'circle'],
    group: 'shapes',
    emoticons: [],
    version: 1,
  },
]

const HARDWARE_ICONS: Array<EmojiItem> = [
  {
    name: 'torx',
    shortcodes: ['torx'],
    tags: ['hardware', 'torx'],
    group: 'hardware',
    emoticons: [],
    version: 1,
    fallbackImage: '/static/icons/torx.svg',
  },
  {
    name: 'phillips',
    shortcodes: ['phillips'],
    tags: ['hardware', 'phillips'],
    group: 'hardware',
    emoticons: [],
    version: 1,
    fallbackImage: '/static/icons/phillips.svg',
  },
  {
    name: 'hex',
    shortcodes: ['hex'],
    tags: ['hardware', 'hex'],
    group: 'hardware',
    emoticons: [],
    version: 1,
    fallbackImage: '/static/icons/hex.svg',
  },
  {
    name: 'pozidriv',
    shortcodes: ['pozidriv'],
    tags: ['hardware', 'pozidriv'],
    group: 'hardware',
    emoticons: [],
    version: 1,
    fallbackImage: '/static/icons/pozidriv.svg',
  },
  {
    name: 'fire',
    shortcodes: ['fire'],
    tags: ['hardware', 'fire'],
    group: 'hardware',
    emoticons: [],
    version: 1,
    fallbackImage: '/static/icons/fireHazard.svg',
  },
  {
    name: 'warning',
    shortcodes: ['warning'],
    tags: ['hardware', 'warning'],
    group: 'hardware',
    emoticons: [],
    version: 1,
    fallbackImage: '/static/icons/warning.svg',
  },
]

const LETTER_ICONS: Array<EmojiItem> = [
  {
    emoji: 'Ⓐ',
    name: 'A',
    shortcodes: ['A', 'a'],
    tags: ['letters', 'A'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓑ',
    name: 'B',
    shortcodes: ['B', 'b'],
    tags: ['letters', 'B'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓒ',
    name: 'C',
    shortcodes: ['C', 'c'],
    tags: ['letters', 'C'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓓ',
    name: 'D',
    shortcodes: ['D', 'd'],
    tags: ['letters', 'D'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓔ',
    name: 'E',
    shortcodes: ['E', 'e'],
    tags: ['letters', 'E'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓕ',
    name: 'F',
    shortcodes: ['F', 'f'],
    tags: ['letters', 'F'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓖ',
    name: 'G',
    shortcodes: ['G', 'g'],
    tags: ['letters', 'G'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓗ',
    name: 'H',
    shortcodes: ['H', 'h'],
    tags: ['letters', 'H'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓘ',
    name: 'I',
    shortcodes: ['I', 'i'],
    tags: ['letters', 'I'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓙ',
    name: 'J',
    shortcodes: ['J', 'j'],
    tags: ['letters', 'J'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓚ',
    name: 'K',
    shortcodes: ['K', 'k'],
    tags: ['letters', 'K'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓛ',
    name: 'L',
    shortcodes: ['L', 'l'],
    tags: ['letters', 'L'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓜ',
    name: 'M',
    shortcodes: ['M', 'm'],
    tags: ['letters', 'M'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓝ',
    name: 'N',
    shortcodes: ['N', 'n'],
    tags: ['letters', 'N'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓞ',
    name: 'O',
    shortcodes: ['O', 'o'],
    tags: ['letters', 'O'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓟ',
    name: 'P',
    shortcodes: ['P', 'p'],
    tags: ['letters', 'P'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓠ',
    name: 'Q',
    shortcodes: ['Q', 'q'],
    tags: ['letters', 'Q'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓡ',
    name: 'R',
    shortcodes: ['R', 'r'],
    tags: ['letters', 'R'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓢ',
    name: 'S',
    shortcodes: ['S', 's'],
    tags: ['letters', 'S'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓣ',
    name: 'T',
    shortcodes: ['T', 't'],
    tags: ['letters', 'T'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓤ',
    name: 'U',
    shortcodes: ['U', 'u'],
    tags: ['letters', 'U'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓥ',
    name: 'V',
    shortcodes: ['V', 'v'],
    tags: ['letters', 'V'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓦ',
    name: 'W',
    shortcodes: ['W', 'w'],
    tags: ['letters', 'W'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓧ',
    name: 'X',
    shortcodes: ['X', 'x'],
    tags: ['letters', 'X'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓨ',
    name: 'Y',
    shortcodes: ['Y', 'y'],
    tags: ['letters', 'Y'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
  {
    emoji: 'Ⓩ',
    name: 'Z',
    shortcodes: ['Z', 'z'],
    tags: ['letters', 'Z'],
    group: 'letters',
    emoticons: [],
    version: 1,
  },
]

export const CUSTOM_ICONS: Array<EmojiItem> = [
  ...STROKE_COLOR_OPTIONS.reduce((acc, color) => {
    SHAPES_ICONS.forEach((shape) => {
      const colorVariationName = ntc
        .name(color)[1]
        .toLowerCase()
        .replace(' ', '-')
      const updatedName = `${shape.name}-${colorVariationName}`
      acc.push({
        ...shape,
        color,
        name: updatedName,
        tags: [...shape.tags, colorVariationName],
        shortcodes: [...shape.shortcodes, updatedName],
      })
    })
    return acc
  }, [] as Array<EmojiItem>),
  ...HARDWARE_ICONS,
  ...LETTER_ICONS,
]
