import { Modifier } from '@dnd-kit/core'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toChunks(array: Array<any>, chunkSize: number) {
  return array.reduce((accumulator: Array<any>, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize) as number

    if (!accumulator[chunkIndex]) {
      accumulator[chunkIndex] = []
    }

    accumulator[chunkIndex].push(item)
    return accumulator
  }, [])
}

export function createSnapModifier({
  gridDimensions,
  gridContainer,
}: {
  gridDimensions: {
    width: number
    height: number
  }
  gridContainer: Element | null
}): Modifier {
  return ({ transform, activeNodeRect }) => {
    const { width: gridWidth, height: gridHeight } = gridDimensions
    const gridRect = gridContainer?.getBoundingClientRect()
    const snappedTransform = {
      x: Math.round(transform.x / gridWidth) * gridWidth,
      y: Math.round(transform.y / gridHeight) * gridHeight,
    }
    const delta = {
      x: (activeNodeRect?.left || 0) - (gridRect?.left || 0),
      y: (activeNodeRect?.top || 0) - (gridRect?.top || 0),
    }
    const adjust = {
      x: (delta?.x % gridWidth) / gridWidth || 0,
      y: (delta?.y % gridHeight) / gridHeight || 0,
    }
    const result = {
      ...snappedTransform,
    }

    if (snappedTransform.x && adjust.x) {
      result.x = snappedTransform.x - gridWidth * adjust.x
      if (snappedTransform.x < 0 && adjust.x) {
        result.x += gridWidth
      }
    }

    if (snappedTransform.y && adjust.y) {
      result.y = snappedTransform.y - gridHeight * adjust.y
      if (snappedTransform.y < 0) {
        result.y += gridHeight
      }
    }

    return {
      ...transform,
      ...result,
    }
  }
}
