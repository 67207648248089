import { useState, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { FileIcon } from '@/components/icons/FileIcon'
import { EyeClosedIcon } from '@/components/icons/EyeClosedIcon'
import { EyeOpenIcon } from '@/components/icons/EyeOpenIcon'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import * as z from 'zod'

import { cn } from '@/utils'
import queryClient from '@/queryClient'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  CollapsibleTriangleIcon,
} from '@/components/ui/collapsible'
import { Button } from '@/components/ui/button'

import { ASMTreeNode, useAssemblyTree } from '@/state'
import {
  useUpdateCadVersionMutation,
  useAssemblyTreeQuery,
} from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useSelectable } from '@/pages/CADPage/hooks'
import { AssemblyMenuButton } from '@/pages/CADPage/components/AssemblyMenuButton'
import { Input } from '@/components/ui/input'
import { useCADPageStore } from '../state'
import { QUERY_KEYS as VIEW_JOB_QUERY_KEYS } from '@/services/queries/view_jobs'

export const StaticAssembly = () => {
  const [editingPart, setEditingPart] = useState<string | null>(null)

  const tree = useAssemblyTree((state) => state.tree)
  const getRoot = useAssemblyTree((state) => state.getRoot)
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const getAssemblyTree = useAssemblyTree((state) => state.getAssemblyTree)
  const renamePart = useAssemblyTree((state) => state.renameNode)
  const setVisibility = useAssemblyTree((state) => state.setVisibility)
  const getNodeAndDescendants = useAssemblyTree(
    (state) => state.getNodeAndDescendants,
  )

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const isHidden = useCADPageStore((state) => state.isHidden)
  const hiddenParts = useCADPageStore(useShallow((state) => state.hiddenParts))
  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))
  const transparentParts = useCADPageStore(
    useShallow((state) => state.transparentParts),
  )
  const wandSelected = useCADPageStore((state) => state.wandSelected)
  const setWandSelected = useCADPageStore((state) => state.setWandSelected)
  const highlightedPartUUID = useCADPageStore(
    (state) => state.highlightedPartUUID,
  )
  const explosions = useCADPageStore((state) => state.explosions)
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const focusedPartUUID = useCADPageStore((state) => state.focusedPartUUID)

  const selectable = useSelectable({
    onRangeSelect: (uuids) => {
      // Select a range of parts
      const root = getRoot()
      if (root) {
        const sortedSelections = uuids.sort((a, b) => {
          const indexA = root.children.indexOf(a)
          const indexB = root.children.indexOf(b)
          return indexB - indexA
        })
        const maxIndex = root.children.indexOf(sortedSelections[0])
        const minIndex = root.children.indexOf(
          sortedSelections[sortedSelections.length - 1],
        )
        for (let i = minIndex; i <= maxIndex && i < root.children.length; i++) {
          selectable.select(root.children[i])
        }
      }
    },
    onSelect: (uuid) => {
      if (uuid !== editingPart) {
        setEditingPart(null)
      }

      if (wandSelected) setWandSelected(null)
    },
    onReset: (uuid) => {
      const node = getNode(uuid)
      const assemblyTree = getAssemblyTree()
      if (data?.gltf && node && assemblyTree) {
        data.gltf.viewPartAssembly(
          assemblyTree,
          node.instance,
          node.instance,
          hiddenParts,
          colorMap,
          transparentParts,
        )
      }
    },
  })

  const { mutate: updateCadVersion } = useUpdateCadVersionMutation()
  const { isLoading, data } = useCADQuery()

  const projectId = data.project?.id as string
  const cadVersionId = data?.version?.id as string
  const { documentId } = useCadPageParams()

  const { isLoading: isLoadingAssembly } = useAssemblyTreeQuery({
    cadVersionId,
    projectId,
    documentId,
  })

  const getLowestSelected = (root: ASMTreeNode, selected: string[]) => {
    if (!root) {
      return null
    }

    return selected.sort((a, b) => {
      const indexA = root.children.indexOf(a)
      const indexB = root.children.indexOf(b)
      return indexB - indexA
    })[0]
  }

  // Reset on Mount
  const [isReset, setIsReset] = useState(false)
  useEffect(() => {
    if (!data?.gltf || !tree || isReset) return
    data.gltf.viewPartAssembly(tree, '', '')
    setIsReset(true)
  }, [data?.gltf, tree])

  // Update CAD model based on hidden parts
  useEffect(() => {
    const tree = getAssemblyTree()
    const root = getRoot()
    if (!tree || !data?.gltf || !root) {
      return
    }
    const lowestSelected = getLowestSelected(root, selectable.selected)
    const selectedNode = lowestSelected === null ? '' : getNode(lowestSelected)
    const selectedNodeName = selectedNode ? selectedNode.instance : ''

    let hiddenPartsUpdate = hiddenParts
    if (lowestSelected && selectedNodeName !== '') {
      const lowestNodeIndex = root.children.indexOf(lowestSelected)
      hiddenPartsUpdate = hiddenParts.filter((part) => {
        const nodeIndex = root.children.findIndex(
          (child) => getNode(child)?.instance === part,
        )
        return nodeIndex <= lowestNodeIndex
      })
      setCadPageState({ hiddenParts: hiddenPartsUpdate })
    }
    data.gltf?.viewPartAssembly(
      tree,
      '',
      '',
      hiddenPartsUpdate,
      colorMap,
      transparentParts,
    )
    data.gltf?.explodeParts(explosions)
  }, [hiddenParts])

  // Highlight selected parts
  useEffect(() => {
    const tree = getAssemblyTree()
    if (!tree || !data?.gltf) {
      return
    }
    data.gltf?.unhighlightParts()

    if (selectable.selected.length >= 1) {
      selectable.selected.forEach((uuid) => {
        const node = getNode(uuid)
        if (node) {
          data.gltf?.highlightPart(tree, node.instance, false)
        }
      })
    }

    return () => {
      data.gltf?.unhighlightParts()
    }
  }, [selectable.selected, data.gltf, getAssemblyTree, getNode])

  // Reset Visibility on Mount
  useEffect(() => {
    const root = getRoot()
    if (!root) return
    getNodeAndDescendants(root).forEach((node) => {
      setVisibility(node.uuid, true)
    })
  }, [data?.gltf])

  if (data.hasNoDownloadUrl) {
    return null
  }

  if (isLoading || isLoadingAssembly) {
    return <div>Loading...</div>
  }

  if (!tree) {
    return <div>No assembly tree found</div>
  }

  const root = getRoot()

  if (!root) {
    return <div>No assembly tree root found</div>
  }

  const documentVersionId = data?.documentVersion?.id

  const docPageIds = root.children
    .map((child) => {
      const node = getNode(child)
      return node?.document_page_id
    })
    .filter((id) => !!id)

  const AssemblyMenuButtonContent = ({
    node,
    isPartHighlighted,
    depth,
  }: {
    node: ASMTreeNode
    isPartHighlighted: boolean | undefined
    depth: number
  }) => {
    return (
      <div
        style={{
          paddingLeft: `${depth * 20}px`,
        }}
        id={`part${node.uuid}`}
        onMouseDown={(e) => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
          if (node?.document_page_id) {
            queryClient.invalidateQueries({
              queryKey: [VIEW_JOB_QUERY_KEYS.VIEW_JOBS],
            })
          }
        }}
        onMouseEnter={(e) => {
          e.stopPropagation()
          const tree = getAssemblyTree()
          if (data?.gltf) data.gltf.unhighlightParts()
          if (
            data?.gltf &&
            node &&
            tree &&
            !(
              selectable.selected.length > 1 && selectable.isSelected(node.uuid)
            )
          ) {
            data.gltf.highlightPart(tree, node.instance, false)
          }
        }}
        onMouseLeave={(e) => {
          e.stopPropagation()
          if (data.gltf) {
            data.gltf.unhighlightParts()

            if (selectable.selected.length > 1) {
              selectable.selected.forEach((uuid) => {
                const node = getNode(uuid)
                if (node) {
                  data.gltf?.highlightPart(tree, node.instance, false)
                }
              })
            }
          }
        }}
        className={cn(
          'group/eyeball border border-transparent hover:bg-primary-10 hover:!text-gray-900 cursor-pointer select-none',
          {
            'bg-primary-10':
              selectable.isSelected(node.uuid) || isPartHighlighted,
            'text-gray-300': node.visible === false,
            'border-primary-50 text-primary-50 rounded-sm':
              explosionsToolbar && focusedPartUUID === node.uuid,
          },
        )}
      >
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2 w-full">
            {depth === 0 && node.children.length > 0 ? (
              <CollapsibleTrigger
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 py-2 px-2"
                style={{ width: '36px', minWidth: '36px' }}
              >
                <Button variant="ghost" className="px-2">
                  <CollapsibleTriangleIcon
                    className={cn('w-5 h-5', {
                      'text-emerald-400': node.visible !== false && node.group,
                      'text-gray-600': node.visible !== false && !node.group,
                      'text-gray-300': node.visible === false,
                    })}
                  />
                </Button>
              </CollapsibleTrigger>
            ) : (
              <div
                className="h-9"
                style={{ width: '36px', minWidth: '36px' }}
              ></div>
            )}
            <div
              className="w-full"
              style={{ width: '85%' }}
              onMouseDown={() => {
                window.location.hash = `#${
                  docPageIds.indexOf(node.document_page_id) >= 0
                    ? 'op' +
                      String(docPageIds.indexOf(node.document_page_id) + 1)
                    : ''
                }`
              }}
            >
              <div
                className="flex items-center space-x-2 w-full py-1.5 justify-between"
                {...selectable.handlers(node.uuid)}
              >
                <div className="flex items-center space-x-2 truncate">
                  {editingPart === node.uuid ? (
                    <RenamePartForm
                      node={node}
                      onSubmit={({ displayName }) => {
                        renamePart(node.uuid, displayName)
                        const assemblyTree = getAssemblyTree()
                        if (assemblyTree && cadVersionId && documentVersionId) {
                          updateCadVersion({
                            cadVersionId,
                            documentVersionId,
                            assemblyTree,
                          })
                        }
                      }}
                      onCompeleted={() => {
                        setEditingPart(null)
                      }}
                    />
                  ) : (
                    <span className="truncate text-ellipsis">
                      {node.display_name}
                    </span>
                  )}
                </div>
                <div className="flex flex-row items-center gap-2 pr-2">
                  <div
                    className="invisible group-hover/eyeball:visible"
                    onPointerDown={(e) => {
                      e.stopPropagation()
                      const tree = getAssemblyTree()
                      if (data.gltf && tree) {
                        selectable.reset()
                        let hiddenPartsUpdate = hiddenParts
                        const nodes = getNodeAndDescendants(node)
                        const nodesUUIDs = nodes.map((n) => n.uuid)
                        const nodesNames = nodes.map((n) => n.instance)
                        if (isHidden(node.instance)) {
                          nodesUUIDs.forEach((n) => setVisibility(n, true))
                          hiddenPartsUpdate = hiddenParts.filter(
                            (part) => !nodesNames.includes(part),
                          )
                          setCadPageState({
                            hiddenParts: hiddenPartsUpdate,
                          })
                        } else {
                          nodesUUIDs.forEach((n) => setVisibility(n, false))
                          hiddenPartsUpdate = [
                            ...hiddenParts,
                            ...(nodesNames as string[]),
                          ]
                          setCadPageState({
                            hiddenParts: hiddenPartsUpdate,
                          })
                        }
                        data.gltf.unhighlightParts()
                      }
                    }}
                  >
                    <ShowHidePartIcon
                      visible={node.visible !== false}
                      hidden={isHidden(node.instance)}
                    />
                  </div>
                  <PartInfoIcon node={node} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="h-full w-full overflow-auto text-sm font-medium">
      {root.children.map((child) => {
        const node = getNode(child)
        const hasDocument = selectable.selected.some((uuid) => {
          const currNode = getNode(uuid)
          return !!currNode?.document_page_id
        })

        const isPartHighlighted = node && node.uuid === highlightedPartUUID

        return (
          node && (
            <Collapsible key={node.uuid} id={node.uuid}>
              <AssemblyMenuButton
                key={node.uuid}
                disabled={!data.isLatestCadVersion}
                selectedCount={selectable.size}
                isGroup={node.group && !node.instance.startsWith('Group')}
                hasDocument={hasDocument}
                onRename={() => {
                  setEditingPart(node.uuid)
                }}
              >
                <AssemblyMenuButtonContent
                  node={node}
                  isPartHighlighted={isPartHighlighted}
                  depth={0}
                />
              </AssemblyMenuButton>
              <CollapsibleContent>
                {getNodeAndDescendants(node).map((childNode, idx) => {
                  if (idx === 0 || !childNode.depth) return null
                  const childHighlighted =
                    childNode && childNode.uuid === highlightedPartUUID
                  return (
                    childNode && (
                      <AssemblyMenuButton
                        key={childNode.uuid}
                        disabled={!data.isLatestCadVersion}
                        selectedCount={selectable.size}
                        isGroup={
                          childNode.group &&
                          !childNode.instance.startsWith('Group')
                        }
                        hasDocument={hasDocument}
                        onRename={() => {
                          setEditingPart(childNode.uuid)
                        }}
                      >
                        <AssemblyMenuButtonContent
                          node={childNode}
                          isPartHighlighted={childHighlighted}
                          depth={childNode.depth}
                        />
                      </AssemblyMenuButton>
                    )
                  )
                })}
              </CollapsibleContent>
            </Collapsible>
          )
        )
      })}
    </div>
  )
}

const PartInfoIcon = ({ node }: { node: ASMTreeNode }) => {
  if (node.document_page_id) {
    return (
      <FileIcon
        className={cn('w-4 h-4', {
          'stroke-gray-300': node.visible === false,
          'stroke-primary-50': node.visible !== false,
        })}
      />
    )
  }
  return <div className="w-4 h-4"></div>
}

const ShowHidePartIcon = ({
  visible,
  hidden,
}: {
  visible: boolean
  hidden: boolean
}) => {
  if (visible) return <EyeOpenIcon className="w-4 h-4 stroke-primary-50" />
  else if (hidden)
    return <EyeClosedIcon className="visible w-4 h-4 stroke-gray-300" />
  else return null
}

const formSchema = z.object({
  displayName: z.string().min(1, {
    message: 'Part cannot be empty.',
  }),
})

const RenamePartForm = ({
  node,
  onCompeleted,
  onSubmit,
}: {
  node: ASMTreeNode
  onSubmit: (values: { displayName: string }) => void
  onCompeleted: () => void
}) => {
  const form = useForm<{ displayName: string }>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      displayName: node.display_name,
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      form.setFocus('displayName')
    }, 290)

    return () => clearTimeout(timer)
  }, [])

  function onFormSubmit(values: z.infer<typeof formSchema>) {
    onSubmit(values)
    onCompeleted()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFormSubmit)} className="p-2">
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input className="bg-white" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center space-x-1 justify-end mt-2">
          <Button className="h-6 px-2" type="submit">
            <CheckIcon className="w-3 h-3" />
          </Button>
          <Button
            variant="secondary"
            className="h-6 px-2"
            onClick={onCompeleted}
          >
            <Cross2Icon className="w-3 h-3" />
          </Button>
        </div>
      </form>
    </Form>
  )
}
