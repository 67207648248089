import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '@/queryClient'
import { RawAssemblyTree } from '@/state'
import {
  listDocumentPages,
  getDocumentPage,
  deleteDocumentPage,
  createDocumentPage,
  reorderDocumentPages,
} from '@/lib/api/client/documentPages'
import { QUERY_KEYS as DOCUMENTS_QUERY_KEYS } from '@/services/queries/documents'
import { DocumentPage, updateDocumentPage } from '@/lib/api/client'
import QUERY_KEYS from './queryKeys'
import MUTATION_KEYS from './mutationKeys'

export const listDocumentPagesQuery = ({
  documentVersionId,
}: {
  documentVersionId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
    queryFn: async () => {
      const docs = await listDocumentPages(documentVersionId)
      return docs
    },
  })
}

export const useListDocumentPagesQuery = ({
  documentVersionId,
}: {
  documentVersionId?: string | null
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
    queryFn: async () => {
      const docs = documentVersionId
        ? await listDocumentPages(documentVersionId)
        : []
      return docs
    },
    enabled: Boolean(documentVersionId),
  })
}

export const useUpdateDocumentPage = (props?: {
  onSuccess?: (response?: DocumentPage | Array<DocumentPage>) => void
}) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_DOCUMENT_PAGE],
    mutationFn: async ({
      documentPageId,
      values,
    }: {
      documentPageId: string
      values: {
        name: string
        assembly_group_id: string
        template_values: any
      }
    }) => updateDocumentPage(documentPageId, values),
    onSuccess: (documentPage) => {
      queryClient.setQueryData(
        [
          QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId: documentPage?.document_version },
        ],
        (documentPages: Array<DocumentPage> | undefined) => {
          if (documentPages)
            return documentPages.map((currentDocumentPage) =>
              currentDocumentPage.id === documentPage?.id
                ? documentPage
                : currentDocumentPage,
            )
        },
      )
      queryClient.setQueryData(
        [QUERY_KEYS.DOCUMENT_PAGE, { documentPageId: documentPage?.id }],
        documentPage,
      )

      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (props?.onSuccess) props.onSuccess(documentPage)
    },
  })
}

export const getDocumentPageQuery = ({
  documentPageId,
}: {
  documentPageId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_PAGE, { documentPageId }],
    queryFn: () => getDocumentPage(documentPageId),
  })
}

export const useDeleteDocumentPage = () => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_DOCUMENT_PAGE],
    mutationFn: async ({ documentPageId }: { documentPageId: string }) =>
      deleteDocumentPage(documentPageId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENT_PAGES, {}],
      })
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })
    },
  })
}

export const useCreateDocumentPage = () => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT_PAGE],
    mutationFn: async ({
      documentVersionId,
      values,
    }: {
      documentVersionId: string
      values: {
        name: string
        assembly_group_id: string
        template_values: any
      }
    }) => {
      const documentPage = await createDocumentPage(documentVersionId, values)
      return {
        documentPage,
        documentVersionId,
      }
    },
    onSuccess: ({ documentVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
      })
    },
  })
}

export const useReorderDocumentPages = () => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.REORDER_DOCUMENT_PAGES],
    mutationFn: async ({
      documentVersionId,
      assemblyTree,
    }: {
      documentVersionId: string
      assemblyTree: RawAssemblyTree
    }) => {
      return reorderDocumentPages(documentVersionId, assemblyTree)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENT_PAGES, {}],
      })
    },
  })
}
