import { useShallow } from 'zustand/react/shallow'
import { useParams, useSearch } from 'wouter'
import queryString from 'query-string'
import { ThreeEvent } from '@react-three/fiber'

import { useCADPageStore } from './state'

interface SelectableOptions {
  onRangeSelect?: (uuids: string[]) => void
  onSelect?: (uuid: string) => void
  onReset?: (uuid: string) => void
}

export const useSelectable = ({
  onSelect,
  onReset,
  onRangeSelect,
}: SelectableOptions) => {
  const selected = useCADPageStore((state) => state.selectedParts)
  const setState = useCADPageStore(useShallow((state) => state.setState))
  const pushSelectedPart = useCADPageStore(
    useShallow((state) => state.pushSelectedPart),
  )

  return {
    size: selected.length,
    selected,
    select: (uuid: string) => pushSelectedPart(uuid),
    reset: () => setState({ selectedParts: [] }),
    isSelected: (uuid: string) => selected.includes(uuid),
    handlers: (uuid: string) => ({
      onPointerDown: (
        e: React.MouseEvent<HTMLDivElement> | ThreeEvent<PointerEvent>,
      ) => {
        const rightClick = e.button === 2
        const commandKey = e.metaKey || e.ctrlKey || e.shiftKey

        if (commandKey) {
          if (selected.includes(uuid)) {
            setState({ selectedParts: selected.filter((id) => id !== uuid) })
          } else {
            if (selected.length === 1 && typeof onRangeSelect === 'function') {
              onRangeSelect([...new Set([...selected, uuid])])
            }
            pushSelectedPart(uuid)
          }
          return
        }

        if (selected.length === 1 && selected[0] === uuid && !rightClick) {
          setState({ selectedParts: [] })
          if (typeof onReset === 'function') {
            onReset(uuid)
          }
          return
        }

        if (!rightClick) {
          setState({ selectedParts: [uuid] })
          if (typeof onSelect === 'function') {
            onSelect(uuid)
          }
          return
        }

        if (rightClick && selected.length === 0) {
          setState({ selectedParts: [uuid] })
          if (typeof onSelect === 'function') {
            onSelect(uuid)
          }
          return
        }
      },
    }),
  }
}

export const useCadPageParams = () => {
  const params = useParams<{
    projectId: string
    documentId: string
  }>()
  const search = useSearch()
  const queryParams = queryString.parse(search) as {
    cv?: string // cad version
    dv?: string // document version
  }
  return { ...params, ...queryParams }
}
