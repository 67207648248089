import { RawAssemblyTree } from '@/state'
import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CSRF_TOKEN_NAME } from '@/constants'
import type { DocumentPage } from './types'

/**
 * Create a new document page
 * @param documentId
 * @param values
 * @returns
 */
export const createDocumentPage = async (
  documentVersionId: string,
  values: {
    name: string
    assembly_group_id: string
    template_values: any
  },
) => {
  await getCsrf()
  const resp = await client.POST(
    '/api/v1/projects/{document_version_id}/document_pages',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_version_id: documentVersionId,
        },
      },
      body: values,
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create document page')
  }

  return resp.data
}

/**
 * list a document pages
 * @param documentVersionId
 * @returns
 */
export const listDocumentPages = async (documentVersionId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/{document_version_id}/document_pages',
    {
      params: {
        path: {
          document_version_id: documentVersionId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list document pages')
  }

  return resp.data
}

/**
 * Reorder document pages
 * @param documentVersionId
 * @param values
 * @returns
 */
export const reorderDocumentPages = async (
  documentVersionId: string,
  assemblyTree: RawAssemblyTree,
) => {
  const documentPages = await listDocumentPages(documentVersionId)

  const validDocumentPages: DocumentPage[] = []
  const invalidDocumentPages: DocumentPage[] = []

  // Find document that have assembly_group_id in the assembly tree.
  // Document that do not have assembly_group_id in the assembly tree will be
  // considered invalid.
  documentPages.forEach((page) => {
    const hasAssemblyNode = assemblyTree.nodes.some(
      (node) => node.uuid === page.assembly_group_id,
    )
    if (hasAssemblyNode) {
      validDocumentPages.push(page)
    } else {
      invalidDocumentPages.push(page)
    }
  })

  // Remove invalid document pages. This will ensure the reordering operation
  // will not fail.
  for (const page of invalidDocumentPages) {
    await deleteDocumentPage(page.id as string)
  }

  // Traverse through the assembly tree in topological order and add valid
  // document page ids to the `documentIds list. Remember we want to maintain
  // the order of the document pages associated the assembly tree.
  const documentIds: string[] = []
  const q = [assemblyTree.root]

  while (q.length > 0) {
    const uuid = q.pop()
    const node = assemblyTree.nodes.find((node) => node.uuid === uuid)
    const documentPageIds = node
      ? validDocumentPages
          .filter((page) => page.assembly_group_id === node?.uuid)
          .map((page) => page.id as string)
      : []

    if (documentPageIds.length > 0) {
      documentIds.push(...documentPageIds)
    }
    if (node?.children) {
      q.push(...node.children)
    }
  }

  await getCsrf()
  const resp = await client.POST(
    '/api/v1/projects/{document_version_id}/document_pages/reorder',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_version_id: documentVersionId,
        },
      },
      body: {
        document_ids: documentIds.reverse(),
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to reorder document pages')
  }

  return resp.data
}

/**
 * Get a document page
 * @param documentPageId
 * @returns
 */
export const getDocumentPage = async (documentPageId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/document_pages/{document_page_id}',
    {
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get document page')
  }

  return resp.data
}

/**
 * Update a document page
 * @param documentPageId
 * @param values
 * @returns
 */
export const updateDocumentPage = async (
  documentPageId: string,
  values: {
    name: string
    assembly_group_id: string
    template_values: any
  },
) => {
  await getCsrf()
  const resp = await client.PUT(
    '/api/v1/projects/document_pages/{document_page_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
      body: values,
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to update document page')
  }

  return resp.data
}

/**
 * Delete a document page
 * @param documentPageId
 * @returns
 */
export const deleteDocumentPage = async (documentPageId: string) => {
  await getCsrf()
  const resp = await client.DELETE(
    '/api/v1/projects/document_pages/{document_page_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to delete document page')
  }

  return resp.data
}
