import { useShallow } from 'zustand/react/shallow'
import { useAssemblyTree, ASMTreeNode } from '@/state'
import { cn } from '@/utils'

export const AssemblyParts = ({
  depth,
  order,
  node,
}: {
  depth: number
  order: number[]
  node: ASMTreeNode
}) => {
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))

  return (
    <div
      style={{
        paddingLeft: `${depth * 20}px`,
      }}
    >
      <div className="flex items-center space-x-2 mb-2">
        {/* <span className="h-1 w-1 bg-gray-400 rounded-full"></span> */}
        <span
          className={cn({
            'text-gray-300': node.visible === false,
          })}
        >
          {node.display_name}
        </span>
      </div>
      {node.children.map((child, i) => {
        const childNode = getNode(child)
        return (
          childNode && (
            <AssemblyParts
              key={childNode.uuid}
              node={childNode}
              depth={depth + 1}
              order={[...order, i]}
            />
          )
        )
      })}
    </div>
  )
}
