interface CreateMarkProps {
  projectId: string
  documentId: string
  text: string
  hash: string
}

export const createMark = ({
  projectId,
  documentId,
  text,
  hash,
}: CreateMarkProps) => {
  const host = window.location.origin
  const href = `${host}/p/${projectId}/document/${documentId}/edit#${hash}`
  return `<mark class="slash-command-pill" href="${href}" style="background-color: #DAF2EF;color: rgb(30, 30, 30);">${text}</mark> `
}
