import { ReactNode, ReactElement, useEffect, useRef, useState } from 'react'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Skeleton } from '@/components/ui/skeleton'
import {
  DocumentPage,
  View,
  DocumentTypeChoices,
  ImageData,
} from '@/lib/api/client'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useAssemblyTree, useAppStore } from '@/state'
import { Button } from '@/components/ui/button'
import { TrashIcon } from '@heroicons/react/24/solid'
import { CameraPlusIcons } from '@/components/icons/CameraPlusIcon'
import { CameraRetakeIcon } from '@/components/icons/CameraRetakeIcon'
import { useDeleteViewMutation, useViewCreateMutation } from '../queries'
import { useCADQuery } from '@/services/queries/cads'
import { useDeleteImages } from '@/services/queries/images'
import { useDeleteDocumentPage } from '@/services/queries/document_pages'
import { useDocumentPreview } from '@/services/hooks/document_preview'
import {
  useListNotes,
  useListViews,
} from '@/services/hooks/template_attributes'
import { Loading } from '@/components/ui/loading'
import { cn } from '@/utils'
import { useCADPageStore } from '../state'
import { useShallow } from 'zustand/react/shallow'
import { Markup } from '@/components/editor/Markup'
import { ToolsTablePreview } from './ToolsTablePreview'
import { cleanAndFilterViews } from '@/utils/document_preview'
import { Image } from '@/components/pdf/Image'

export const DocumentPreview = () => {
  const { data: cadData } = useCADQuery()
  const { isLoading, data } = useDocumentPageQuery()

  const documentPages = data?.documentPages.sort(
    (a, b) => (a.order_number || 0) - (b.order_number || 0),
  )

  const documentVersionId = cadData?.documentVersion?.id as string
  const cadId = cadData?.cad?.id as string
  const documentType = data?.documentType

  const viewUploadsInProgress = useAppStore(
    (state) => state.viewUploadsInProgress,
  )

  const isCreatingFirstDocument =
    documentPages?.length === 0 && viewUploadsInProgress.length > 0
  const isDocumentLoading = isLoading || !cadId || isCreatingFirstDocument

  if (
    cadData.hasNoDownloadUrl ||
    !cadData.isLatestCadVersion ||
    !cadData.documentVersion ||
    !documentType
  ) {
    return null
  }

  return (
    <div className="flex flex-col items-center space-y-2 h-full">
      <h1 className="text-md font-medium my-4">Preview</h1>
      {isDocumentLoading ? (
        Array.from({ length: 1 }).map((_, i) => (
          <Skeleton
            key={i}
            className="w-full bg-gray-200 w-[264px] h-[204px]"
          />
        ))
      ) : (
        <div
          className="w-full flex flex-col items-center space-y-8 px-5 overflow-auto"
          style={{ paddingBottom: 35 }}
        >
          {documentPages && documentPages.length > 0 ? (
            <>
              {documentPages?.map((documentPage, i) => (
                <OperationPreview
                  key={documentPage.id}
                  documentPage={documentPage}
                  orderNumber={i + 1}
                  cadId={cadId}
                  documentVersionId={documentVersionId}
                  documentType={documentType}
                />
              ))}
            </>
          ) : (
            <div className="flex flex-col items-center space-y-2">
              <h1 className="text-md font-semibold text-center">
                No operations found.
              </h1>
              <p className="text-sm text-gray-500 text-center">
                Select a part from the assembly tree and take a smart screenshot
                to create an operation.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const OperationPlaceholder = ({
  position,
  documentPage,
  documentType,
}: {
  position: number
  documentPage: DocumentPage
  documentType: DocumentTypeChoices
}) => {
  const { mutate: createView, isPending: isCreatingView } =
    useViewCreateMutation({ documentType })
  const { isLoading, data: cadData } = useCADQuery()
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const renderMode = useCADPageStore((state) => state.renderMode)

  const gltf = cadData.gltf

  const {
    explosions,
    hiddenParts,
    colorMap,
    transparentParts,
    cameraAspect,
    cameraPosition,
    cameraFov,
    cameraZoom,
    cameraQuaternion,
    cameraUp,
    selectedPartUUID,
  } = useCADPageStore((state) => ({
    explosions: state.explosions,
    hiddenParts: state.hiddenParts,
    colorMap: state.colorMap,
    transparentParts: state.transparentParts,
    cameraAspect: state.cameraAspect,
    cameraPosition: state.cameraPosition,
    cameraFov: state.cameraFov,
    cameraZoom: state.cameraZoom,
    cameraQuaternion: state.cameraQuaternion,
    cameraUp: state.cameraUp,
    selectedPartUUID:
      state.explosionsToolbar && state.focusedPartUUID
        ? state.focusedPartUUID
        : state.selectedParts.length === 1
          ? state.selectedParts[0]
          : null,
  }))

  const addScreenshot = (position: number) => {
    const image_data: ImageData = {
      cameraAspect,
      cameraPosition,
      cameraFov,
      cameraZoom,
      cameraQuaternion,
      cameraUp,
      explosions,
      hiddenParts,
      colorMap,
      transparentParts,
      isExplosionLinesEnabled,
      renderMode,
    }

    const node = getNode(documentPage.assembly_group_id)
    const partName =
      node?.display_name || node?.product || node?.instance || 'part'

    const docPageId = documentPage.id
    const docVersionId = documentPage.document_version
    const cadId = cadData.cad?.id
    const cadVersionId = cadData?.version?.id
    const projectId = cadData?.project?.id

    if (cadVersionId && selectedPartUUID && gltf && cadId && projectId) {
      const projectName = cadData?.project?.name || 'project'
      const cadVersionNumber = cadData.version?.version_number || 1
      const viewName =
        documentType === 'work_instructions'
          ? `${projectName}-${partName}-${cadVersionNumber}`.replace(' ', '-')
          : documentType === 'project_tracker'
            ? `${projectName}-${partName}-${cadVersionNumber}-${new Date()
                .toISOString()
                .replace(/[:.TZ]/g, '')}`.replace(' ', '-')
            : documentType === 'visual_bom'
              ? `${projectName}-${partName}`.replace(' ', '-')
              : `${projectName}-${partName}-${cadVersionNumber}`.replace(
                  ' ',
                  '-',
                )

      createView({
        cadId,
        cadVersionId,
        documentPageId: docPageId,
        docVersionId,
        assemblyPartUUID: selectedPartUUID,
        projectId,
        values: {
          download_url: null,
          source_type:
            documentType === 'visual_bom' ? 'isometric' : 'screenshot',
          name: viewName,
          image_data,
          cad_version: cadVersionId,
          assembly_group_id: selectedPartUUID,
          document_page_order: position,
          dynamic:
            documentType === 'visual_bom' || documentType === 'project_tracker'
              ? false
              : true,
        },
      })
    }
  }

  const isDisabled =
    isLoading ||
    isCreatingView ||
    selectedPartUUID !== documentPage.assembly_group_id

  return (
    <div
      key={`placeholder-${position}`}
      className="h-full flex-1 flex flex-col"
      style={{ maxWidth: '49%' }}
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="secondary"
              disabled={isDisabled}
              onClick={(e) => {
                e.stopPropagation()
                addScreenshot(position)
              }}
              className="h-full w-full flex bg-gray-100 rounded border border-solid border-gray-200"
            >
              <div className="bg-primary-20 stroke-primary-50 p-2 rounded-full">
                <CameraPlusIcons className="h-5 w-5 stroke-primary-50" />
              </div>
            </Button>
          </TooltipTrigger>
          <TooltipContent className="bg-black text-white">
            <p>Take Screenshot</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}

const ImagePreview = ({
  imgData,
  actionButtons = [],
  actionButtonsPosition = 'left',
  style = { maxHeight: '50%', minHeight: 50 },
}: {
  imgData: {
    name: string
    download_url: string
  }
  actionButtons?: Array<ReactElement>
  actionButtonsPosition?: 'left' | 'right'
  style?: React.CSSProperties
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const imgRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.addEventListener('mouseenter', () => setIsHovering(true))
      imgRef.current.addEventListener('mouseleave', () => setIsHovering(false))
    }
  }, [imgRef])

  return (
    <div
      className="w-full flex flex-col items-center border rounded-md relative flex-1"
      ref={imgRef}
      style={style}
    >
      <a
        href=""
        download={`${imgData.name}.jpg`}
        onClick={(e) => {
          e.preventDefault()
        }}
        className="flex h-full justify-center"
      >
        <Image
          key={Date.now()}
          src={imgData.download_url}
          style={{
            height: '100%',
            width: 'auto',
            maxHeight: '100%',
          }}
        />
      </a>

      {actionButtons && actionButtons.length > 0 && isHovering && (
        <div
          className="absolute flex flex-col items-center space-x-0 justify-between absolute m-auto top-0 bottom-0 bg-primary-10 border border-primary-20 rounded-lg py-2"
          style={{
            minHeight: 95,
            zIndex: 1,
            left: actionButtonsPosition === 'left' ? -40 : 'auto',
            right: actionButtonsPosition === 'right' ? -40 : 'auto',
          }}
        >
          {actionButtons}
        </div>
      )}
    </div>
  )
}

const OperationPreview = ({
  documentPage,
  orderNumber,
  cadId,
  documentVersionId,
  documentType,
}: {
  documentPage: DocumentPage
  orderNumber: number
  cadId: string
  documentVersionId: string
  documentType: DocumentTypeChoices
}) => {
  return (
    <DocumentPagePreviewTemplate
      orderNumber={orderNumber}
      documentType={documentType}
      documentPage={documentPage}
    >
      <DocumentPagePreview
        cadId={cadId}
        documentVersionId={documentVersionId}
        documentPage={documentPage}
        documentType={documentType}
      />
    </DocumentPagePreviewTemplate>
  )
}

const DocumentPagePreviewTemplate = ({
  children,
  orderNumber,
  documentType,
  documentPage,
}: {
  children: ReactNode
  orderNumber: number
  documentType: DocumentTypeChoices
  documentPage: DocumentPage
}) => {
  const { data: cadData } = useCADQuery()
  const gltf = cadData.gltf
  const selectedPartUUID = useCADPageStore((state) =>
    state.explosionsToolbar && state.focusedPartUUID
      ? state.focusedPartUUID
      : state.selectedParts.length === 1
        ? state.selectedParts[0]
        : null,
  )
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const assemblyTree = useAssemblyTree((state) => state.tree)
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const getCadPageState = useCADPageStore((state) => state.getState)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      containerRef.current &&
      selectedPartUUID &&
      documentPage?.assembly_group_id &&
      selectedPartUUID === documentPage?.assembly_group_id
    ) {
      containerRef.current.scrollIntoView()
    }
  }, [selectedPartUUID, containerRef, documentPage])

  const previewTitle =
    documentType === 'work_instructions'
      ? `Operation ${orderNumber}`
      : documentType === 'project_tracker'
        ? `${documentPage.name}`
        : ''

  return (
    <div
      id={`op${orderNumber}`}
      className="flex flex-col space-y-2"
      ref={containerRef}
    >
      <div className="flex items-center justify-between">
        <h1 className="text-md font-semibold text-left text-sm">
          {previewTitle}
        </h1>
        <div>
          <DeleteOperationButton documentPage={documentPage} />
        </div>
      </div>
      <div
        className={cn(
          'border border-transparent hover:border-blue-500 cursor-pointer relative w-[264px] h-[204px] flex flex-col',
          {
            'border-blue-500':
              selectedPartUUID === documentPage.assembly_group_id,
          },
        )}
        style={{
          fontSize: 5,
          padding: 8,
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
        }}
        onClick={(e) => {
          e.stopPropagation()
          const { explosionsToolbar } = getCadPageState()
          if (explosionsToolbar) {
            setCadPageState({
              focusedPartUUID: documentPage.assembly_group_id,
            })
          }
          setCadPageState({
            selectedParts: [documentPage.assembly_group_id],
          })
          const node = getNode(documentPage.assembly_group_id)
          if (
            documentType === 'work_instructions' &&
            gltf &&
            assemblyTree &&
            node
          ) {
            gltf.viewPartAssembly(assemblyTree, node.instance)
          }
        }}
      >
        <div className="flex items-center justify-between mb-1">
          <h1 className="font-semibold">{previewTitle}</h1>
        </div>
        <div className="flex flex-col flex-1 justify-between">
          {documentType === 'work_instructions' && (
            <ToolsTablePreview documentPage={documentPage} />
          )}
          <div
            className="flex flex-row flex-1 auto-rows-max px-2 justify-between max-h-[108px]"
            style={{ overflowWrap: 'break-word' }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const DocumentPagePreview = ({
  cadId,
  documentVersionId,
  documentPage,
  documentType,
}: {
  cadId: string
  documentVersionId: string
  documentPage: DocumentPage
  documentType: DocumentTypeChoices
}) => {
  const notes = useListNotes(documentPage.id)
  const {
    isLoading,
    images,
    documentImages,
    viewData,
    refetchImages,
    refetchViews,
  } = useDocumentPreview({ documentPage, cadId, documentVersionId })
  const viewAttrs = useListViews(documentPage.id)
  const viewIds = viewAttrs?.map((attr) => attr.template_values?.viewId) || []
  const views = viewData?.filter((view) => viewIds.includes(view.id)) || []
  const viewUploadsInProgress = useAppStore(
    (state) => state.viewUploadsInProgress,
  )

  if (isLoading) return null

  const filteredImages = cleanAndFilterViews({
    images,
    documentImages,
    viewData: views as View[],
    assembly_group_id: documentPage.assembly_group_id,
    documentType,
  })

  return (
    <>
      {filteredImages.map((image, i) => {
        const index = documentType === 'project_tracker' ? i + 1 : i
        const isViewEmpty = image.type === 'empty'
        const isLoadingWorkInstructionsView = image.id && !image.download_url
        const isUploading = viewUploadsInProgress.find(
          (view) => view.viewId === image.id,
        )

        if (isLoadingWorkInstructionsView || isUploading) {
          return (
            <Skeleton
              key={`view-skeleton-${i}`}
              className="min-h-24 h-full w-full bg-gray-200 flex-1"
              style={{
                maxWidth: documentType === 'work_instructions' ? '49%' : '100%',
              }}
            />
          )
        }

        if (isViewEmpty) {
          return (
            <OperationPlaceholder
              key={index}
              position={index}
              documentPage={documentPage}
              documentType={documentType}
            />
          )
        }

        const note = notes.find(
          (n) => n.template_values.viewId === image.id,
        )?.template_values
        const isView = image.type === 'view'
        return (
          <div
            key={`${image.id}-${index}`}
            className={cn('flex flex-1 content-center', {
              'flex-col': documentType === 'work_instructions',
              'flex-row-reverse': documentType === 'project_tracker',
            })}
            style={{
              maxWidth: documentType === 'work_instructions' ? '49%' : '100%',
            }}
          >
            {image.download_url && (
              <ImagePreview
                imgData={image}
                style={{
                  maxHeight:
                    documentType === 'work_instructions' ? '50%' : '100%',
                  minHeight: 50,
                }}
                actionButtonsPosition={i % 2 === 0 ? 'left' : 'right'}
                actionButtons={
                  isView
                    ? [
                        <RetakeViewButton
                          key="retake-view-button"
                          view={image}
                          documentPage={documentPage}
                          documentType={documentType}
                        />,
                        <DeleteViewButton
                          key="delete-view-button"
                          view={image}
                          documentPageId={documentPage.id as string}
                          onDelete={() => refetchViews()}
                        />,
                      ]
                    : [
                        <DeleteImageButton
                          key="delete-image-button"
                          documentPage={documentPage}
                          image={image}
                          onDelete={() => refetchImages()}
                        />,
                      ]
                }
              />
            )}
            <Markup
              className="flex-1 whitespace-pre-line"
              style={{
                fontSize: 4.67,
                marginTop: 2.66,
                padding: '2.66px 4px',
              }}
              html={note?.content || ''}
            />
          </div>
        )
      })}
    </>
  )
}

const DeleteOperationButton = ({
  documentPage,
}: {
  documentPage: DocumentPage
}) => {
  const { isPending: isDeleting, mutate: deleteDocumentPage } =
    useDeleteDocumentPage()

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="text-gray-400 hover:text-red-500 px-2 py-0 bg-gray-100/50 mx-1 bg-white hover:bg-white rounded-full"
            disabled={isDeleting}
            onClick={(e) => {
              e.stopPropagation()
              if (documentPage.id) {
                deleteDocumentPage({ documentPageId: documentPage.id })
              }
            }}
          >
            {isDeleting ? <Loading /> : <TrashIcon className="w-4 h-4" />}
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Delete Operation {documentPage.order_number}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

const DeleteViewButton = ({
  view,
  documentPageId,
  onDelete,
}: {
  view: View
  documentPageId: string
  onDelete: () => void
}) => {
  const serviceWorker = useAppStore((state) => state.serviceWorker)
  const { isPending: isDeletingView, mutate: deleteView } =
    useDeleteViewMutation({
      onDelete: () => {
        onDelete()
        if (view.download_url) {
          serviceWorker?.deleteCache(view.download_url as string)
        }
      },
    })

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            disabled={isDeletingView}
            variant="ghost"
            className="text-red-500 hover:text-red-500 px-2 py-0 bg-gray-100/50 mx-1 bg-white hover:bg-white rounded-full h-8"
            onClick={(e) => {
              e.stopPropagation()
              if (view.id) deleteView({ viewId: view.id, documentPageId })
            }}
          >
            {isDeletingView ? <Loading /> : <TrashIcon className="w-4 h-4" />}
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Delete View</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

const RetakeViewButton = ({
  view,
  documentPage,
  documentType,
}: {
  view: View
  documentPage: DocumentPage
  documentType: DocumentTypeChoices
}) => {
  const { isPending: isDeletingView, mutate: deleteView } =
    useDeleteViewMutation({})
  const { mutate: createView, isPending: isCreatingView } =
    useViewCreateMutation({ documentType })
  const { data: cadData } = useCADQuery()

  const explosions = useCADPageStore((state) => state.explosions)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const colorMap = useCADPageStore((state) => state.colorMap)
  const transparentParts = useCADPageStore((state) => state.transparentParts)
  const cameraAspect = useCADPageStore((state) => state.cameraAspect)
  const cameraPosition = useCADPageStore((state) => state.cameraPosition)
  const cameraFov = useCADPageStore((state) => state.cameraFov)
  const cameraZoom = useCADPageStore((state) => state.cameraZoom)
  const cameraQuaternion = useCADPageStore((state) => state.cameraQuaternion)
  const cameraUp = useCADPageStore((state) => state.cameraUp)
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const renderMode = useCADPageStore((state) => state.renderMode)

  const getNode = useAssemblyTree(useShallow((state) => state.getNode))

  const node = getNode(documentPage.assembly_group_id)

  const isLoading = isDeletingView || isCreatingView

  const gltf = cadData.gltf
  const cadVersionNumber = cadData.version?.version_number || 1
  const projectName = cadData.project?.name || 'project'
  const partName =
    node?.display_name || node?.product || node?.instance || 'part'

  const viewName = `${projectName}-${partName}-${cadVersionNumber}`.replace(
    ' ',
    '-',
  )

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            disabled={isLoading}
            variant="ghost"
            className="text-blue-500 hover:text-blue-500 px-2 py-0 bg-gray-100/50 mx-1 bg-white hover:bg-white rounded-full"
            onClick={(e) => {
              e.stopPropagation()
              const image_data: ImageData = {
                cameraAspect,
                cameraPosition,
                cameraFov,
                cameraZoom,
                cameraQuaternion,
                cameraUp,
                explosions,
                hiddenParts,
                colorMap,
                transparentParts,
                isExplosionLinesEnabled,
                renderMode,
              }

              const docPageId = documentPage.id
              const docVersionId = documentPage.document_version
              const cadVersionId = view.cad_version

              const cadId = cadData.cad?.id
              const projectId = cadData.project?.id

              if (
                cadVersionId &&
                docPageId &&
                docVersionId &&
                gltf &&
                cadId &&
                projectId
              ) {
                if (view.id) deleteView({ viewId: view.id })
                createView({
                  cadId,
                  cadVersionId,
                  docVersionId,
                  documentPageId: docPageId,
                  assemblyPartUUID: view.assembly_group_id,
                  projectId,
                  values: {
                    download_url: null,
                    source_type:
                      documentType === 'visual_bom'
                        ? 'isometric'
                        : 'screenshot',
                    name: viewName,
                    image_data,
                    cad_version: view.cad_version,
                    assembly_group_id: view.assembly_group_id,
                    document_page_order: view.document_page_order,
                    dynamic:
                      documentType === 'visual_bom' ||
                      documentType === 'project_tracker'
                        ? false
                        : true,
                  },
                })
              }
            }}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <CameraRetakeIcon className="w-5 h-5 stroke-primary" />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Retake Screenshot</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

const DeleteImageButton = ({
  documentPage,
  image,
  onDelete,
}: {
  documentPage: DocumentPage
  image: {
    download_url: string | null
    upload_url: string | null
    name: string
    id?: string | undefined
  }
  onDelete: () => void
}) => {
  const { isPending: isDeletingImage, mutate: deleteImage } = useDeleteImages({
    onDelete: () => {
      onDelete()
    },
  })

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            disabled={isDeletingImage}
            variant="ghost"
            className="text-red-500 hover:text-red-500 px-2 py-0 h-6 bg-gray-100/50 mx-1 bg-white hover:bg-white rounded-full h-8"
            onClick={() => {
              if (image.id) deleteImage({ imageId: image.id, documentPage })
            }}
          >
            {isDeletingImage ? <Loading /> : <TrashIcon className="w-4 h-4" />}
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Delete Image</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
