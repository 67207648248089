import { useEffect, useMemo, useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { DocumentPage } from '@/lib/api/client'
import { useAssemblyTree } from '@/state'
import {
  CustomColumn,
  CustomColumnData,
  ToolsColumn,
  ToolsRow,
  useListPartsCustomColumns,
  useListPartsCustomColumnsData,
  useListPartsCustomRows,
  useListToolsColumns,
  useListToolsRows,
} from '@/services/hooks/template_attributes'

type Column = { key: string; display: string }
export const ToolsTablePreview = ({
  documentPage,
}: {
  documentPage: DocumentPage
}) => {
  const maxPartsInOperation = 4
  const partsInOperation = useAssemblyTree((state) =>
    state.getBillOfMaterialsForPart(documentPage.assembly_group_id),
  )
  const partsCustomColumns = useListPartsCustomColumns(documentPage.id)
  const partsCustomColumnsData = useListPartsCustomColumnsData(documentPage.id)
  const partsCustomRows = useListPartsCustomRows(documentPage.id)
  const toolsTemplateColumns = useListToolsColumns(documentPage.id)
  const toolsTemplateRows = useListToolsRows(documentPage.id)

  const INITIAL_PARTS_COLUMNS: Array<Column> = useMemo(
    () => [
      { key: 'partName', display: 'Parts' },
      { key: 'quantity', display: 'Qty' },
    ],
    [],
  )

  const INITIAL_TOOLS_COLUMNS: Array<Column> = useMemo(
    () => [{ key: 'toolName', display: 'Tools' }],
    [],
  )

  const INITIAL_ROWS = useMemo<Array<ToolsRow>>(
    () => [
      {
        toolName: '',
      },
    ],
    [],
  )

  const [partsColumns, setPartsColumns] = useState<Array<Column>>(() => [
    ...INITIAL_PARTS_COLUMNS,
  ])
  const [partsRows, setPartsRows] = useState(() => [...partsInOperation])

  const [toolsColumns, setToolsColumns] = useState<Array<Column>>(() => [
    ...INITIAL_TOOLS_COLUMNS,
  ])
  const [toolsRows, setToolsRows] = useState<Array<ToolsRow>>(() => [
    ...INITIAL_ROWS,
  ])

  useEffect(() => {
    const customColumns =
      (partsCustomColumns?.template_values as unknown as Array<CustomColumn>) ||
      []

    setPartsColumns((prevColumns) => [
      ...prevColumns,
      ...(customColumns.map((column) =>
        Object.keys(column).reduce((accum, accessorKey) => {
          accum['key'] = accessorKey
          accum['display'] = column[accessorKey]
          return accum
        }, {}),
      ) as Array<Column>),
    ])

    return () => {
      setPartsColumns([...INITIAL_PARTS_COLUMNS])
    }
  }, [partsCustomColumns])

  useEffect(() => {
    const partsCustomTableData =
      (partsCustomColumnsData?.template_values as unknown as Array<CustomColumnData>) ||
      []
    const rows: Array<CustomColumnData> = partsCustomRows.map(
      (row) => row.template_values,
    )

    setPartsRows((prevRows) => [
      ...prevRows.map((row, i) => ({
        ...row,
        ...((partsCustomTableData[i] as any) || {}),
      })),
      ...rows,
    ])

    return () => {
      setPartsRows([...partsInOperation])
    }
  }, [partsCustomColumns, partsCustomRows])

  useEffect(() => {
    const columns =
      (toolsTemplateColumns?.template_values as unknown as Array<ToolsColumn>) ||
      []

    setToolsColumns((prevColumns) => [
      ...prevColumns,
      ...(columns.map((column) =>
        Object.keys(column).reduce((accum, accessorKey) => {
          accum['key'] = accessorKey
          accum['display'] = column[accessorKey]
          return accum
        }, {}),
      ) as Array<Column>),
    ])

    return () => {
      setPartsColumns([...INITIAL_PARTS_COLUMNS])
    }
  }, [toolsTemplateColumns])

  useEffect(() => {
    const rows: Array<ToolsRow> = toolsTemplateRows.map(
      (row) => row.template_values,
    )

    setToolsRows(() => [...(rows as any)])

    return () => {
      setToolsRows([...INITIAL_ROWS])
    }
  }, [partsCustomColumns, partsCustomRows])

  return (
    <div className="flex justify-between">
      <div className="mb-2 flex-1" style={{ maxWidth: '68%' }}>
        <Table className="border text-xs w-full table-fixed">
          <TableHeader className="bg-muted/80">
            <TableRow style={{ lineHeight: '5px', fontSize: 4 }}>
              {partsColumns.map((column) => (
                <TableHead key={column.key} className="h-0">
                  {column.display}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {partsRows.slice(0, maxPartsInOperation).map((row, i) => (
              <TableRow
                key={i}
                className="h-0"
                style={{ fontSize: 4, lineHeight: '5px' }}
              >
                {partsColumns.map((column, j) => (
                  <TableCell key={j} className="h-0 p-0 px-2 h-2">
                    {row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="mb-2 flex-1" style={{ maxWidth: '30%' }}>
        <Table className="border text-xs w-full table-fixed">
          <TableHeader className="bg-muted/80">
            <TableRow style={{ lineHeight: '5px', fontSize: 4 }}>
              {toolsColumns.map((column) => (
                <TableHead key={column.key} className="h-0">
                  {column.display}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {toolsRows.map((row, i) => (
              <TableRow
                key={i}
                className="h-0"
                style={{ fontSize: 4, lineHeight: '5px' }}
              >
                {toolsColumns.map((column, j) => (
                  <TableCell key={j} className="h-0 p-0 px-2 h-2">
                    {row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
