import QUERY_KEYS from '../queryKeys'
import { listCadViews } from '@/lib/api/client'
import { useQuery } from '@tanstack/react-query'
import { VIEWS_CACHE_TIME } from '..'

export const useListCadViews = ({ cadId }: { cadId?: string | null }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CAD_VIEWS, { cadId }],
    queryFn: async () => {
      if (!cadId) return
      return listCadViews(cadId)
    },
    enabled: Boolean(cadId),
    staleTime: VIEWS_CACHE_TIME,
  })
}
