import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { Files } from '@/components/ui/file'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/ui/loading'
import { UploadIcon } from '@/components/icons/UploadIcon'

import type { DocumentPage } from '@/lib/api/client'

import { useCreateImage } from '@/services/queries/images'

interface UploadPhotoButtonProps {
  documentPage: DocumentPage
}

type FormInputs = {
  files: File[]
}

const formSchema = z.object({
  files: z.array(z.any()).min(1, {
    message: 'Upload at least 1 image.',
  }),
})

export const UploadPhotoButton = ({ documentPage }: UploadPhotoButtonProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isPending, mutate: createImage } = useCreateImage({
    onSuccess: () => {
      setIsOpen(false)
    },
  })
  const form = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  })

  const onSubmit = ({ files }: FormInputs) => {
    createImage({
      documentPage,
      file: files[0],
    })
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        disabled={isPending}
        className="text-primary-50 flex justify-center items-center space-x-2"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <UploadIcon className="h-3.5 w-3.5" />
        <span className="text-sm">Upload image</span>
      </DialogTrigger>
      <DialogContent className="min-h-44">
        <DialogHeader>
          <DialogTitle>Upload new image</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <Files
              placeholder="Upload a PNG, JPG or GIF up to 5 MB."
              onChange={(files) => {
                form.setValue('files', files)
              }}
              validateFile={(file) => {
                form.setError('files', {})
                const validExtentions = ['png', 'jpg', 'jpeg', 'gif']
                const tok = file.name.toLowerCase().split('.')
                const ext = tok[tok.length - 1]

                const hasValidFileExtention = validExtentions.includes(ext)
                if (!hasValidFileExtention) {
                  form.setError('files', {
                    type: 'extention',
                    message: `Only files with the following extensions are allowed: ${validExtentions.join(
                      ', ',
                    )}.`,
                  })
                }

                const maxFileSize = 5242880 // 5 MB
                const hasValidFileSize = file.size <= maxFileSize

                if (!hasValidFileSize) {
                  form.setError('files', {
                    type: 'max',
                    message: 'Image must be smaller than 5MB.',
                  })
                }

                const isValid = hasValidFileExtention && hasValidFileExtention

                return isValid
              }}
              error={form.formState.errors.files?.message}
            />
            <Button type="submit" disabled={isPending}>
              {isPending ? (
                <div className="flex items-center space-x-2">
                  <span>Uploading</span>
                  <Loading />
                </div>
              ) : (
                'Upload image'
              )}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
