import { useEffect, useMemo, useState } from 'react'
import { useMutationState } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  CollapsibleTriangleIcon,
} from '@/components/ui/collapsible'
import { Button } from '@/components/ui/button'
import { BookIcon } from '@/components/icons/BookIcon'
import { DocumentTextIcon } from '@/components/icons/DocumentTextIcon'
import { ListBulletIcon } from '@/components/icons/ListBulletIcon'
import { ClockIcon } from '@/components/icons/ClockIcon'
import { PartIcon } from '@/components/icons/PartIcon'
import {
  useListPartsCustomRows,
  useListToolsRows,
} from '@/services/hooks/template_attributes'
import { MUTATION_KEYS as CAD_VERSIONS_MUTATION_KEYS } from '@/services/queries/cad_versions'
import { ToolIcon } from '@/components/icons/ToolIcon'
import { DocumentPage } from '@/lib/api/client'
import { BillOfMaterials, useAssemblyTree, useDocumentState } from '@/state'
import { ProjectDocumentTitle } from '@/components/core/ProjectDocumentTitle'
import { cn } from '@/utils'

export const WorkInstructionsTOC = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const currentPageInView = useDocumentState(
    useShallow((state) => state.currentPageInView),
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  const project = data?.project

  if (!project) {
    return null
  }

  const navigateToPage = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    selector: string,
  ) => {
    e.preventDefault()
    document.querySelector(selector)?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  const operations = data.documentPages

  return (
    <div className="h-full overflow-auto pb-5 text-sm">
      <div className="my-4 px-10">
        <ProjectDocumentTitle />
      </div>
      <div className="flex flex-col">
        <a
          href="#cover"
          className={cn(
            'flex items-center space-x-2 px-10 py-1.5 font-medium hover:bg-indigo-50 hover:text-primary-50',
            {
              'bg-indigo-100': currentPageInView === 'cover',
            },
          )}
          onClick={(e) => navigateToPage(e, '#cover')}
        >
          <BookIcon className="w-4 h-4" />
          <span>Cover</span>
        </a>
        <a
          href="#history"
          className={cn(
            'flex items-center space-x-2 px-10 py-1.5 font-medium hover:bg-indigo-50 hover:text-primary-50',
            {
              'bg-indigo-100': currentPageInView === 'history',
            },
          )}
          onClick={(e) => navigateToPage(e, '#history')}
        >
          <ClockIcon className="w-4 h-4" />
          <span>Version History</span>
        </a>
        <a
          href="#bom"
          className={cn(
            'flex items-center space-x-2 px-10 py-1.5 font-medium hover:bg-indigo-50 hover:text-primary-50',
            {
              'bg-indigo-100': new RegExp(/^parts-bom--\d+$/).test(
                currentPageInView,
              ),
            },
          )}
          onClick={(e) => navigateToPage(e, '#bom')}
        >
          <ListBulletIcon className="w-4 h-4" />
          <span>Bill of Materials</span>
        </a>
        {operations.map((operation, i) => {
          return <OperationItem operation={operation} key={`operation_${i}`} />
        })}
      </div>
    </div>
  )
}

const OperationItem = ({ operation }: { operation: DocumentPage }) => {
  const currentPageInView = useDocumentState(
    useShallow((state) => state.currentPageInView),
  )

  const navigateToOperation = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    document.querySelector(`#op-${operation.id}`)?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return (
    <Collapsible>
      <a
        href={`#op-${operation.id}`}
        className={cn(
          'flex items-center space-x-2 font-medium hover:bg-indigo-50 hover:text-primary-50',
          {
            'bg-indigo-100': currentPageInView === `op-${operation.id}`,
          },
        )}
        onClick={navigateToOperation}
      >
        <CollapsibleTrigger>
          <Button variant="ghost" className="px-2">
            <CollapsibleTriangleIcon className="w-5 h-5 text-indigo-400" />
          </Button>
        </CollapsibleTrigger>
        <DocumentTextIcon className="w-4 h-4" />
        <span>Operation {operation.order_number}</span>
      </a>
      <CollapsibleContent>
        <div className="pl-6">
          <PartsList partId={operation.assembly_group_id} />
        </div>
        <div className="pl-6 mt-4">
          <ToolsList documentPageId={operation.id as string} />
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

const CUSTOM_PARTS_MUTATION_KEYS = [
  CAD_VERSIONS_MUTATION_KEYS.UPDATE_CAD_VERSION,
]
const PartsList = ({ partId }: { partId: string }) => {
  const getBillOfMaterialsForPart = useAssemblyTree(
    useShallow((state) => state.getBillOfMaterialsForPart),
  )
  const [bom, setBom] = useState<BillOfMaterials>([])
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))

  const documentPageId = useMemo(
    () => getNode(partId)?.document_page_id || '',
    [partId],
  )

  const mutationData = useMutationState({
    filters: {
      status: 'success',
      predicate: (mutation) => {
        const [mutationKey] = mutation.options.mutationKey || []

        if (!mutationKey) return false

        return CUSTOM_PARTS_MUTATION_KEYS.includes(mutationKey as any)
      },
    },
  })

  useEffect(() => {
    setBom(getBillOfMaterialsForPart(partId))
  }, [partId, mutationData, getBillOfMaterialsForPart])

  const partsCustomRows = useListPartsCustomRows(documentPageId)

  const customParts = useMemo(
    () =>
      Array.from(
        new Set(partsCustomRows.map((part) => part.template_values.partName)),
      ).map((partName) => ({ partName })),
    [partsCustomRows],
  )

  return (
    <Collapsible defaultOpen={true}>
      <div className="flex items-center space-x-2">
        <CollapsibleTrigger>
          <CollapsibleTriangleIcon className="w-5 h-5 text-indigo-400" />
        </CollapsibleTrigger>
        <PartIcon className="w-4 h-4" />
        <span className="font-semibold">Parts</span>
      </div>
      <CollapsibleContent>
        <div className="pl-10 mt-2">
          {[...bom, ...customParts].map((part, i) => (
            <div key={`part_${i}`} className="flex items-center space-x-2">
              <span>{part.partName}</span>
            </div>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

const ToolsList = ({ documentPageId }: { documentPageId: string }) => {
  const toolsRows = useListToolsRows(documentPageId)

  const toolsList = useMemo(
    () =>
      Array.from(
        new Set(
          toolsRows.map(
            (toolTemplate) => toolTemplate.template_values.toolName,
          ),
        ),
      ).map((toolName) => ({ toolName })),
    [toolsRows],
  )

  return toolsList.length ? (
    <Collapsible defaultOpen={true}>
      <div className="flex items-center space-x-2">
        <CollapsibleTrigger>
          <CollapsibleTriangleIcon className="w-5 h-5 text-indigo-400" />
        </CollapsibleTrigger>
        <ToolIcon className="w-4 h-4" />
        <span className="font-semibold">Tools</span>
      </div>
      <CollapsibleContent>
        <div className="pl-10 mt-2">
          {toolsList.map((tool, i) => (
            <div key={`tool${i}`} className="flex items-center space-x-2">
              <span>{tool.toolName}</span>
            </div>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  ) : null
}
