import { useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react'
import { ColumnDef } from '@tanstack/react-table'

import { TableCell } from '@/components/ui/table'
import {
  ToolsColumn,
  ToolsRow,
  useCreateToolsColumn,
  useCreateToolsRow,
  useDeleteToolsRow,
  useUpdateToolsColumns,
  useUpdateToolsRow,
  useListToolsColumns,
  useListToolsRows,
  useToolsTablePosition,
  useDeleteToolsColumns,
  useUpdateElementPosition,
  useCreateElementPosition,
} from '@/services/hooks/template_attributes'
import type { DocumentPage } from '@/lib/api/client'
import { EditableTable, createNewColumn } from './EditableTable'
import { useDocumentState } from '@/state/document'

export type ToolsTableData = {
  toolName: string
  [accessor: string]: any
}

type ToolsTableProps = {
  assemblyGroupId: string
  documentPage: DocumentPage
  isReadOnly?: boolean
}
export const ToolsTable = ({
  assemblyGroupId,
  documentPage,
  isReadOnly,
}: ToolsTableProps) => {
  const initialColumnsDef = useMemo<ColumnDef<ToolsTableData>[]>(
    () => [
      {
        accessorKey: 'toolName',
        header: 'Tools',
        cell: ({ getValue }) => (
          <TableCell className="flex flex-1 items-center border-x border-solid border-gray-200 border-collapse text-xs py-1 min-h-6">
            <span className="px-1">{getValue<string>()}</span>
          </TableCell>
        ),
      },
    ],
    [],
  )
  const initialTableData = useMemo<Array<ToolsTableData>>(
    () => [
      {
        toolName: '',
      },
    ],
    [],
  )
  const [tableData, setTableData] = useState<ToolsTableData[]>(() => [
    ...initialTableData,
  ])
  const [columnsDef, setColumnsDef] = useState<ColumnDef<ToolsTableData>[]>(
    () => [...initialColumnsDef],
  )
  const [columnsData, setColumnsData] = useState(
    initialColumnsDef.reduce((acc, column) => {
      acc[(column as any).accessorKey] = column.header
      return acc
    }, {}),
  )

  const toolsColumns = useListToolsColumns(documentPage.id)
  const toolsRows = useListToolsRows(documentPage.id)
  const tablePosition = useToolsTablePosition(documentPage.id)

  const { mutateAsync: createToolsColumn } = useCreateToolsColumn()
  const { mutateAsync: updateToolsColumns } = useUpdateToolsColumns()
  const { mutateAsync: deleteToolsColumns, isPending: isDeletingToolsColumns } =
    useDeleteToolsColumns()
  const { mutateAsync: createToolsRow } = useCreateToolsRow()
  const { mutateAsync: updateToolsRow } = useUpdateToolsRow()
  const { mutateAsync: deleteToolsRow, isPending: isDeletingToolsRow } =
    useDeleteToolsRow()
  const { mutateAsync: updateTablePosition, isPending: isUpdatingPosition } =
    useUpdateElementPosition()
  const { mutateAsync: createTablePosition, isPending: isCreatingPosition } =
    useCreateElementPosition()

  const isDeleting = useMemo(
    () =>
      isDeletingToolsColumns ||
      isDeletingToolsRow ||
      isUpdatingPosition ||
      isCreatingPosition,
    [
      isDeletingToolsColumns,
      isDeletingToolsRow,
      isUpdatingPosition,
      isCreatingPosition,
    ],
  )

  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )

  const draggableId = useMemo(() => 'tools-table', [])
  const tableType = useMemo(() => 'tools-table', [])

  const selectTable = useCallback(() => {
    setSelectedElement({
      documentPageId: documentPage.id as string,
      id: draggableId,
    })
  }, [documentPage.id, draggableId, setSelectedElement])

  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPage.id &&
      selectedElement?.id === draggableId,
    [selectedElement, documentPage.id, draggableId],
  )

  const deleteTableHandler = useCallback(async () => {
    if (tablePosition) {
      const cleanPayload = {
        ...tablePosition.template_values,
      }

      delete cleanPayload.position
      cleanPayload.removed = true
      await updateTablePosition({
        templateAttributeId: tablePosition.id as string,
        payload: cleanPayload,
      })
    } else {
      await createTablePosition({
        documentPageId: documentPage.id as string,
        payload: {
          movableElementId: draggableId,
          removed: true,
          type: tableType,
        },
      })
    }

    if (toolsColumns) deleteToolsColumns(toolsColumns.id as string)
    if (toolsRows && toolsRows.length) {
      Promise.all(toolsRows.map((row) => deleteToolsRow(row.id as string)))
    }
  }, [
    tablePosition,
    updateTablePosition,
    createTablePosition,
    documentPage.id,
    draggableId,
    tableType,
    toolsColumns,
    toolsRows,
    deleteToolsColumns,
    deleteToolsRow,
  ])

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteTableHandler()
      }
    },
    [deleteTableHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  useEffect(() => {
    if (!tableData.length) {
      setTableData(() => [...initialTableData])
    }
  }, [tableData])

  useEffect(() => {
    const columns =
      toolsColumns?.template_values as unknown as Array<ToolsColumn>

    if (columns) {
      setColumnsData((prev) => ({
        ...prev,
        ...columns.reduce(
          (acc, column) => ({
            ...acc,
            ...column,
          }),
          {},
        ),
      }))

      columns.forEach((column) => {
        Object.keys(column).forEach((accessorKey) => {
          const columnDef = createNewColumn(
            accessorKey,
            onCellChange,
            selectTable,
            assemblyGroupId,
          ) as ColumnDef<ToolsTableData>
          setColumnsDef((prev) => [...prev, columnDef])
        })
      })
    }

    return () => {
      setColumnsDef(() => [...initialColumnsDef])
    }
  }, [toolsColumns])

  useEffect(() => {
    const rows: Array<ToolsRow> = toolsRows.map((row) => row.template_values)

    if (rows.length) {
      setTableData(() => [...(rows as Array<ToolsTableData>)])
    }

    return () => {
      setTableData(() => [...initialTableData])
    }
  }, [toolsRows])

  const onCellChange = useCallback(
    (rowIndex, columnId, value) => {
      setTableData((prev) =>
        prev.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row,
        ),
      )
    },
    [setTableData],
  )

  const onHeaderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, accessorKey: string) => {
      const target = event.target
      const value = target.value

      setColumnsData((prev) => ({
        ...prev,
        [accessorKey]: value,
      }))
    },
    [],
  )

  const saveToolsData = useCallback(
    ({ rowIndex }: { rowIndex: number }) => {
      const toolsRowTemplate = toolsRows?.at(rowIndex)
      const row = tableData[rowIndex]

      if (!row) return

      if (toolsRowTemplate) {
        updateToolsRow({
          templateAttributeId: toolsRowTemplate.id as string,
          toolsRow: row,
        })
      } else {
        createToolsRow({
          documentPageId: documentPage.id as string,
          toolsRow: row,
        })
      }
    },
    [toolsRows, tableData, documentPage.id, updateToolsRow, createToolsRow],
  )

  const saveToolsColumns = useCallback(() => {
    const filteredColumns = columnsDef
      .filter((column) =>
        ((column as any).accessorKey as string).includes('custom-column'),
      )
      .map((column) => {
        const accessorKey = (column as any).accessorKey
        return { [accessorKey]: columnsData[accessorKey] }
      })

    if (!toolsColumns) {
      createToolsColumn({
        documentPageId: documentPage.id as string,
        column: filteredColumns[0],
      })
    } else {
      updateToolsColumns({
        templateAttributeId: toolsColumns.id as string,
        columns: filteredColumns,
      })
    }
  }, [
    columnsDef,
    columnsData,
    toolsColumns,
    documentPage.id,
    createToolsColumn,
    updateToolsColumns,
  ])

  const addNewColumn = useCallback(
    ({
      prevAccessorKey,
      newColumn,
    }: {
      prevAccessorKey: string
      newColumn: ColumnDef<any>
    }) => {
      const accessorKey = (newColumn as { accessorKey: string }).accessorKey
      const columnBefore =
        columnsDef.findIndex(
          (column) => (column as any).accessorKey === prevAccessorKey,
        ) || columnsDef.length - 1

      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore + 1),
        newColumn as ColumnDef<ToolsTableData>,
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.map((row) => ({
          ...row,
          [accessorKey]: '',
        })),
      )
    },
    [columnsDef, setColumnsDef, setTableData],
  )

  const removeColumn = useCallback(
    (accessorKey: string) => {
      const columnBefore = columnsDef.findIndex(
        (column) => (column as any).accessorKey === accessorKey,
      )

      if (columnBefore === -1) return
      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore),
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.filter((row) => Object.keys(row).includes(accessorKey)),
      )
      setColumnsData((prev) => {
        const newColumns = { ...prev }
        delete newColumns[accessorKey]
        return newColumns
      })

      const filteredToolsRows = tableData
        .map((row) => {
          return Object.keys(row).reduce((acc, currAccessorKey) => {
            if ((currAccessorKey as string) !== accessorKey) {
              acc[currAccessorKey] = row[currAccessorKey]
            }

            return acc
          }, {})
        })
        .filter((row) => Object.keys(row).length)

      const filteredColumns = columnsDef
        .filter(
          (column) =>
            ((column as any).accessorKey as string).includes('custom-column') &&
            ((column as any).accessorKey as string) !== accessorKey,
        )
        .map((column) => {
          const accessorKey = (column as any).accessorKey
          return { [accessorKey]: columnsData[accessorKey] }
        })

      updateToolsColumns({
        templateAttributeId: toolsColumns?.id as string,
        columns: filteredColumns,
      })

      Promise.all(
        toolsRows.map((row, i) =>
          updateToolsRow({
            templateAttributeId: row.id as string,
            toolsRow: filteredToolsRows[i],
          }),
        ),
      )
    },
    [
      columnsDef,
      columnsData,
      toolsRows,
      toolsColumns?.id,
      tableData,
      updateToolsRow,
      setColumnsDef,
      updateToolsColumns,
    ],
  )

  const removeRow = useCallback(
    (rowIndex) => {
      setTableData((prev) => prev.filter((_, index) => index !== rowIndex))
      const toolsRowTemplate = toolsRows?.at(rowIndex)

      if (toolsRowTemplate) {
        deleteToolsRow(toolsRowTemplate.id as string)
      }
    },
    [toolsRows, deleteToolsRow],
  )

  const addNewRow = useCallback((newRow: any) => {
    setTableData((prev) => [...prev, newRow])
  }, [])

  useEffect(() => {
    if (tablePosition?.template_values.columnsSize) {
      setColumnsDef((prevColumns) =>
        prevColumns.map((column) => {
          const accessor = (column as any).accessorKey
          const size = (tablePosition?.template_values.columnsSize as any)[
            accessor
          ]
          if (size) {
            return {
              ...column,
              size,
            }
          }
          return column
        }),
      )
    }
  }, [tablePosition?.template_values.columnsSize])

  const isToolsTableEmpty = tableData.every((row) => !row.toolName)

  if (isReadOnly && isToolsTableEmpty) {
    return null
  }

  return (
    !tablePosition?.template_values.removed && (
      <EditableTable
        saveTableData={saveToolsData}
        onCellChange={onCellChange}
        removeRow={removeRow}
        addRow={addNewRow}
        onHeaderChange={onHeaderChange}
        saveCustomColumns={saveToolsColumns}
        removeColumn={removeColumn}
        addColumn={addNewColumn}
        documentPageId={documentPage?.id as string}
        assemblyGroupId={assemblyGroupId}
        tableData={tableData}
        defaultRows={0}
        defaultColumns={initialColumnsDef.length}
        columnsDef={columnsDef as any}
        columnsData={columnsData}
        positionData={tablePosition?.template_values.position}
        isDeleting={isDeleting}
        isReadOnly={isReadOnly}
        id="tools-table"
        type="tools-table"
        maxRows={4}
        maxColumns={2}
      />
    )
  )
}
