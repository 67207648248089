import { Html } from '@react-three/drei'
import { useAssemblyTree } from '@/state'
import { useCADPageStore } from '../state'
import { RawAssemblyTree, ASMTreeNode } from '@/state'
import { DocumentTypeChoices } from '@/lib/api/client'

const getNodeOrder = (
  tree: RawAssemblyTree | null,
  node: ASMTreeNode | undefined,
) => {
  if (tree && node) {
    const root = tree.nodes.find((n) => n.uuid === tree.root)
    if (root) {
      return root.children.indexOf(node.uuid) + 1
    }
  }
}

export const CADPartLabel = ({
  documentType,
}: {
  documentType?: DocumentTypeChoices
}) => {
  const highlightedPartUUID = useCADPageStore(
    (state) => state.highlightedPartUUID,
  )
  const tree = useAssemblyTree((state) => state.tree)
  const node = tree?.nodes.find((node) => node.uuid === highlightedPartUUID)
  const partName = node?.display_name || node?.product || node?.instance
  const order = getNodeOrder(tree, node)
  const selectedParts = useCADPageStore((state) => state.selectedParts)
  const selectedNode = tree?.nodes.find(
    (node) => node.uuid === selectedParts[0],
  )
  const selectedPartName =
    selectedNode?.display_name ||
    selectedNode?.product ||
    selectedNode?.instance

  if (!documentType) return null

  if (documentType === 'work_instructions' && partName) {
    return (
      <CADPartLabelTemplate>
        {order !== undefined && <span>{order}.</span>}
        <span>{partName}</span>
      </CADPartLabelTemplate>
    )
  } else if (documentType === 'project_tracker' && selectedPartName) {
    return (
      <CADPartLabelTemplate>
        {<span>Selected: {selectedPartName}</span>}
      </CADPartLabelTemplate>
    )
  } else if (documentType === 'project_tracker' && partName) {
    return (
      <CADPartLabelTemplate>{<span>{partName}</span>}</CADPartLabelTemplate>
    )
  }

  return null
}

const CADPartLabelTemplate = ({ children }: { children: React.ReactNode }) => (
  <Html
    wrapperClass="transform-none w-full h-0 !z-20 text-center"
    className="right-0"
    style={{
      right: 0,
      left: 0,
      top: '60px',
      transform: 'none !important',
    }}
  >
    <div className="flex justify-center select-none">
      <h1 className="text-sm text-white bg-gray-900 rounded px-2 py-1 flex items-center space-x-2">
        {children}
      </h1>
    </div>
  </Html>
)
