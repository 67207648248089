import { create } from 'zustand'
import { ViewWorker } from '@/lib/workers/viewWorker'
import { ServiceWorker } from '@/lib/workers/serviceWorker'

interface CADPageState {
  viewWorker?: ViewWorker | null
  serviceWorker?: ServiceWorker | null
  viewUploadsInProgress: {
    viewId: string
    assemblyGroupId: string
    documentOrder?: number | null
  }[]
  isViewUploaded: boolean
  webworkerDebugger: {
    canvas: HTMLCanvasElement | null
    images: string[]
  }
  initViewWorker: () => void
  initServiceWorker: () => void
  setViewUploadsInProgress: (
    views: {
      viewId: string
      assemblyGroupId: string
      documentOrder?: number | null
    }[],
  ) => void
  setViewUploadsCompleted: (viewIds: string[]) => void
  addWebworkerDebuggerImage: (image: string) => void
}

export const useAppStore = create<CADPageState>((set) => ({
  viewWorker: null,
  viewUploadsInProgress: [],
  isViewUploaded: false,
  webworkerDebugger: {
    canvas: null,
    images: [],
  },

  addWebworkerDebuggerImage: (image: string) =>
    set((state) => {
      return {
        webworkerDebugger: {
          ...state.webworkerDebugger,
          images: [...state.webworkerDebugger.images, image],
        },
      }
    }),

  initViewWorker: () => {
    set((state) => {
      if (state.viewWorker) {
        return {}
      }
      const viewWorker = new ViewWorker({
        onUploadsInProgress: state.setViewUploadsInProgress,
        onUploadsCompleted: state.setViewUploadsCompleted,
        onScreenshot: state.addWebworkerDebuggerImage,
      })
      return {
        viewWorker,
        webworkerDebugger: { canvas: viewWorker.canvas, images: [] },
      }
    })
  },

  initServiceWorker: () => {
    set((state) => {
      if (state.serviceWorker) {
        return {}
      }
      const serviceWorker = new ServiceWorker()
      return {
        serviceWorker,
      }
    })
  },

  setViewUploadsInProgress: (
    views: {
      viewId: string
      assemblyGroupId: string
      documentOrder?: number | null
    }[],
  ) => {
    const seenViews: {
      [k: string]: {
        viewId: string
        assemblyGroupId: string
        documentOrder?: number | null
      }
    } = {}
    views.forEach((view) => {
      seenViews[view.viewId] = view
    })

    const uniqueViews = Object.values(seenViews)

    set({
      viewUploadsInProgress: uniqueViews,
      isViewUploaded: false,
    })
  },
  setViewUploadsCompleted: (viewIds: string[]) =>
    set((state) => {
      return {
        isViewUploaded: true,
        viewUploadsInProgress: state.viewUploadsInProgress.filter(
          ({ viewId }) => !viewIds.includes(viewId),
        ),
      }
    }),
}))
