import { useMemo } from 'react'
import dayjs from 'dayjs'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { DocumentTemplate } from './DocumentTemplate'
import { toChunks } from '@/utils'

export const MAX_VERSIONS_PER_PAGE = 12
export const VersionHistoryDocument = ({
  versionNumber,
  lastUpdated,
}: {
  versionNumber: number
  lastUpdated: string
}) => {
  const { isLoading, data } = useDocumentPageQuery()
  const documentVersions: Array<any> = useMemo(
    () => toChunks(data?.documentVersions || [], MAX_VERSIONS_PER_PAGE),
    [data?.documentVersions],
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>Document not found</div>
  }

  return documentVersions.map((chunk, i) => (
    <DocumentTemplate
      id="history"
      title="Version History"
      lastUpdated={lastUpdated}
      versionNumber={versionNumber}
      key={i}
      order={2}
    >
      <div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Version</TableHead>
              <TableHead>Last Updated</TableHead>
              <TableHead>Notes</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {chunk.map((item, i) => (
              <TableRow key={i}>
                <TableCell className="py-1 px-2.5">
                  V{item.version_number || 1}
                </TableCell>
                <TableCell className="py-1 px-2.5">
                  {dayjs(
                    item.modified || item.created || new Date().toISOString(),
                  ).format('MMM DD, YYYY hh:mm a')}
                </TableCell>
                <TableCell className="py-1 px-2.5">{item.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </DocumentTemplate>
  ))
}
