import { forwardRef } from 'react'
import { Editor, Range } from '@tiptap/react'
import { Spinner } from '@/components/ui/spinner'
import { useToast } from '@/components/ui/use-toast'
import { useAssemblyTree } from '@/state'
import { useListTemplateAttributes } from '@/services/queries/template_attributes'

import { CommandSelector } from './CommandSelector'
import { useCommand } from './hooks/useCommand'
import { useCommandItems } from './hooks/useCommandItems'
import type { EditorAttributes } from '../../lib/types'

interface SlashCommandProps {
  query: string
  editor: Editor
  range: Range
}

export const CommandList = forwardRef<any, SlashCommandProps>(
  ({ query, editor, range }, ref) => {
    const editorAttributes = editor.options.editorProps
      .attributes as EditorAttributes

    const documentPageId = editorAttributes?.documentPageId

    const assemblyGroupId = editorAttributes?.assemblyGroupId

    const command = useCommand({ query })

    const { toast } = useToast()

    const { isLoading, data: items } = useCommandItems({
      commandName: command.name,
      documentPageId,
      assemblyGroupId,
    })

    const bom = useAssemblyTree((state) =>
      state.getBillOfMaterialsForPart(assemblyGroupId),
    )

    const templateAttributes = useListTemplateAttributes({
      documentPageId,
    })

    if (isLoading || templateAttributes.isLoading) {
      return <Spinner />
    }

    if (!items) {
      return null
    }

    return (
      <CommandSelector
        parentRef={ref}
        items={items}
        canMultiSelect={Boolean(command.mutltiSelect)}
        query={query.replace(command.path.slice(1), '')}
        editor={editor}
        range={range}
        onSelect={(items) => {
          command.onSelect({
            items,
            editor,
            range,
            bom,
            templateAttributes: templateAttributes.data || [],
            toast,
            query,
          })
        }}
        renderEmptyResults={command.renderEmptyResults}
      />
    )
  },
)

CommandList.displayName = 'CommandList'
