interface VersionNumberButtonProps {
  version: number
}

export const VersionNumberButton = ({ version }: VersionNumberButtonProps) => {
  return (
    <span className="bg-blue-100 text-blue-500 px-2 py-1 rounded text-xs">
      {version}
    </span>
  )
}
