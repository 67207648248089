import { ReactNode } from 'react'
import { Html } from '@react-three/drei'

import { cn } from '@/utils'
import { CaretDownIcon } from '@radix-ui/react-icons'
import { Button, ButtonProps } from '@/components/ui/button'
import { AxisIcon } from '@/components/icons/AxisIcon'
import { ResetExplosionsIcon } from '@/components/icons/ResetExplosionsIcon'
import { WandFillIcon } from '@/components/icons/WandFillIcon'
import { WandOutlineIcon } from '@/components/icons/WandOutlineIcon'
import { WandSparkleIcon } from '@/components/icons/WandSparkleIcon'
import { ExplodeIcon } from '@/components/icons/ExplodeIcon'
import { PlusCircledIcon } from '@/components/icons/PlusCircledIcon'
import { CrossCircledIcon } from '@/components/icons/CrossCircledIcon'
import { BookmarkIcon } from '@/components/icons/BookmarkIcon'
import { BookmarkFilledIcon } from '@/components/icons/BookmarkFilledIcon'
import { ExplosionLineIcon } from '@/components/icons/ExplosionLineIcon'
import { WandButtonState } from '../state'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'

import { useCADPageStore } from '../state'
import { useAssemblyTree } from '@/state'
import { useShallow } from 'zustand/react/shallow'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useSelectable } from '../hooks'

export const ControlPanel = () => {
  const toggleAxis = useCADPageStore((state) => state.toggleAxis)
  const toggleExplosions = useCADPageStore((state) => state.toggleExplosions)
  const explosions = useCADPageStore((state) => state.explosions)
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const savedExplosions = useCADPageStore((state) => state.savedExplosions)
  const loadedExplosion = useCADPageStore((state) => state.loadedExplosion)
  const saveNewExplosion = useCADPageStore((state) => state.saveNewExplosion)
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const explodeObjectRef = useCADPageStore((state) => state.explodeObjectRef)
  const wandSelected = useCADPageStore((state) => state.wandSelected)
  const setWandSelected = useCADPageStore((state) => state.setWandSelected)
  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))
  const transparentParts = useCADPageStore(
    useShallow((state) => state.transparentParts),
  )
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const selectable = useSelectable({})
  const getAssemblyTree = useAssemblyTree(
    useShallow((state) => state.getAssemblyTree),
  )
  const { data: cadData } = useCADQuery()
  const gltf = cadData.gltf
  const cadId = cadData.cad?.id

  const { data: docData } = useDocumentPageQuery()
  const documentType = docData?.documentType

  if (!cadId) return null

  return (
    <Html
      wrapperClass="transform-none w-full h-0 !z-20"
      className="right-0 top-0"
      style={{ right: 0, left: 0, transform: 'none !important' }}
    >
      <div className="flex bg-transparent w-full py-2 px-4">
        <div className="flex items-center space-x-1">
          <div>
            <ControlButton
              tooltip="Toggle Axis"
              icon={<AxisIcon className="w-4 h-4" />}
              onClick={() => {
                toggleAxis()
              }}
            />
          </div>
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <ControlButton
                  asChild
                  icon={
                    <div className="flex flex-row items-center">
                      {getWandIcon(wandSelected)}
                      <CaretDownIcon />
                    </div>
                  }
                  isActive={wandSelected !== null}
                  tooltip="Change Color"
                  onClick={(e) => {
                    if (explodeObjectRef?.current !== null) {
                      if (gltf) gltf.unhighlightParts()
                      setCadPageState({
                        explodeObjectRef: null,
                        selectedParts: [],
                        explosionsToolbar: false,
                      })
                    }
                    if (wandSelected !== null) {
                      e.preventDefault()
                      setWandSelected(null)
                    }
                  }}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="flex flex-row">
                <DropdownMenuItem>
                  <ControlButton
                    isActive={wandSelected === 'color'}
                    icon={<WandFillIcon className="w-4 h-4" />}
                    tooltip="Change Color"
                    onClick={() => {
                      setWandSelected('color')
                    }}
                  />
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <ControlButton
                    isActive={wandSelected === 'transparency'}
                    icon={<WandOutlineIcon className="w-4 h-4" />}
                    tooltip="Change Transparency"
                    onClick={() => {
                      setWandSelected('transparency')
                    }}
                  />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div>
            <ControlButton
              tooltip="Reset Parts"
              icon={<ResetExplosionsIcon className="w-4 h-4" />}
              onClick={() => {
                if (gltf) {
                  gltf.showExplosionLines(explosions, false)
                  gltf.resetExplosions(explosions)
                  gltf.unhighlightParts()
                  gltf.clearDragLines()
                  gltf.resetColors(colorMap)
                  gltf.resetTransparency(transparentParts)
                }
                setCadPageState({
                  isDragging: false,
                  explodeObjectRef: null,
                  selectedParts: [],
                  loadedExplosion: '',
                  explosions: {},
                  hiddenParts: [],
                  colorMap: {},
                  transparentParts: [],
                  focusedPartUUID: '',
                  highlightedPartUUID: null,
                })
                if (explosionsToolbar) {
                  toggleExplosions()
                }
              }}
            />
          </div>
          <div>
            <DropdownMenu open={explosionsToolbar} modal={false}>
              <DropdownMenuTrigger
                className={cn({
                  'bg-primary-20 rounded-md': explosionsToolbar,
                })}
              >
                <ControlButton
                  asChild
                  tooltip="Explode Parts"
                  icon={<ExplodeIcon className="w-4 h-4" />}
                  onClick={(event) => {
                    // in work instructions, auto focus the last part
                    // in other documents, auto focus the first part
                    event.preventDefault()
                    const { explosionsToolbar } = getCadPageState()
                    if (!explosionsToolbar) {
                      setCadPageState({ wandSelected: null })
                      const tree = getAssemblyTree()
                      const autoSelectPart =
                        documentType === 'work_instructions'
                          ? tree?.nodes[0].children[
                              tree?.nodes[0].children.length - 1
                            ]
                          : tree?.nodes[0].children[0]
                      if (autoSelectPart && selectable.selected.length === 0) {
                        selectable.select(autoSelectPart)
                        setCadPageState({ focusedPartUUID: autoSelectPart })
                      } else if (selectable.selected.length > 0) {
                        setCadPageState({
                          focusedPartUUID: selectable.selected[0],
                        })
                      }
                    } else {
                      if (gltf) {
                        gltf.unhighlightParts()
                        setCadPageState({ highlightedPartUUID: null })
                      }
                    }
                    setTimeout(() => toggleExplosions())
                  }}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="flex flex-row min-w-0">
                <DropdownMenuItem>
                  <div>
                    <ControlButton
                      tooltip="Explosion Lines"
                      icon={<ExplosionLineIcon className="w-4 h-4" />}
                      isActive={isExplosionLinesEnabled}
                      onClick={() => {
                        setCadPageState({
                          isExplosionLinesEnabled: !isExplosionLinesEnabled,
                        })
                      }}
                    />
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <ControlButton
                    tooltip="Save Explosion"
                    icon={<PlusCircledIcon className="w-4 h-4" />}
                    onClick={() => {
                      saveNewExplosion(cadId)
                    }}
                  />
                </DropdownMenuItem>
                {savedExplosions
                  .filter((exp) => exp?.cadId === cadId)
                  .map((exp, i) => (
                    <DropdownMenuItem key={i}>
                      <ControlButton
                        tooltip={`Load Explosion ${i + 1}`}
                        icon={
                          exp.id === loadedExplosion ? (
                            <BookmarkFilledIcon className="w-4 h-4" />
                          ) : (
                            <BookmarkIcon className="w-4 h-4" />
                          )
                        }
                        onClick={() => {
                          if (gltf) {
                            gltf.resetExplosions(explosions)
                            gltf.explodeParts(exp.explosions)
                            setCadPageState({
                              explosions: exp.explosions,
                              loadedExplosion: exp.id,
                            })
                          }
                        }}
                      />
                    </DropdownMenuItem>
                  ))}
                {loadedExplosion && (
                  <DropdownMenuItem>
                    <ControlButton
                      tooltip="Delete Current Explosion"
                      icon={<CrossCircledIcon className="w-4 h-4" />}
                      disabled={!loadedExplosion}
                      onClick={() => {
                        const { savedExplosions } = getCadPageState()
                        setCadPageState({
                          savedExplosions: savedExplosions.filter(
                            (exp) => exp.id !== loadedExplosion,
                          ),
                          loadedExplosion: '',
                        })
                      }}
                    />
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </Html>
  )
}

interface ControlButtonProps extends ButtonProps {
  icon: ReactNode
  isActive?: boolean
  tooltip: string
}

const ControlButton = ({
  icon,
  tooltip,
  isActive,
  ...btnProps
}: ControlButtonProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="!h-auto"
            {...btnProps}
            style={{ backgroundColor: isActive ? '#D2E8FF' : undefined }}
          >
            <div className="flex flex-col items-center space-y-2 hover:color-primary cursor-pointer">
              {icon}
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

const getWandIcon = (wandSelected: WandButtonState) => {
  switch (wandSelected) {
    case null:
      return <WandFillIcon className="w-4 h-4" />
    case 'color':
      return <WandSparkleIcon className="w-4 h-4" />
    case 'transparency':
      return <WandOutlineIcon className="w-4 h-4" />
  }
}
