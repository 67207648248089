import { useState, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { DragEndEvent } from '@dnd-kit/core'
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { FileIcon } from '@/components/icons/FileIcon'
import { EyeClosedIcon } from '@/components/icons/EyeClosedIcon'
import { EyeOpenIcon } from '@/components/icons/EyeOpenIcon'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import { cn } from '@/utils'
import queryClient from '@/queryClient'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { SortableContext, SortableItem } from '@/components/ui/sortable'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  CollapsibleTriangleIcon,
} from '@/components/ui/collapsible'
import { Button } from '@/components/ui/button'

import { ASMTreeNode, useAssemblyTree } from '@/state'
import {
  useUpdateCadVersionMutation,
  useUngroupAssemblyNodesMutation,
  useCreateGroupDocumentMutation,
  useAssemblyTreeQuery,
} from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useSelectable } from '@/pages/CADPage/hooks'
import { AssemblyParts } from '@/pages/CADPage/components/AssemblyParts'
import { AssemblyMenuButton } from '@/pages/CADPage/components/AssemblyMenuButton'
import { Input } from '@/components/ui/input'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useCADPageStore } from '../state'
import { QUERY_KEYS as VIEW_JOB_QUERY_KEYS } from '@/services/queries/view_jobs'

export const Assembly = () => {
  const { documentId } = useCadPageParams()
  const [editingPart, setEditingPart] = useState<string | null>(null)
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false)

  const tree = useAssemblyTree((state) => state.tree)
  const getRoot = useAssemblyTree((state) => state.getRoot)
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const groupNodes = useAssemblyTree((state) => state.groupNodes)
  const getAssemblyTree = useAssemblyTree((state) => state.getAssemblyTree)
  const moveNodeToIndex = useAssemblyTree((state) => state.moveNodeToIndex)
  const renamePart = useAssemblyTree((state) => state.renameNode)
  const setVisibility = useAssemblyTree((state) => state.setVisibility)
  const getNodeAndDescendants = useAssemblyTree(
    (state) => state.getNodeAndDescendants,
  )

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const isHidden = useCADPageStore((state) => state.isHidden)
  const hiddenParts = useCADPageStore(useShallow((state) => state.hiddenParts))
  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))
  const transparentParts = useCADPageStore(
    useShallow((state) => state.transparentParts),
  )
  const wandSelected = useCADPageStore((state) => state.wandSelected)
  const setWandSelected = useCADPageStore((state) => state.setWandSelected)
  const highlightedPartUUID = useCADPageStore(
    (state) => state.highlightedPartUUID,
  )
  const explosions = useCADPageStore((state) => state.explosions)
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const focusedPartUUID = useCADPageStore((state) => state.focusedPartUUID)

  const selectable = useSelectable({
    onRangeSelect: (uuids) => {
      // Select a range of parts
      const root = getRoot()
      if (root) {
        const sortedSelections = uuids.sort((a, b) => {
          const indexA = root.children.indexOf(a)
          const indexB = root.children.indexOf(b)
          return indexB - indexA
        })
        const maxIndex = root.children.indexOf(sortedSelections[0])
        const minIndex = root.children.indexOf(
          sortedSelections[sortedSelections.length - 1],
        )
        for (let i = minIndex; i <= maxIndex && i < root.children.length; i++) {
          selectable.select(root.children[i])
        }
      }
    },
    onSelect: (uuid) => {
      if (uuid !== editingPart) {
        setEditingPart(null)
      }

      if (wandSelected) setWandSelected(null)

      setCadPageState({
        hiddenParts: [],
      })

      if (explosionsToolbar) return

      const node = getNode(uuid)
      const assemblyTree = getAssemblyTree()
      if (data?.gltf && node && assemblyTree) {
        const prevSelected =
          selectable.selected.length > 0
            ? getNode(selectable.selected[0])?.instance
            : undefined
        data.gltf.viewPartAssembly(
          assemblyTree,
          node.instance,
          prevSelected,
          hiddenParts,
          colorMap,
          transparentParts,
        )
        data.gltf.clearDragLines()
      }
    },
    onReset: (uuid) => {
      const node = getNode(uuid)
      const assemblyTree = getAssemblyTree()
      if (data?.gltf && node && assemblyTree) {
        data.gltf.viewPartAssembly(
          assemblyTree,
          node.instance,
          node.instance,
          hiddenParts,
          colorMap,
          transparentParts,
        )
      }
    },
  })

  const { mutate: updateCadVersion } = useUpdateCadVersionMutation()
  const { mutate: createGroupDocument } = useCreateGroupDocumentMutation()
  const { mutate: ungroupNode } = useUngroupAssemblyNodesMutation()
  const { isLoading, data } = useCADQuery()

  const projectId = data.project?.id as string
  const cadVersionId = data?.version?.id as string

  const { isLoading: isLoadingAssembly } = useAssemblyTreeQuery({
    cadVersionId,
    projectId,
    documentId,
  })

  const getLowestSelected = (root: ASMTreeNode, selected: string[]) => {
    if (!root) {
      return null
    }

    return selected.sort((a, b) => {
      const indexA = root.children.indexOf(a)
      const indexB = root.children.indexOf(b)
      return indexB - indexA
    })[0]
  }

  const updateActivePartsView = (forceUpdate: boolean = false) => {
    const root = getRoot()
    const tree = getAssemblyTree()
    if (!root || !tree || !data?.gltf) {
      return
    }
    if (wandSelected) setWandSelected(null)
    if (!forceUpdate && explosionsToolbar) return

    if (selectable.selected.length === 0) {
      root.children.forEach((child) => setVisibility(child, true))
    } else {
      const lowestSelected = getLowestSelected(root, selectable.selected)
      if (lowestSelected === null) return
      const lowestNode = getNode(lowestSelected)
      if (lowestNode) {
        const lowestNodeIndex = root.children.indexOf(lowestSelected)
        data.gltf.viewPartAssembly(
          tree,
          lowestNode.instance,
          '',
          hiddenParts,
          colorMap,
          transparentParts,
        )
        root.children.forEach((child, i) => {
          setVisibility(child, i <= lowestNodeIndex)
        })
      }
    }
  }

  // Reset on Mount
  const [isReset, setIsReset] = useState(false)
  useEffect(() => {
    if (!data?.gltf || !tree || isReset) return
    data.gltf.viewPartAssembly(tree, '', '')
    setIsReset(true)
  }, [data?.gltf, tree])

  // Update CAD model based on selected parts
  useEffect(() => {
    updateActivePartsView()
  }, [selectable.selected])

  // Update CAD model based on focused part
  useEffect(() => {
    if (!focusedPartUUID) return
    updateActivePartsView(true)
  }, [focusedPartUUID])

  // Update CAD model based on hidden parts
  useEffect(() => {
    const tree = getAssemblyTree()
    const root = getRoot()
    if (!tree || !data?.gltf || !root) {
      return
    }
    const lowestSelected =
      explosionsToolbar && focusedPartUUID
        ? focusedPartUUID
        : getLowestSelected(root, selectable.selected)
    const selectedNode = lowestSelected === null ? '' : getNode(lowestSelected)
    const selectedNodeName = selectedNode ? selectedNode.instance : ''

    let hiddenPartsUpdate = hiddenParts
    if (lowestSelected && selectedNodeName !== '') {
      const lowestNodeIndex = root.children.indexOf(lowestSelected)
      hiddenPartsUpdate = hiddenParts.filter((part) => {
        const nodeIndex = root.children.findIndex(
          (child) => getNode(child)?.instance === part,
        )
        return nodeIndex <= lowestNodeIndex
      })
      setCadPageState({ hiddenParts: hiddenPartsUpdate })
    }
    data.gltf?.viewPartAssembly(
      tree,
      selectedNodeName,
      '',
      hiddenPartsUpdate,
      colorMap,
      transparentParts,
    )
    data.gltf?.explodeParts(explosions)
  }, [hiddenParts])

  // Highlight selected parts
  useEffect(() => {
    const tree = getAssemblyTree()
    if (!tree || !data?.gltf) {
      return
    }
    data.gltf?.unhighlightParts()

    if (selectable.selected.length >= 1) {
      selectable.selected.forEach((uuid) => {
        const node = getNode(uuid)
        if (node) {
          data.gltf?.highlightPart(tree, node.instance)
        }
      })
    }

    return () => {
      data.gltf?.unhighlightParts()
    }
  }, [selectable.selected, data.gltf, getAssemblyTree, getNode])

  if (data.hasNoDownloadUrl) {
    return null
  }

  if (isLoading || isLoadingAssembly) {
    return <div>Loading...</div>
  }

  if (!tree) {
    return <div>No assembly tree found</div>
  }

  const root = getRoot()

  if (!root) {
    return <div>No assembly tree root found</div>
  }

  const documentVersionId = data?.documentVersion?.id

  const shouldDisableSorting =
    editingPart !== null || isContextMenuOpen || !data.isLatestCadVersion

  const docPageIds = root.children
    .map((child) => {
      const node = getNode(child)
      return node?.document_page_id
    })
    .filter((id) => !!id)

  return (
    <div className="h-full w-full overflow-auto text-sm font-medium">
      <SortableContext
        disabled={shouldDisableSorting}
        items={root.children}
        onDragEnd={(event: DragEndEvent) => {
          const { active, over } = event
          if (
            over &&
            typeof over.id === 'string' &&
            active &&
            typeof active.id === 'string' &&
            active.id !== over.id
          ) {
            const newIndex = root.children.indexOf(over.id)
            moveNodeToIndex(active.id, newIndex)

            const assemblyTree = getAssemblyTree()
            updateActivePartsView()
            if (assemblyTree && cadVersionId && documentVersionId) {
              updateCadVersion({
                cadVersionId,
                documentVersionId,
                assemblyTree,
              })
            }
          }
        }}
      >
        {root.children.map((child, i) => {
          const node = getNode(child)
          const hasDocument = selectable.selected.some((uuid) => {
            const currNode = getNode(uuid)
            return !!currNode?.document_page_id
          })

          const isPartHighlighted = node && node.uuid === highlightedPartUUID

          return (
            node && (
              <SortableItem key={node.uuid} id={node.uuid}>
                <Collapsible id={node.uuid} className="hover:bg-primary-10">
                  <AssemblyMenuButton
                    key={node.uuid}
                    disabled={!data.isLatestCadVersion}
                    selectedCount={selectable.size}
                    isGroup={node.group}
                    hasDocument={hasDocument}
                    onOpenChange={setIsContextMenuOpen}
                    onGroup={() => {
                      const group = groupNodes(selectable.selected)
                      selectable.reset()
                      const assemblyTree = getAssemblyTree()

                      if (
                        group &&
                        documentVersionId &&
                        assemblyTree &&
                        cadVersionId
                      ) {
                        createGroupDocument({
                          cadVersionId,
                          assemblyTree,
                          documentVersionId,
                          group,
                        })
                      }
                    }}
                    onUngroup={() => {
                      if (selectable.size === 1 && cadVersionId) {
                        ungroupNode({
                          groupUUID: selectable.selected[0],
                          cadVersionId,
                          documentVersionId,
                        })
                        setCadPageState({
                          hiddenParts: [],
                        })
                        selectable.reset()
                      }
                    }}
                    onRename={() => {
                      setEditingPart(node.uuid)
                    }}
                  >
                    <div
                      id={`part${node.uuid}`}
                      onMouseDown={(e) => {
                        e.stopPropagation()
                        const node = getNode(child)
                        if (node?.document_page_id) {
                          queryClient.invalidateQueries({
                            queryKey: [VIEW_JOB_QUERY_KEYS.VIEW_JOBS],
                          })
                        }
                      }}
                      onMouseEnter={() => {
                        const node = getNode(child)
                        const tree = getAssemblyTree()
                        if (
                          data?.gltf &&
                          node &&
                          tree &&
                          !(
                            selectable.selected.length > 1 &&
                            selectable.isSelected(node.uuid)
                          )
                        ) {
                          if (node.group) {
                            node.children.forEach((child) => {
                              const childNode = getNode(child)
                              if (childNode) {
                                data?.gltf?.highlightPart(
                                  tree,
                                  childNode.instance,
                                )
                              }
                            })
                          } else {
                            data.gltf.highlightPart(tree, node.instance)
                          }
                        }
                      }}
                      onMouseLeave={() => {
                        if (data.gltf) {
                          data.gltf.unhighlightParts()

                          if (selectable.selected.length > 1) {
                            selectable.selected.forEach((uuid) => {
                              const node = getNode(uuid)
                              if (node) {
                                data.gltf?.highlightPart(tree, node.instance)
                              }
                            })
                          }
                        }
                      }}
                      className={cn(
                        'group border border-transparent hover:bg-primary-10 hover:!text-gray-900 cursor-pointer select-none',
                        {
                          'bg-primary-10':
                            selectable.isSelected(node.uuid) ||
                            isPartHighlighted,
                          'text-gray-300': node.visible === false,
                          'border-primary-50 text-primary-50 rounded-sm':
                            explosionsToolbar && focusedPartUUID === node.uuid,
                        },
                      )}
                    >
                      <div className="flex items-center space-x-2">
                        <div className="flex items-center space-x-2 w-full">
                          {node.children.length > 0 ? (
                            <CollapsibleTrigger
                              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 py-2 px-2"
                              style={{ width: '36px', minWidth: '36px' }}
                            >
                              <Button variant="ghost" className="px-2">
                                <CollapsibleTriangleIcon
                                  className={cn('w-5 h-5', {
                                    'text-emerald-400':
                                      node.visible !== false && node.group,
                                    'text-gray-600':
                                      node.visible !== false && !node.group,
                                    'text-gray-300': node.visible === false,
                                  })}
                                />
                              </Button>
                            </CollapsibleTrigger>
                          ) : (
                            <div
                              className="h-9"
                              style={{ width: '36px', minWidth: '36px' }}
                            ></div>
                          )}

                          <div
                            className="w-full"
                            style={{ width: '85%' }}
                            onMouseDown={() => {
                              window.location.hash = `#${
                                docPageIds.indexOf(node.document_page_id) >= 0
                                  ? 'op' +
                                    String(
                                      docPageIds.indexOf(
                                        node.document_page_id,
                                      ) + 1,
                                    )
                                  : ''
                              }`
                            }}
                          >
                            <div
                              className="flex items-center space-x-2 w-full py-1.5 justify-between"
                              {...selectable.handlers(node.uuid)}
                            >
                              <div className="flex items-center space-x-2 truncate">
                                <span
                                  className={cn({
                                    'text-gray-500': node.visible !== false,
                                    'text-gray-300': node.visible === false,
                                  })}
                                >
                                  {i + 1}.
                                </span>

                                {editingPart === node.uuid ? (
                                  <RenamePartForm
                                    node={node}
                                    onSubmit={({ displayName }) => {
                                      renamePart(node.uuid, displayName)
                                      const assemblyTree = getAssemblyTree()
                                      if (
                                        assemblyTree &&
                                        cadVersionId &&
                                        documentVersionId
                                      ) {
                                        updateCadVersion({
                                          cadVersionId,
                                          documentVersionId,
                                          assemblyTree,
                                        })
                                      }
                                    }}
                                    onCompeleted={() => {
                                      setEditingPart(null)
                                    }}
                                  />
                                ) : (
                                  <span className="truncate text-ellipsis">
                                    {node.display_name}
                                  </span>
                                )}
                              </div>
                              <div className="flex flex-row items-center gap-2 pr-2">
                                <div
                                  className="invisible group-hover:visible"
                                  onPointerDown={(e) => {
                                    e.stopPropagation()
                                    const tree = getAssemblyTree()
                                    if (data.gltf && tree) {
                                      let hiddenPartsUpdate = hiddenParts
                                      const nodes = getNodeAndDescendants(node)
                                      const nodesUUIDs = nodes.map(
                                        (n) => n.uuid,
                                      )
                                      const nodesNames = nodes.map(
                                        (n) => n.instance,
                                      )
                                      if (isHidden(node.instance)) {
                                        nodesUUIDs.forEach((n) =>
                                          setVisibility(n, true),
                                        )
                                        hiddenPartsUpdate = hiddenParts.filter(
                                          (part) => !nodesNames.includes(part),
                                        )
                                        setCadPageState({
                                          hiddenParts: hiddenPartsUpdate,
                                        })
                                      } else {
                                        nodesUUIDs.forEach((n) =>
                                          setVisibility(n, false),
                                        )
                                        hiddenPartsUpdate = [
                                          ...hiddenParts,
                                          ...(nodesNames as string[]),
                                        ]
                                        setCadPageState({
                                          hiddenParts: hiddenPartsUpdate,
                                        })
                                      }
                                      data.gltf.unhighlightParts()
                                    }
                                  }}
                                >
                                  <ShowHidePartIcon
                                    visible={node.visible !== false}
                                    hidden={isHidden(node.instance)}
                                  />
                                </div>
                                <PartInfoIcon node={node} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AssemblyMenuButton>
                  <CollapsibleContent>
                    <div className="pl-10">
                      {node.children.map((child, j) => {
                        const childNode = getNode(child)
                        return (
                          childNode && (
                            <AssemblyParts
                              key={childNode.uuid}
                              depth={1}
                              order={[i + 1, j + 1]}
                              node={childNode}
                            />
                          )
                        )
                      })}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              </SortableItem>
            )
          )
        })}
      </SortableContext>
    </div>
  )
}

const PartInfoIcon = ({ node }: { node: ASMTreeNode }) => {
  if (node.document_page_id) {
    return (
      <FileIcon
        className={cn('w-4 h-4', {
          'stroke-gray-300': node.visible === false,
          'stroke-primary-50': node.visible !== false,
        })}
      />
    )
  }
  return <div className="w-4 h-4"></div>
}

const ShowHidePartIcon = ({
  visible,
  hidden,
}: {
  visible: boolean
  hidden: boolean
}) => {
  if (visible) return <EyeOpenIcon className="w-4 h-4 stroke-primary-50" />
  else if (hidden)
    return <EyeClosedIcon className="visible w-4 h-4 stroke-gray-300" />
  else return null
}

const formSchema = z.object({
  displayName: z.string().min(1, {
    message: 'Part cannot be empty.',
  }),
})

const RenamePartForm = ({
  node,
  onCompeleted,
  onSubmit,
}: {
  node: ASMTreeNode
  onSubmit: (values: { displayName: string }) => void
  onCompeleted: () => void
}) => {
  const form = useForm<{ displayName: string }>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      displayName: node.display_name,
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      form.setFocus('displayName')
    }, 290)

    return () => clearTimeout(timer)
  }, [])

  function onFormSubmit(values: z.infer<typeof formSchema>) {
    onSubmit(values)
    onCompeleted()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFormSubmit)} className="p-2">
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input className="bg-white" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center space-x-1 justify-end mt-2">
          <Button className="h-6 px-2" type="submit">
            <CheckIcon className="w-3 h-3" />
          </Button>
          <Button
            variant="secondary"
            className="h-6 px-2"
            onClick={onCompeleted}
          >
            <Cross2Icon className="w-3 h-3" />
          </Button>
        </div>
      </form>
    </Form>
  )
}
