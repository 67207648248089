import { cn } from '@/utils'

export const EyeClosedIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M2.00879 1L13.991 13M11.3699 10.5669C10.3569 11.1134 9.21204 11.4999 7.99988 11.4999C5.35582 11.4999 3.03203 9.66088 1.69944 8.33411C1.34769 7.98389 1.17184 7.8088 1.05989 7.46509C0.98005 7.21996 0.980025 6.77992 1.05989 6.53481C1.17187 6.1911 1.34808 6.01559 1.70054 5.66475C2.3722 4.99616 3.29547 4.1977 4.38449 3.57007M13.6165 8.97509C13.8659 8.7554 14.0944 8.53925 14.2994 8.33521L14.3015 8.33292C14.6525 7.98357 14.8284 7.80841 14.9402 7.46545C15.02 7.22034 15.0199 6.78013 14.94 6.535C14.8281 6.1914 14.6521 6.01581 14.3005 5.66583C12.968 4.33906 10.6439 2.5 7.99988 2.5C7.74713 2.5 7.49731 2.5168 7.25099 2.54836M8.99059 8.125C8.72656 8.35839 8.37973 8.5 7.99988 8.5C7.17268 8.5 6.50211 7.82843 6.50211 7C6.50211 6.59537 6.66208 6.22831 6.92214 5.9585"
        stroke="#D9D9D9"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
