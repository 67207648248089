export enum MUTATION_KEYS {
  CREATE_TEMPLATE_ATTRIBUTE = 'CREATE_TEMPLATE_ATTRIBUTE',
  UPDATE_TEMPLATE_ATTRIBUTE = 'UPDATE_TEMPLATE_ATTRIBUTE',
  DELETE_TEMPLATE_ATTRIBUTE = 'DELETE_TEMPLATE_ATTRIBUTE',
  CREATE_CUSTOM_ROW = 'CREATE_CUSTOM_ROW',
  UPDATE_CUSTOM_ROW = 'UPDATE_CUSTOM_ROW',
  DELETE_CUSTOM_ROW = 'DELETE_CUSTOM_ROW',
  DELETE_CUSTOM_COLUMN = 'DELETE_CUSTOM_COLUMN',
  DELETE_CUSTOM_COLUMNS_DATA = 'DELETE_CUSTOM_COLUMNS_DATA',
  CREATE_TOOLS_ROW = 'CREATE_TOOLS_ROW',
  UPDATE_TOOLS_ROW = 'UPDATE_TOOLS_ROW',
  DELETE_TOOLS_ROW = 'DELETE_TOOLS_ROW',
  DELETE_TOOLS_COLUMNS = 'DELETE_TOOLS_COLUMNS',
}

export default MUTATION_KEYS
