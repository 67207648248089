import { useSearch } from 'wouter'
import queryString from 'query-string'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Spinner } from '@/components/ui/spinner'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const DocumentVersionDropdown = () => {
  const search = useSearch()
  const queryParams = queryString.parse(search) as {
    cv?: string // cad version
    dv?: string // document version
  }
  const docPage = useDocumentPageQuery()

  const docVersions = docPage.data?.documentVersions ?? []
  const docVersion = docPage.data?.documentVersion ?? null
  const currentDocVersionId = docVersion?.id ?? ''
  const projectId = docPage.data?.project.id ?? ''

  if (docPage.isLoading) {
    return <Spinner />
  }

  return (
    <Select
      defaultValue={String(currentDocVersionId)}
      onValueChange={(selectedVersion) => {
        const newQueryParams = queryString.stringify({
          ...queryParams,
          dv: selectedVersion,
        })
        window.location.href = `/p/${projectId}/document/${docPage.data?.documentId}/edit?${newQueryParams}`
      }}
    >
      <SelectTrigger className="w-[200px] bg-primary-40 text-white rounded-full px-5 focus:ring-0">
        <SelectValue placeholder="Select a document version" />
      </SelectTrigger>
      <SelectContent>
        {docVersions.map((version) => (
          <SelectItem key={version.id} value={String(version.id)}>
            {`V${version.version_number}`}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
