import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
  DndContext,
  Modifier,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import { useDocumentState } from '@/state'
import { cn, createSnapModifier } from '@/utils'
import type { DocumentPage } from '@/lib/api/client'
import { useDocumentPageQuery } from '../../queries'
import {
  Point,
  useAnnotationsQuery,
  useSaveAnnotation,
  useUpdateAnnotation,
} from '../Annotations/queries'
import {
  useCreateElementPosition,
  useListElementPositions,
  useUpdateElementPosition,
} from '@/services/hooks/template_attributes'
import { DraggableAnnotation } from '../Annotations/DraggableAnnotation'
import { Line } from '../Annotations/Line'
import {
  DEFAULT_STROKE_COLOR,
  DISABLED_STROKE_COLOR,
} from '../Annotations/constants'
import { isLine, isArrow } from '../Annotations/utils'
import DocumentGrid from '../DocumentGrid'

export const AnnotationsContainer = ({
  documentPage,
  children,
}: {
  documentPage: DocumentPage
  children: ReactNode
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [showGrid, setShowGrid] = useState(false)
  const [gridHeight, setGridHeight] = useState(0)
  const [gridWidth, setGridWidth] = useState(0)
  const { data: docData } = useDocumentPageQuery()
  const { mutate: createElementPosition } = useCreateElementPosition()
  const { mutate: updateElementPosition } = useUpdateElementPosition()
  const annotationsCanvasRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<HTMLDivElement>(null)
  const {
    isAddingAnnotation,
    annotations,
    newAnnotation,
    canDropAnnotation,
    selectAnnotation,
    setLinePoint,
  } = useAnnotationsQuery()
  const { mutate: updateAnnotation } = useUpdateAnnotation()
  const { mutate: saveAnnotation } = useSaveAnnotation()
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const zoom = useDocumentState((state) => state.zoom)
  const zoomPercentage = useMemo(() => zoom / 100, [zoom])
  const elementPositions = useListElementPositions(documentPage.id)

  const unselectElement = useCallback(() => {
    setSelectedElement(null)
  }, [setSelectedElement])

  useEffect(() => {
    if (selectedElement) {
      document.addEventListener('click', unselectElement)
    } else {
      document.removeEventListener('click', unselectElement)
    }

    return () => {
      document.removeEventListener('click', unselectElement)
    }
  }, [unselectElement, selectedElement])

  useEffect(() => {
    if (gridRef.current) {
      const { width, height } = gridRef.current.getBoundingClientRect()
      setGridHeight(height / 50)
      setGridWidth(width / 50)
    }
  }, [gridRef])

  const addAnnotation = useCallback(
    (clickPosition: Point) => {
      if (!document || !annotationsCanvasRef.current || !newAnnotation) return
      const iconPlaceholder = globalThis.document.getElementById(
        `icon-placeholder--${newAnnotation.id}`,
      ) as HTMLElement
      const { width: placeholderWidth, height: placeholderHeight } =
        iconPlaceholder.getBoundingClientRect()
      const canvasRect = annotationsCanvasRef.current.getBoundingClientRect()
      if (canDropAnnotation) {
        saveAnnotation({
          documentPage,
          position: {
            x:
              clickPosition.x / zoomPercentage -
              canvasRect.x / zoomPercentage -
              placeholderWidth / zoomPercentage / 2 -
              1,
            y:
              clickPosition.y / zoomPercentage -
              canvasRect.y / zoomPercentage -
              placeholderHeight / zoomPercentage / 2 -
              1,
          },
        })
      }
    },
    [
      documentPage,
      annotationsCanvasRef,
      zoomPercentage,
      newAnnotation,
      canDropAnnotation,
      saveAnnotation,
    ],
  )

  const addLine = useCallback(
    (clickPosition: { x: number; y: number }) => {
      if (!document || !annotationsCanvasRef.current || !newAnnotation) return
      const canvasRect = annotationsCanvasRef.current.getBoundingClientRect()
      if (!newAnnotation.startPoint && canDropAnnotation) {
        setLinePoint({
          position: 'start',
          x: clickPosition.x / zoomPercentage - canvasRect.x / zoomPercentage,
          y: clickPosition.y / zoomPercentage - canvasRect.y / zoomPercentage,
        })
      }
      if (newAnnotation.startPoint && canDropAnnotation) {
        saveAnnotation({
          documentPage,
          position: {
            x: clickPosition.x / zoomPercentage - canvasRect.x / zoomPercentage,
            y: clickPosition.y / zoomPercentage - canvasRect.y / zoomPercentage,
          },
        })
      }
    },
    [
      documentPage,
      annotationsCanvasRef,
      newAnnotation,
      canDropAnnotation,
      zoomPercentage,
      setLinePoint,
      saveAnnotation,
    ],
  )

  const onAddAnnotation = useCallback(
    (event: MouseEvent) => {
      if (!newAnnotation) return
      if (isLine(newAnnotation.id)) {
        addLine({ x: event.clientX, y: event.clientY })
      } else {
        addAnnotation({ x: event.clientX, y: event.clientY })
      }
    },
    [newAnnotation, addAnnotation, addLine],
  )

  useEffect(() => {
    const annotationsCanvas = annotationsCanvasRef.current

    if (isAddingAnnotation && annotationsCanvas) {
      annotationsCanvasRef.current.addEventListener('click', onAddAnnotation)
      if (newAnnotation?.id && isLine(newAnnotation?.id)) {
        annotationsCanvasRef.current.style.cursor = 'crosshair'
      } else {
        annotationsCanvasRef.current.style.cursor = 'auto'
      }
    } else if (!isAddingAnnotation && annotationsCanvas) {
      annotationsCanvasRef.current.removeEventListener('click', onAddAnnotation)
    }

    return () => {
      if (annotationsCanvas) {
        annotationsCanvas.removeEventListener('click', onAddAnnotation)
        annotationsCanvas.style.cursor = 'auto'
      }
    }
  }, [isAddingAnnotation, annotationsCanvasRef, newAnnotation, onAddAnnotation])

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 0,
      tolerance: 5,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 0,
      tolerance: 5,
    },
  })

  const sensors = useSensors(mouseSensor, touchSensor)

  const showContainerBorder = useMemo(
    () =>
      (isAddingAnnotation &&
        newAnnotation?.documentPageId === documentPage.id) ||
      isDragging,
    [isAddingAnnotation, newAnnotation, documentPage, isDragging],
  )

  const restrictToDocumentPage: Modifier = ({
    draggingNodeRect,
    transform,
    ...other
  }) => {
    const containerNodeRect =
      annotationsCanvasRef.current?.getBoundingClientRect()

    if (!draggingNodeRect || !containerNodeRect) {
      return transform
    }

    return restrictToParentElement({
      ...other,
      transform,
      draggingNodeRect,
      containerNodeRect,
    })
  }

  const snapToGrid = useMemo(() => {
    if (showGrid) {
      return createSnapModifier({
        gridDimensions: {
          height: gridHeight,
          width: gridWidth,
        },
        gridContainer: gridRef.current,
      })
    }

    return createSnapModifier({
      gridDimensions: {
        height: 1,
        width: 1,
      },
      gridContainer: gridRef.current,
    })
  }, [showGrid, gridHeight, gridWidth])

  if (
    !docData ||
    !docData.project ||
    !docData.version ||
    !docData.documentPages
  ) {
    return null
  }

  return (
    <DndContext
      sensors={sensors}
      modifiers={[snapToGrid, restrictToDocumentPage]}
      onDragStart={({ active }) => {
        const { type } = (active.data?.current as any) || {}
        const draggableId = active.id as string
        selectAnnotation(null)
        setSelectedElement(null)

        if (type === 'annotation') {
          if (documentPage?.id) {
            selectAnnotation({
              annotationId: draggableId,
              documentId: documentPage.id,
            })
          }
        } else {
          setSelectedElement({
            documentPageId: documentPage.id as string,
            id: draggableId,
          })
        }
      }}
      onDragMove={({ active }) => {
        const { type } = (active.data?.current as any) || {}
        setIsDragging(true)

        if (type !== 'annotation') {
          setShowGrid(true)
        }
      }}
      onDragEnd={({ delta: { x, y }, active }) => {
        setShowGrid(false)
        if (x || y) {
          const { type } = (active.data?.current as any) || {}

          if (type === 'annotation') {
            updateAnnotation({
              annotationId: active.id as string,
              delta: { x, y },
            })
          } else {
            const currentPositionTemplate = elementPositions.find(
              (position) =>
                position.template_values.movableElementId === active.id,
            )
            if (currentPositionTemplate) {
              updateElementPosition({
                templateAttributeId: currentPositionTemplate.id as string,
                payload: {
                  ...currentPositionTemplate.template_values,
                  position: {
                    x:
                      (currentPositionTemplate.template_values.position?.x ||
                        0) + x,
                    y:
                      (currentPositionTemplate.template_values.position?.y ||
                        0) + y,
                  },
                },
              })
            } else {
              createElementPosition({
                documentPageId: documentPage.id as string,
                payload: {
                  type,
                  movableElementId: active.id as string,
                  position: { x, y },
                },
              })
            }
          }
        }
        setIsDragging(false)
      }}
      onDragCancel={() => setIsDragging(false)}
    >
      <div
        className={cn(
          'flex flex-col rounded border flex-1 mb-9 relative justify-between',
          {
            'border-solid': showContainerBorder,
            'border-cyan-600': showContainerBorder,
            'shadow-md': showContainerBorder,
            'shadow-gray-400': showContainerBorder,
          },
        )}
        ref={annotationsCanvasRef}
        style={{
          borderColor: showContainerBorder ? '#5884E7' : 'transparent',
        }}
        id={`droppable-container--${documentPage.id}`}
      >
        <DocumentGrid
          gridDimensions={{ width: gridWidth, height: gridHeight }}
          showGrid={showGrid}
          ref={gridRef}
        />
        {isAddingAnnotation &&
          newAnnotation?.documentPageId === documentPage.id &&
          isLine(newAnnotation?.id || '') &&
          newAnnotation?.startPoint && (
            <div className="z-[2] absolute">
              <Line
                startPoint={newAnnotation?.startPoint}
                endPoint={newAnnotation?.endPoint || newAnnotation?.startPoint}
                strokeOpacity={canDropAnnotation ? 0.6 : 1}
                strokeColor={
                  canDropAnnotation
                    ? newAnnotation?.strokeColor || DEFAULT_STROKE_COLOR
                    : DISABLED_STROKE_COLOR
                }
                isArrow={isArrow(newAnnotation?.id || '')}
                isBubble={
                  (newAnnotation?.id && newAnnotation.id.includes('bubble')) ||
                  false
                }
                bubbleValue={newAnnotation?.bubbleValue}
                renderWrapper={true}
                textPosition={newAnnotation?.id?.split('--')[1] || ''}
              />
            </div>
          )}
        {annotations
          .filter((annotation) => annotation.documentPageId === documentPage.id)
          .map((annotation) => (
            <DraggableAnnotation
              key={`annotation-container--${annotation.id}`}
              annotation={annotation}
            />
          ))}
        {children}
      </div>
    </DndContext>
  )
}
